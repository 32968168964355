import React from "react";
import QuestionIconFocus from "../../assets/svg/info-question.svg";
import QuestionIcon from "../../assets/svg/info-question.svg";

const StarIcon = ({ onClick = () => {} }) => {

  const id = Math.random();

  return (
    <a
      href="/#"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="link-focus information-icon"
      aria-label={"More Information"}
      onFocus={() => {
        let img = document.getElementById(`Question-${id}`);
        if (img) img.src = QuestionIconFocus;
      }}
      onBlur={() => {
        let img = document.getElementById(`Question-${id}`);
        if (img) img.src = QuestionIcon;
      }}
    >
      <img
        id={`Question-${id}`}
        src={QuestionIcon}
        alt="Note"
        style={{ width: "16px", height: "16px" }}
      />
    </a>
  );
};

export default StarIcon;

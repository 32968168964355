import { Router, navigate } from "@reach/router";
import React, { useEffect } from "react";

import LearnDashboard from "./LearnDashboard/LearnDashboard";
import Webinars from "./Webinars/Webinars";
// import Resources from "./Resources/Resources";
import Container from "../../components/Container/Container";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import { routes } from "../../routes";
import Courses from "./Courses/Courses";
import Resources from "./ResourcesV2/Resources";
import StudentServices from "./StudentServices/StudentServices";

import coursesFocusIcon from "../../assets/FocusIcons/MenuIcons/Learn/Courses.svg";
import resourceFocusIcon from "../../assets/FocusIcons/MenuIcons/Learn/Resources.svg";
import studentFocusServiceIcon from "../../assets/FocusIcons/MenuIcons/Learn/StudentServices.svg";
import webinarsFocusIcon from "../../assets/FocusIcons/MenuIcons/Learn/Webinars.svg";
import CoursesIcon from "../../assets/svg/Courses.svg";
import resourceIcon from "../../assets/svg/Resources.svg";

const Learn = ({ location, permissions = {} }) => {
  useEffect(() => {
    if (
      (location.pathname.includes(routes.LEARN.WEBINARS) &&
        !permissions["webinar"]) ||
      (location.pathname.includes(routes.LEARN.COURSES) &&
        !permissions["courses"]) ||
      (location.pathname.includes(routes.LEARN.RESOURCES) &&
        !permissions["resources"]) ||
      (location.pathname.includes(routes.LEARN.STUDENT_SERVICES) &&
        !permissions["student_services"])
    )
      navigate(routes.DASHBOARD);
  }, [permissions]);

  const tabs = [
    ...(permissions["webinar"]
      ? [
          {
            title: "Webinars",
            toolTip: "Register for live webinars and watch past recordings",
            avatar: webinarsFocusIcon,
            focusAvatar: webinarsFocusIcon,
            navigateURL: routes.LEARN.WEBINARS,
            isActive: location.pathname === routes.LEARN.WEBINARS,
          },
        ]
      : []),
    ...(permissions["courses"]
      ? [
          {
            title: "Courses",
            toolTip: "Essential courses",
            avatar: CoursesIcon,
            focusAvatar: coursesFocusIcon,
            navigateURL: routes.LEARN.COURSES,
            isActive: location.pathname === routes.LEARN.COURSES,
          },
        ]
      : []),
    ...(permissions["resources"]
      ? [
          {
            title: "Resources",
            toolTip:
              "Resource library of career readiness and immigration resources",
            avatar: resourceIcon,
            focusAvatar: resourceFocusIcon,
            navigateURL: routes.LEARN.RESOURCES,
            isActive: location.pathname === routes.LEARN.RESOURCES,
          },
        ]
      : []),
    ...(permissions["student_services"]
      ? [
          {
            title: "Student Services",
            toolTip: "Essential services for your life in the U.S.",
            avatar: studentFocusServiceIcon,
            focusAvatar: studentFocusServiceIcon,
            navigateURL: routes.LEARN.STUDENT_SERVICES,
            isActive: location.pathname === routes.LEARN.STUDENT_SERVICES,
          },
        ]
      : []),
  ];

  return (
    <MainLayout
      title="Learn Dashboard"
      withSubHeader={true}
      tabs={tabs}
      location={location}
      viewOnlyNavbar
    >
      <Container>
        <Router>
          <LearnDashboard path="/" />

          {permissions["webinar"] && <Webinars path="/webinars" />}
          {permissions["webinar"] && <Webinars path="/webinars/live" />}
          {permissions["webinar"] && <Webinars path="/webinars/on-demand" />}
          {permissions["webinar"] && <Webinars path="/webinars/registered" />}

          {permissions["resources"] && <Resources path="/resources" />}

          {permissions["student_services"] && (
            <StudentServices path="/student-services" />
          )}

          <Courses path="/courses" permissions={permissions} />
        </Router>
      </Container>
    </MainLayout>
  );
};

export default Learn;

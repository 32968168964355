import React from "react";
import { Router } from "@reach/router";

import { NotificationManage } from "./NotificationManage/NotificationManage";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import NotificationDashboard from "./NotificationDashboard/NotificationDashboard";

export default function Notification() {
  return (
    <MainLayout
      title="Notifications"
      withSubHeader={false}
      showSubheaderHeading={false}
    >
      <Router>
        <NotificationDashboard path="/" />
        <NotificationManage path="/settings" />
      </Router>
    </MainLayout>
  );
}

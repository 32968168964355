import React, { useState, useCallback } from "react";
import Subscription from "../../../components/Subscription/Subscription";
import { Grid } from "@material-ui/core";
import MiddlePage from "../../../components/PrivacyPolicy/MiddlePage/MiddlePage";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import uploadIcon from "../../../assets/svg/uploadIcon.svg";
import PrivacyPolicyDBStyles from "./PrivacyPolicyDBStyles";
import PrivacyCategory from "../../../components/PrivacyPolicy/Category/Category";
import CustomDropzone from "../../../components/CustomDropzone/CustomDropzone";
import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import { PRIVACY_POLICY } from "../../../constant/mockdata";
import { toastify } from "../../../helper/helper";
import uploadFocusIcon from "../../../assets/FocusIcons/Common/Upload.svg";
import deleteIcon from "../../../assets/svg/f-delete-icon.svg";
const PrivacyPolicyDB = () => {
  const [activeCatId, setCategoryID] = useState(1);
  const [showModel, SetModel] = useState(false);

  const [contactUs, setContactUs] = useState({
    issueType: null,
    issue: "",
  });

  const [issueImage, setIssueImage] = useState(null);

  const handleCategory = (Id) => {
    setCategoryID(Id);
  };

  const contactDialog = () => {
    SetModel(!showModel);
    setContactUs({
      issueType: null,
      issue: "",
    });
  };

  const handleChange = (value, name) => {
    setContactUs({
      ...contactUs,
      [name]: value,
    });
  };

  const handleCallback = useCallback((file) => {
    file.forEach((item) => {
      // if (item.type.match("image.*")) {
      if (["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        setIssueImage(item);
      } else {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendReport = () => {
    // console.log("Report =>", { ...contactUs, issueImage });
    contactDialog();
  };

  return (
    <PrivacyPolicyDBStyles>
      <div className="started-mainpage">
        <div className="resource-section">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <PrivacyCategory
                catList={PRIVACY_POLICY}
                ActiveID={activeCatId}
                handleCategory={handleCategory}
              />
            </Grid>
            <Grid item xs={6}>
              <MiddlePage
                data={PRIVACY_POLICY[activeCatId - 1].details}
                ActiveID={activeCatId}
                name={PRIVACY_POLICY[activeCatId - 1].category}
              />
            </Grid>
            <Grid item xs={3}>
              <Subscription
                title={"Any questions?"}
                subTitle1={"We’re here to clear up any questions you may have."}
                btnName={"Contact Us"}
                subTitle2={""}
                showCloseBtn={false}
                contactUs={contactDialog}
              />
            </Grid>
            {showModel && (
              <div className="conatct-us-model">
                <CustomDialog
                  className="feedback-dialog"
                  open={showModel}
                  title="Contact us"
                  subTitle="Describe the issue and we will get back to you within 48 hours."
                  handleClose={() => contactDialog()}
                >
                  <div className="model-body">
                    <div className="issueType">
                      <DropdownCustom
                        name="issueType"
                        label="Select an issue"
                        placeholder="Select an issue"
                        justifyContent="center"
                        options={[
                          { value: "1", label: "Technical Support" },
                          { value: "2", label: "Interstride Services" },
                          { value: "3", label: "Report a Bug" },
                          { value: "4", label: "Other Issues" },
                        ]}
                        onChange={(obj) => handleChange(obj.value, "issueType")}
                      />
                    </div>
                    <div className="about-box">
                      <TextboxCustom
                        name="about"
                        placeholder="Tell us more..."
                        isMultiline={true}
                        value={contactUs.issue}
                        onChange={(e) => handleChange(e.target.value, "issue")}
                        label={"Tell us more"}
                        lableAignMent="center"
                        className="custom-textarea"
                      />
                    </div>
                    <div className="file-attch">
                      <CustomDropzone
                        icon={uploadIcon}
                        focusIcon={uploadFocusIcon}
                        text="Upload an image"
                        handleUploadFile={handleCallback}
                        vallidFiles="image/*"
                      />
                      {issueImage && (
                        <div className="selectedfile-wrapper">
                          {issueImage.name}
                          <a href="" className="delete-btn">
                            <img src={deleteIcon} alt="Delete" />
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="btn">
                      <ButtonCustom
                        width="248"
                        onClick={() => sendReport()}
                        isDisabled={
                          contactUs.issueType === null || contactUs.issue === ""
                        }
                      >
                        Send
                      </ButtonCustom>
                    </div>
                    <div className="text-center">
                      <a
                        href="/#"
                        className="text-cancel link-focus"
                        onClick={(e) => {
                          e.preventDefault();
                          contactDialog();
                        }}
                        aria-label="cancel"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </CustomDialog>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </PrivacyPolicyDBStyles>
  );
};

export default PrivacyPolicyDB;

import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";

import CareerGameplanTestStyles from "./CareerGameplanTestStyles";
import btnArrow from "../../../../assets/svg/f-btn-arrow.svg";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { toastify } from "../../../../helper/helper";
import usePersistState from "../../../../state/usePersistState";
import ButtonCustom from "../../../../components/ButtonCustom/ButtonCustom";
import TestQuestionnaries from "./TestQuestionnaries";
import CompletedTest from "./CompletedTest";
import TestGuidelines from "./TestGuidelines";
import TestTypeSelection from "./TestTypeSelection";

import { submitTestAnswersAPI } from "../../../../services/careerGameplanServices";

const CareerGameplanTest = () => {
  const {
    preTestStep = 1,
    setPreTestStep,
    isCompletedTest,
    resetTestState,
    testAnswersList = [],
  } = usePersistState();
  const [userScore, setUserScore] = useState(null);
  const [callingSubmitTestAnswersAPI, refreshSubmitTestAnswersAPI] =
    usePromise(submitTestAnswersAPI);

  useEffect(() => {
    setUserScore(null);
  }, []);

  useEffect(() => {
    if (isCompletedTest) {
      const formData = new FormData();

      testAnswersList.forEach(({ answers = {} }) => {
        Object.keys(answers).forEach((index) => {
          const ansIndex = parseInt(index);
          formData.append(`user_answer[][gameplan_question_id]`, ansIndex);
          formData.append(`user_answer[][answer_id]`, answers[ansIndex].id);
          formData.append(
            `user_answer[][answer_points]`,
            answers[ansIndex].points
          );
        });
      });

      refreshSubmitTestAnswersAPI(formData);
    }
  }, [isCompletedTest]);

  useEffect(() => {
    if (
      callingSubmitTestAnswersAPI.hasFetched() &&
      callingSubmitTestAnswersAPI.hasErrors() === false &&
      callingSubmitTestAnswersAPI.data()
    ) {
      if (callingSubmitTestAnswersAPI.data().message)
        toastify("success", callingSubmitTestAnswersAPI.data().message);

      setUserScore(callingSubmitTestAnswersAPI.data().data.total_score);
      resetTestState();
    }
  }, [callingSubmitTestAnswersAPI.isFetching()]);

  return (
    <CareerGameplanTestStyles>
      <div className="tools-gameplan">
        <div className="f-main-title-with-button mb-15">
          <h2 className="f-main-title mb-0">Career Gameplan Test</h2>
          {userScore === null && (
            <div className="btn-wrapper">
              <ButtonCustom
                width={173}
                className="shadow-btn"
                onClick={() => {
                  resetTestState();
                  navigate("/tools/career-gameplan");
                }}
              >
                Quit Test
                <img src={btnArrow} alt="btn-arrow" />
              </ButtonCustom>
            </div>
          )}
        </div>
        <div className="career-gameplan-test-wrapper">
          {userScore === null && preTestStep === 1 ? (
            <TestGuidelines onNext={() => setPreTestStep(2)} />
          ) : userScore === null && preTestStep === 2 ? (
            <TestTypeSelection
              onNext={() => setPreTestStep(3)}
              onBack={() => setPreTestStep(1)}
            />
          ) : (
            <>
              {userScore !== null ? (
                <CompletedTest userScore={userScore} />
              ) : (
                <TestQuestionnaries onBack={() => setPreTestStep(2)} />
              )}
            </>
          )}
        </div>
      </div>
    </CareerGameplanTestStyles>
  );
};

export default CareerGameplanTest;

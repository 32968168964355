import { useEffect, useState } from "react";
import { useAppState } from "../context";
import { fetchContactsList } from "../services/chat.service";

export const useFetchContacts = (filter = {}) => {
  const {
    contacts,
    setChatContactsList,
    setIsDiscoverNetworkFilterInProgress,
  } = useAppState("chat");
  const [loading, setIsLoading] = useState(false);
  const loadContacts = async () => {
    try {
      const response = await fetchContactsList(filter);
      const { success, data } = response || {};
      if (success) {
        setChatContactsList(data?.networks_list || []);
        setIsLoading(false);
        setIsDiscoverNetworkFilterInProgress(false);
      }
    } catch (error) {
      console.log(`error ${error.message}`);
      setIsLoading(false);
      setIsDiscoverNetworkFilterInProgress(false);
    }
  };

  useEffect(() => {
    if (loading) return;
    setIsLoading(true);
    setIsDiscoverNetworkFilterInProgress(true);
    loadContacts();
  }, [filter]);

  return { contacts, loading };
};

import axiosInstance from "../config/axiosInterceptors";

const getWidgetAPIHeader = (token) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Token: token,
    },
  };
};

export const validateWidgetTokenAPI = (token) => {
  return axiosInstance
    .get("validate/school", getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};

// Jobs tab - START
export const widgetJobsCountryListAPI = (token) => {
  return axiosInstance
    .get(`countries`, getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};

export const widgetSearchJobsAPI = (token, requestBody = {}) => {
  const body = {
    sort: "date", // Default sort
    job_region: "international",
    search: "",
    city: null,
    visa: "",
    job_type: null,
    publish_date: null,
    degree: null,
    experience: null,
    state: "",
    location: "",
    page: 1, // We need only first page
    job_search_type: "approx", // Default search type
    ...requestBody,
  };
  return axiosInstance
    .post(`jobs/widget/search`, body, getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};

export const widgetSearchTopEmployerJobsAPI = (token, params) => {
  const {
    sort = "date", // Default sort
    search = "",
    city = null,
    state = null,
    job_type = null,
    publish_date = null,
    degree = null,
    experience = null,
    company_name = null,
    visa = "top_1000_employers",
    page = 1, // We need only first page
    job_search_type = "approx", // Default search type
  } = params;
  return axiosInstance
    .get(
      `jobs/widget/top_employer_jobs?keyword=${search}&page=${page}${
        city !== null ? `&city=${city}` : ""
      }${state !== null ? `&state=${state}` : ""}${
        job_type !== null ? `&job_type=${job_type}` : ""
      }${publish_date !== null ? `&publish_date=${publish_date}` : ""}${
        degree !== null ? `&degree=${degree}` : ""
      }${experience !== null ? `&experience=${experience}` : ""}${
        company_name !== null ? `&company_name=${company_name}` : ""
      }&sort=${sort}&visa=${visa}&job_search_type=${job_search_type}`,
      getWidgetAPIHeader(token)
    )
    .then((res) => {
      return res.data;
    });
};
// Jobs tab - END

// Network tab - START
export const widgetNetworksAPI = (token) => {
  return axiosInstance
    .get("network_modules/widget/discover_network", getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};
// Network tab - END

// Webinars tab - START
export const widgetUpcomingWebinarsAPI = (token) => {
  return axiosInstance
    .get("webinars/widget", getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};
// Webinars tab - END

// Resources tab - START
export const widgetResourceCategoriesAPI = (token) => {
  return axiosInstance
    .get(`videos/widget/categories`, getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};

export const widgetServicesCategoriesAPI = (token) => {
  return axiosInstance
    .get(`deals/widget/categories`, getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};

export const widgetServicesAPI = (token, id) => {
  return axiosInstance
    .get(`deals/widget?deal_category_id=${id}`, getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};
// Resources tab - END

// Employment tab - START
export const widgetEmploymentGraphAPI = async (token) => {
  return axiosInstance
    .get(`opts/widget/graph_data`, getWidgetAPIHeader(token))
    .then((res) => {
      return res.data;
    });
};
// Employment tab - END

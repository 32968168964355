import React, { useEffect, useState } from "react";
import { Router, navigate } from "@reach/router";

import searchJobsFocusIcon from "../../assets/FocusIcons/MenuIcons/Jobs/Search.svg";
import visaJobsFocusIcon from "../../assets/FocusIcons/MenuIcons/Jobs/Visa.svg";
import countryInsightIcon from "../../assets/svg/Country.svg";
import employmentDataIcon from "../../assets/svg/Employment.svg";
import countryFocusInsightIcon from "../../assets/FocusIcons/MenuIcons/Learn/Country.svg";
import visaJobsIcon from "../../assets/svg/visaJobsIcon.svg";
import searchJobsIcon from "../../assets/svg/searchJobsIcon.svg";
import { routes } from "../../routes";
import usePersistState from "../../state/usePersistState";
import usePromise from "../../hooks/usePromise/usePromise";

import CountryInsight from "./CountryInsight/CountryInsight";
import EmploymentData from "./EmploymentData/EmploymentData";
import SearchResult from "./Search/SearchResult";
import Alert from "./Alert/Alert";
import Visa from "./Visa/Visa";
import JobsDashboard from "./JobsDashboard/JobsDashboard";
import CompanyDetail from "./Visa/CompanyDetail/CompanyDetail";
import SavedSearches from "./Search/SavedSearches/SavedSearches";
import SavedJobs from "./Search/SavedJobs/SavedJobs";
import FeaturedJobs from "../../components/Jobs/FeaturedJobsList/FeaturedJobsList";
import EmployerJobs from "../../components/Jobs/EmployerJobsList/EmployerJobsList";
import JobDetails from "../../components/Jobs/EmployerJobsDetails/EmployerJobsDetails";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Container from "../../components/Container/Container";
import { getJobsCountriesAPI } from "../../services/JobServices";
import { config } from "../../constant/config";
import { useSavedJobs } from "../../hooks/useSavedJobs";

const Jobs = ({
  location,
  permissions = {},
  studentViewPreference = "international",
}) => {
  const {
    jobsCountryListVersion,
    jobsCountryList = [],
    setJobsCountryList,
    users,
  } = usePersistState();
  const [callingGetJobsCountriesAPI, refreshGetJobsCountriesAPI] =
    usePromise(getJobsCountriesAPI);
  const [withSubHeader, setWithSubheader] = useState(true);

  // Fetch saved jobs on first load
  useSavedJobs({ fetchJobs: true });

  const JOBS_COUNTRY_LIST_VERSION = config().VERSIONS.JOBS_COUNTRY_LIST;
  useEffect(() => {
    if (
      ((location.pathname.includes(routes.JOBS.VISA) ||
        location.pathname.includes(routes.JOBS.COMPANY_DETAILS)) &&
        !permissions["visa_insights"] &&
        studentViewPreference !== "international") ||
      (location.pathname.includes(routes.JOBS.COUNTRY_INSIGHTS) &&
        !permissions["country_insights"])
    )
      navigate(routes.DASHBOARD);

    if (
      jobsCountryList.length === 0 ||
      !jobsCountryListVersion ||
      jobsCountryListVersion !== JOBS_COUNTRY_LIST_VERSION
    ) {
      refreshGetJobsCountriesAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  useEffect(() => {
    if (
      callingGetJobsCountriesAPI.hasFetched() &&
      callingGetJobsCountriesAPI.hasErrors() === false &&
      callingGetJobsCountriesAPI.data() &&
      callingGetJobsCountriesAPI.data().data &&
      callingGetJobsCountriesAPI.data().data.countries
    ) {
      setJobsCountryList(
        callingGetJobsCountriesAPI.data().data.countries,
        JOBS_COUNTRY_LIST_VERSION
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetJobsCountriesAPI.isFetching()]);

  const tabs = [
    {
      title: "Search Jobs",
      toolTip: "Filter for domestic and international opportunities",
      avatar: searchJobsIcon,
      focusAvatar: searchJobsFocusIcon,
      navigateURL: routes.JOBS.SEARCH,
      isActive: location.pathname === routes.JOBS.SEARCH,
    },
    ...(permissions["visa_insights"] &&
    studentViewPreference === "international"
      ? [
          {
            title: "U.S. Visa Insights",
            toolTip:
              "U.S. employers' past sponsorship record of hiring international candidates",
            avatar: visaJobsIcon,
            focusAvatar: visaJobsFocusIcon,
            navigateURL: routes.JOBS.VISA,
            isActive: location.pathname === routes.JOBS.VISA,
          },
        ]
      : []),
    ...(permissions["country_insights"]
      ? [
          {
            title: "Country Insights",
            toolTip: "Guide to traveling and working internationally",
            avatar: countryInsightIcon,
            focusAvatar: countryFocusInsightIcon,
            navigateURL: routes.JOBS.COUNTRY_INSIGHTS,
            isActive: location.pathname === routes.JOBS.COUNTRY_INSIGHTS,
          },
        ]
      : []),
    ...(permissions["employment_data"] && permissions["opt_upload"]
      ? [
          {
            title: "Employment Data",
            toolTip: `The data hub provides employment information of international graduates${
              users?.school_name && " from " + users.school_name
            }`,
            avatar: employmentDataIcon,
            focusAvatar: employmentDataIcon,
            navigateURL: routes.JOBS.EMPLOYMENT_DATA,
            isActive: location.pathname === routes.JOBS.EMPLOYMENT_DATA,
          },
        ]
      : []),
  ];

  const subHeaderList = [
    {
      title: "Company Profiles",
      isActive: location.pathname === routes.JOBS.COMPANY_DETAILS,
    },
    {
      title: "Saved Searches",
      isActive: location.pathname === routes.JOBS.SAVED_SEARCHES,
    },
    {
      title: "Saved Jobs",
      isActive: location.pathname === routes.JOBS.SAVED_JOBS,
    },
    {
      title: "Featured Jobs",
      isActive: location.pathname === routes.JOBS.FEATURED_JOBS,
    },
    {
      title: "Featured Jobs",
      isActive: location.pathname === routes.JOBS.EMPLOYER_JOBS,
    },
    {
      title: "",
      isActive: location.pathname === routes.JOBS.JOB_DETAILS,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.indexOf("jobs/job-details") !== -1) {
      setWithSubheader(false);
      return;
    }
    setWithSubheader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <MainLayout
      title="Jobs Dashboard"
      withSubHeader={withSubHeader}
      tabs={tabs}
      subHeaderList={subHeaderList}
      location={location}
      viewOnlyNavbar
    >
      <Container>
        <Router>
          <JobsDashboard path="/" />
          <SearchResult path="/search" />
          <Alert path="/job-alerts" />
          <SavedSearches path="/saved-searches" />
          <SavedJobs path="/saved-jobs" />
          <FeaturedJobs path="/featured-jobs" />
          <EmployerJobs path="/featured-jobs-portal" />
          <JobDetails path="/job-details/:id" />
          {permissions["visa_insights"] &&
          studentViewPreference === "international" ? (
            <>
              <Visa path="/visa-insight" />
              <CompanyDetail path="/visa-insight/company" />
            </>
          ) : null}
          {permissions["country_insights"] && (
            <CountryInsight path="/country-insights" />
          )}
          {permissions["employment_data"] && (
            <EmploymentData path="/employment-data" />
          )}
        </Router>
      </Container>
    </MainLayout>
  );
};

export default Jobs;

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { Link } from "@reach/router";
import _get from "lodash/get";

import {
  getSavedSearchesAPI,
  deleteSavedSearchAPI,
  getJobSearchNotificationsAPI,
} from "../../../../../services/JobServices";
import { useAppState } from "../../../../../context";
import { toastify, capitalizeFirstLetter } from "../../../../../helper/helper";
import usePromise from "../../../../../hooks/usePromise/usePromise";
import usePersistState from "../../../../../state/usePersistState";
import NotificationSettingPanel from "../NotificationSettingPanel/NotificationSettingPanel";
import NotificationInfoPanel from "../NotificationInfoPanel/NotificationInfoPanel";
import SaveSearchListStyles from "../../../../Jobs/SaveSearchList/SaveSearchListStyles";
import CardCustom from "../../../../CardCustom/CardCustom";
import ButtonCustom from "../../../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../../../CustomDialog/CustomDialog";
import JobsListingEffect from "../../../../effects/JobsListingEffect/JobsListingEffect";

const EmptySaveSearch = () => {
  return (
    <Grid item xs={12}>
      <CardCustom className="empty-search-box">
        <div className="empty-item">
          <div className="header-primary">
            Save your searches to make it easy to search again.
          </div>
          <div className="text-grey">
            You can click "Save Search" at the bottom of the search in Search
            Jobs.
          </div>
        </div>
      </CardCustom>
    </Grid>
  );
};

const JobsSettings = ({ isFetching }) => {
  const { notificationSettings, setJobsInNotificationSettings, saveJobs } =
    useAppState("notifications");

  const { jobsCountryList } = usePersistState();

  const [callSavedJobSearchesAPI, refreshSavedJobSearchesAPI] =
    usePromise(getSavedSearchesAPI);
  const [callJobSearchNotificationsAPI, refreshJobSearchNotificationsAPI] =
    usePromise(getJobSearchNotificationsAPI);
  const [callingDeleteSavedSearchAPI, refreshDeleteSavedSearchAPI] =
    usePromise(deleteSavedSearchAPI);
  const [showDeleteSearchPopup, setShowDeleteSearchPopup] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null);

  const { job_saved_search_push, job_saved_search_email } =
    notificationSettings;

  useEffect(() => {
    refreshSavedJobSearchesAPI();
  }, []);

  useEffect(() => {
    if (
      callSavedJobSearchesAPI.hasFetched() &&
      callSavedJobSearchesAPI.hasErrors() === false
    ) {
      setJobsInNotificationSettings(callSavedJobSearchesAPI.data().data, false);
    }
  }, [callSavedJobSearchesAPI.isFetching()]);

  const deleteSavedSearch = () => {
    refreshDeleteSavedSearchAPI(saveJobs[selectedSearch].id);
  };

  useEffect(() => {
    if (
      callingDeleteSavedSearchAPI.hasFetched() &&
      callingDeleteSavedSearchAPI.hasErrors() === false &&
      callingDeleteSavedSearchAPI.data() &&
      callingDeleteSavedSearchAPI.data().message
    ) {
      toastify("success", callingDeleteSavedSearchAPI.data().message);
      setSelectedSearch(null);
      setShowDeleteSearchPopup(false);
      refreshSavedJobSearchesAPI();
    }
  }, [callingDeleteSavedSearchAPI.isFetching()]);

  useEffect(() => {
    if (
      callJobSearchNotificationsAPI.hasFetched() &&
      callJobSearchNotificationsAPI.hasErrors() === false
    ) {
      setJobsInNotificationSettings(
        callJobSearchNotificationsAPI.data().data,
        true
      );
    }
  }, [callJobSearchNotificationsAPI.isFetching()]);

  const jobNotifcation = (item) => {
    const { job_saved_search_push, job_saved_search_email } =
      notificationSettings;
    let jobNotiData = {
      push_notification: job_saved_search_push,
      email_notification: job_saved_search_email,
      notification_frequency:
        item.notification_frequency === "no_notification"
          ? "bi-weekly"
          : "no_notification",
    };
    refreshJobSearchNotificationsAPI(item.id, jobNotiData);
  };

  return isFetching ? (
    <JobsListingEffect count={3} />
  ) : (
    <SaveSearchListStyles>
      <h2 className="title-primary mb-15">Job Settings</h2>
      <NotificationSettingPanel
        title="Saved searches"
        subTitle="Receive alerts whenever a new job is posted based on your saved searches."
        type="job_saved_searches"
        appPush={_get(notificationSettings, "job_saved_search_push", false)}
        email={_get(notificationSettings, "job_saved_search_email", false)}
      />
      <NotificationSettingPanel
        title="New recommended jobs"
        subTitle="Receive alerts about new jobs that match your profile."
        type="recommended_jobs"
        appPush={_get(notificationSettings, "recommended_job_push", false)}
        email={_get(notificationSettings, "recommended_job_email", false)}
      />
      <h2 className="title-primary mt-30 mb-15">Saved Searches</h2>

      {saveJobs && saveJobs.length > 0 ? (
        saveJobs.map((item, i) => {
          const country =
            item.country !== null
              ? jobsCountryList.find((rec) => rec.value === item.country)
              : "";
          return (
            <div className="jobs-notification-settings" key={item.title}>
              <NotificationInfoPanel
                notificationType={"saved search"}
                title={item.title}
                subTitle={[
                  `
                ${
                  item.city !== null
                    ? `${capitalizeFirstLetter(item?.city)}${
                        item.state !== null ? "," : ""
                      }`
                    : ""
                }
                  ${
                    item.state !== null
                      ? `${item.state}${country !== null ? "," : ""}`
                      : ""
                  }
                  ${country ? country.label : ""}
                  ${
                    item.created_at !== null
                      ? `|  ${moment(item.created_at).format("MMM DD, YYYY")}`
                      : ""
                  }
                `,
                ]}
                actionName="Delete"
                checked={
                  item &&
                  (item.notification_frequency === "no_notification" ||
                    (!job_saved_search_push && !job_saved_search_email))
                    ? false
                    : true
                }
                isDisabled={!job_saved_search_push && !job_saved_search_email}
                handleChange={() => jobNotifcation(item && item)}
                onClick={() => {
                  setShowDeleteSearchPopup(true);
                  setSelectedSearch(i);
                }}
                isManageNotification={true}
              />
            </div>
          );
        })
      ) : (
        <EmptySaveSearch />
      )}
      {showDeleteSearchPopup && (
        <CustomDialog
          open={showDeleteSearchPopup}
          title={"Delete saved search"}
          handleClose={() => {
            setSelectedSearch(null);
            setShowDeleteSearchPopup(false);
          }}
          className="delete-saved-search-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <p className="warnning-text text-center">
            Are you sure you want to delete this saved search? This action
            cannot be reversed.
          </p>
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="btn mt-30 mb-30">
              <ButtonCustom width="250" height={60} onClick={deleteSavedSearch}>
                Delete Saved Search
              </ButtonCustom>
            </div>
            <a
              href="/#"
              className="ngray-link"
              onClick={(e) => {
                e.preventDefault();
                setShowDeleteSearchPopup(false);
                setSelectedSearch(null);
              }}
              aria-label="cancel"
            >
              Cancel
            </a>
          </div>
        </CustomDialog>
      )}
    </SaveSearchListStyles>
  );
};

export default JobsSettings;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Avatar, Fab, Grid, ClickAwayListener } from "@material-ui/core";
import { MoreHoriz, Close } from "@material-ui/icons";
import ReactPlayer from "react-player";
import { isEmpty } from "lodash";

import usePromise from "../../../hooks/usePromise/usePromise";
import { savedPostAPI, hidePostAPI } from "../../../services/dashboardServices";
import ReadMoreReadLess from "../../atoms/ReadMoreLess/ReadMoreLess";
import { toastify, trapFocus } from "../../../helper/helper";
import CustomDialog from "../../CustomDialog/CustomDialog";
import CardCustom from "../../CardCustom/CardCustom";
import CustomMenu from "../../CustomMenu/CustomMenu";
import DashboardPostSlider from "./DashboardPostSlider/DashboardPostSlider";
import { GRAY_COLOR } from "../../../styles/variables";
import DashboardMiddleStyles from "./DashboardMiddleStyles";
import { Mixpanel } from "../../../mixpanel";
import { trackClickForAnalyticsAPI } from "../../../services/commonServices";
import usePersistState from "../../../state/usePersistState";

import BigPlayIcon from "../../../assets/svg/BigPlayIcon.svg";
import LinkContent from "../../LinkContent/LinkContent";

const MAX_LINES_TOPIC_POST = 5;

const DashboardPost = ({ avatarImg, post, refreshSavedPosts }) => {
  const { users = {} } = usePersistState();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const {
    is_favourite,
    title,
    post_image_type,
    post_image_url,
    id,
    description,
    posted_by,
    posted_by_image,
    created_at,
    share_link,
    youtube_link,
    post_link_type,
    attachments_file = [],
  } = post;

  const [postMenu, setPostMenu] = useState(false);
  const [callSavedPostAPI, refreshSavedPostAPI] = usePromise(savedPostAPI);
  const [callHidePostAPI, refreshHidePostAPI] = usePromise(hidePostAPI);
  const [, refreshTrackClickForAnalyticsAPI] = usePromise(
    trackClickForAnalyticsAPI
  );
  const [isSaved, setIsSaved] = useState(is_favourite);
  const [isHide, setIsHide] = useState(false);

  const isUploadedAttachment =
    !isEmpty(attachments_file) && attachments_file.length > 0;

  const isPdf =
    isUploadedAttachment &&
    attachments_file?.[0]?.file_content_type?.includes("pdf");

  const isVideo =
    post_image_type &&
    post_link_type &&
    (post_image_type ?? "").split("/")?.[0] === "video" &&
    post_image_url;

  const [state, setModel] = useState({
    showModel: false,
    link_type: null,
    image: null,
    link: null,
  });

  useEffect(() => {
    setIsSaved(is_favourite);
  }, [is_favourite]);

  useEffect(() => {
    if (
      callSavedPostAPI.hasFetched() &&
      callSavedPostAPI.hasErrors() === false &&
      callSavedPostAPI.data() &&
      callSavedPostAPI.data().message
    ) {
      toastify("success", callSavedPostAPI.data().message);
      setIsSaved(!isSaved);
      refreshSavedPosts && refreshSavedPosts();
      setPostMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSavedPostAPI.isFetching()]);

  useEffect(() => {
    if (
      callHidePostAPI.hasFetched() &&
      callHidePostAPI.hasErrors() === false &&
      callHidePostAPI.data() &&
      callHidePostAPI.data().message
    ) {
      toastify("success", callHidePostAPI.data().message);
      refreshSavedPosts && refreshSavedPosts();
      setIsHide(true);
      setPostMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callHidePostAPI.isFetching()]);

  const getDateTimeFormatted = (createdDate) => {
    const date = moment(createdDate),
      today = moment();

    const diffDays = today.diff(date, "days"),
      diffYears = today.diff(date, "year");

    if (diffYears === 0 && diffDays === 0) {
      return "at " + moment(createdDate).format("hh:mm a");
    } else if (diffYears === 0 && diffDays === -1) {
      return "at " + moment(createdDate).format("hh:mm a") + " Yesterday";
    } else {
      return (
        "at " +
        moment(createdDate).format("hh:mm a") +
        " on " +
        moment(createdDate).format("dddd, MMM Do YYYY")
      );
    }
  };

  const modelHandler = (
    modelStatus = false,
    link_type = null,
    image = null,
    link = null
  ) => {
    setModel({
      showModel: modelStatus,
      link_type,
      image,
      link,
    });
  };

  function validateYoutubeURL(url = "") {
    if (!url) return false;

    const link =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const isValid = (url ?? "").match(link);
    if (isValid) {
      return isValid[1];
    }
    return false;
  }
  const handleClick = (e = {}) => {
    e.stopPropagation && e.stopPropagation();
    e.preventDefault && e.preventDefault();

    if (users?.school_id)
      refreshTrackClickForAnalyticsAPI("user_post", id, users.school_id);
    Mixpanel.track("Home Feed Post clicked", {
      id,
    });

    // Open pdf in new window
    if (isPdf) {
      window.open(attachments_file?.[0]?.file_url);
      return;
    }

    if (isUploadedAttachment && share_link?.length > 0) {
      window.open(share_link);
      return;
    }

    if (
      post_image_type &&
      post_link_type &&
      post_image_type.split("/")[0] === "video" &&
      post_image_url
    ) {
      modelHandler(true, post_link_type, post_image_url, post_image_url);
      return;
    }

    // if share link or youtube link contains video link
    const isVideoLink = validateYoutubeURL(share_link ?? youtube_link ?? "");
    if (isVideoLink) {
      modelHandler(true, post_link_type, post_image_url, share_link);
      return;
    }
    if (share_link || youtube_link) {
      window.open(share_link ?? youtube_link);
    }
  };

  useEffect(() => {
    if (postMenu) {
      let element = document.getElementById(`post-action-${postMenu}`);
      trapFocus(element);
    }
  }, [postMenu]);

  const isClickable = share_link || youtube_link || isPdf || isVideo;

  return (
    <DashboardMiddleStyles id={id}>
      <>
        {!isHide && (
          <CardCustom
            className="dashboard-card"
            avatar={
              <Avatar
                src={posted_by_image ? posted_by_image : avatarImg}
                alt={`${posted_by}'s profile picture`}
                className="dashboard-post-avatar"
              />
            }
            action={
              <ClickAwayListener onClickAway={() => setPostMenu(false)}>
                <div id={`post-action-${id}`}>
                  <Fab
                    size="small"
                    color="inherit"
                    aria-label={`Post ${title}'s Menu`}
                    onClick={(e) => {
                      if (e) e.preventDefault();
                      setPostMenu(postMenu ? false : id);
                      if (e) e.stopPropagation();
                    }}
                    disableRipple={true}
                    aria-expanded={postMenu ? true : false}
                  >
                    {postMenu ? (
                      <Close htmlColor={GRAY_COLOR} />
                    ) : (
                      <MoreHoriz htmlColor={GRAY_COLOR} />
                    )}
                  </Fab>
                  <CustomMenu
                    isopen={postMenu}
                    marginPercentage="2%"
                    menuList={[
                      {
                        id: 1,
                        title: isSaved ? "Remove from Saved" : "Save Post",
                      },
                      { id: 2, title: "Hide" },
                    ]}
                    menuOnClick={(e) => {
                      if (e.id === 1) {
                        refreshSavedPostAPI(id);
                      } else {
                        refreshHidePostAPI(id);
                      }
                    }}
                    align="left"
                  />
                </div>
              </ClickAwayListener>
            }
            title={posted_by}
            subheader={getDateTimeFormatted(created_at)}
          >
            <div className="dashboard-post">
              {isUploadedAttachment ||
              !isEmpty(share_link) ||
              !isEmpty(youtube_link) ? (
                <div
                  className="post-wrapper"
                  aria-label={`Know more about ${title}`}
                >
                  <a
                    href="/#"
                    className={`no-underline ${
                      !isClickable &&
                      (!isUploadedAttachment ||
                        (!isEmpty(attachments_file) &&
                          attachments_file.length === 1))
                        ? "pointer-none"
                        : ""
                    }`}
                    onClick={(e) => handleClick(e)}
                  >
                    {isPdf ? (
                      <Grid item xs={12}>
                        <div className={"post-tag"}>PDF</div>
                        <img
                          src={attachments_file?.[0]?.thumb_file_url}
                          className={"responsive cursor-pointer"}
                          width={"590px"}
                          alt={`More about ${title}`}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Grid>
                    ) : isUploadedAttachment ? (
                      <DashboardPostSlider
                        files={attachments_file}
                        handleClick={handleClick}
                        isClickable={isClickable}
                      />
                    ) : post_image_type &&
                      post_link_type &&
                      post_image_type.split("/")[0] === "video" &&
                      post_image_url ? (
                      <Grid
                        item
                        xs={12}
                        className={`responsive ${
                          (youtube_link || share_link) && "cursor-pointer"
                        }`}
                      >
                        <video
                          controls
                          autoPlay={accessibilityHelp === "true" ? false : true}
                          muted
                        >
                          <source src={post_image_url} />
                        </video>
                      </Grid>
                    ) : (
                      <>
                        {post_link_type !== "announcement" &&
                          post_image_url && (
                            <Grid item xs={12}>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={post_image_url}
                                  className={`responsive ${
                                    (youtube_link || share_link) &&
                                    "cursor-pointer"
                                  }`}
                                  width={"590px"}
                                  alt={`More about ${title}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                {share_link?.split("watch?v=")[1] ? (
                                  <img
                                    src={BigPlayIcon}
                                    className="play-icon cursor-pointer"
                                    alt="play-icon"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                ) : null}
                              </div>
                            </Grid>
                          )}
                      </>
                    )}
                  </a>

                  {title && (
                    <h2 className="post-title text-primary">{title}</h2>
                  )}

                  {description?.length > 0 && (
                    <>
                      <p
                        className={`post-body text-primary ${
                          post_link_type === "announcement"
                            ? "font-size-large"
                            : ""
                        }`}
                      >
                        <LinkContent>
                          <ReadMoreReadLess
                            maxLines={MAX_LINES_TOPIC_POST}
                            moreComp={
                              <p className="read-more-less">Read more</p>
                            }
                            lessComp={
                              <p className="read-more-less">Read less</p>
                            }
                          >
                            {description}
                          </ReadMoreReadLess>
                        </LinkContent>
                      </p>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <a
                    href="/#"
                    className={`no-underline ${
                      !isClickable &&
                      (!isUploadedAttachment ||
                        (!isEmpty(attachments_file) &&
                          attachments_file.length === 1))
                        ? "pointer-none"
                        : ""
                    }`}
                    onClick={(e) => handleClick(e)}
                  >
                    {isVideo ? (
                      <Grid item xs={12}>
                        <div style={{ position: "relative" }}>
                          <video
                            src={post_image_url}
                            className={`responsive `}
                            width={"590px"}
                            alt={`Interstride Video`}
                            controls={false}
                            autoPlay={false}
                            style={{ width: "100%" }}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                          <img
                            src={BigPlayIcon}
                            className="play-icon cursor-pointer"
                            alt="play-icon"
                            cursor="pointer"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <>
                        {post_link_type !== "announcement" && (
                          <Grid item xs={12}>
                            <img
                              src={post_image_url}
                              className={`responsive ${
                                (youtube_link || share_link) && "cursor-pointer"
                              }`}
                              width={"590px"}
                              alt={`More about ${title}`}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </a>

                  {title && (
                    <h2 className="post-title text-primary">{title}</h2>
                  )}
                  {description?.length > 0 && (
                    <>
                      <p
                        className={`post-body text-primary ${
                          post_link_type === "announcement"
                            ? "font-size-large"
                            : ""
                        }`}
                      >
                        <LinkContent>
                          <ReadMoreReadLess
                            maxLines={MAX_LINES_TOPIC_POST}
                            moreComp={
                              <p className="read-more-less">Read more</p>
                            }
                            lessComp={
                              <p className="read-more-less">Read less</p>
                            }
                          >
                            {description}
                          </ReadMoreReadLess>
                        </LinkContent>
                      </p>
                    </>
                  )}
                </>
              )}
              {state.showModel && (
                <div className="dashboard-post-modal">
                  <CustomDialog
                    className="dashboard-post-modal__component"
                    open={state.showModel}
                    handleClose={(e) => {
                      if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                      modelHandler();
                    }}
                  >
                    <div className="dashboard-post-modal__body">
                      {state.link_type === "link_image" ? (
                        <div>
                          <video
                            controls
                            autoPlay={false}
                            muted
                            style={{ width: "100%" }}
                            controlsList="nodownload"
                          >
                            <source src={post_image_url} />
                          </video>
                        </div>
                      ) : null}

                      {state.link_type === "article" && (
                        <div>
                          {state.link ? (
                            <ReactPlayer
                              url={state.link}
                              controls={true}
                              width={"auto"}
                            />
                          ) : (
                            <h3>{`Oops, ${state.link_type} is not available`}</h3>
                          )}
                        </div>
                      )}
                    </div>
                  </CustomDialog>
                </div>
              )}
            </div>
          </CardCustom>
        )}
      </>
    </DashboardMiddleStyles>
  );
};

export default DashboardPost;

import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import _isNull from "lodash/isNull";
import { Grid } from "@material-ui/core";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerThumbnail,
} from "react-shimmer-effects";

import CountryInsightStyles from "./CountryInsightStyles";
import usePromise from "../../../hooks/usePromise/usePromise";
import AllCountriesView from "./AllCountriesView";
import CountryView from "./CountryView";
import CityView from "./CityView";

import {
  listCountriesAPI,
  countryDetailsAPI,
  cityDetailsAPI,
} from "../../../services/countryInsightServices";

const CountryInsight = () => {
  let query = new URLSearchParams(useLocation().search);
  let countryParam = query.get("country");

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryDetails, setCountryDetails] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityDetails, setCityDetails] = useState(null);

  const [callingListCountriesAPI, refreshListCountriesAPI] =
    usePromise(listCountriesAPI);
  const [callingCountryDetailsAPI, refreshCountryDetailsAPI] =
    usePromise(countryDetailsAPI);
  const [callingCityDetailsAPI, refreshCityDetailsAPI] =
    usePromise(cityDetailsAPI);

  useEffect(() => {
    refreshListCountriesAPI();
  }, []);

  const handleCountryClick = (e, data) => {
    e.preventDefault();
    setSelectedCountry(data);
    refreshCountryDetailsAPI(data.id);
  };

  const handleCityClick = (e, data) => {
    e.preventDefault();
    setSelectedCity(data);
    refreshCityDetailsAPI(data.id);
  };

  useEffect(() => {
    if (
      callingListCountriesAPI.hasFetched() &&
      callingListCountriesAPI.hasErrors() === false &&
      callingListCountriesAPI._data &&
      callingListCountriesAPI._data.countries
    ) {
      setCountries(callingListCountriesAPI._data.countries);

      if (countries.length === 0 && countryParam) {
        const selectedCountry = callingListCountriesAPI._data.countries.find(
          (rec) => rec.code === countryParam
        );

        if (selectedCountry) {
          setSelectedCountry(selectedCountry);
          refreshCountryDetailsAPI(selectedCountry.id);
        }
      }
    }
  }, [callingListCountriesAPI.isFetching()]);

  useEffect(() => {
    if (
      callingCountryDetailsAPI.hasFetched() &&
      callingCountryDetailsAPI.hasErrors() === false &&
      callingCountryDetailsAPI._data
    ) {
      setCountryDetails({
        ...callingCountryDetailsAPI._data,
        cities: _isNull(callingCountryDetailsAPI._data.cities)
          ? []
          : callingCountryDetailsAPI._data.cities,
      });
    }
  }, [callingCountryDetailsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingCityDetailsAPI.hasFetched() &&
      callingCityDetailsAPI.hasErrors() === false &&
      callingCityDetailsAPI._data
    ) {
      setCityDetails(callingCityDetailsAPI._data.city);
    }
  }, [callingCityDetailsAPI.isFetching()]);

  const LoadingEffectWithCountriesInsight = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <ShimmerText />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="country-insights__items">
              <Grid container spacing={2}>
                {Array.from(Array(20).keys()).map((_, index) => (
                  <Grid item sm={4} md={3} lg={3} xs={6} key={index}>
                    <ShimmerThumbnail height={55} className="m-0" />
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="country-insights__items">
              <ShimmerThumbnail height={330} className="m-0" rounded />
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const LoadingEffectWithCityInsight = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <ShimmerText />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12} sm={2} md={2} lg={2} item>
            <ShimmerCategoryItem />
            <ShimmerCategoryItem />
          </Grid>
          <Grid xs={12} sm={5} md={5} lg={5} item>
            <ShimmerText line={14} />
          </Grid>
          <Grid xs={12} sm={5} md={5} lg={5} item>
            <ShimmerThumbnail height={330} className="m-0" rounded />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <CountryInsightStyles>
      <div className="country-mainpage">
        {!_isNull(selectedCity) ? (
          <>
            {callingCountryDetailsAPI.isFetching() || _isNull(cityDetails) ? (
              <LoadingEffectWithCityInsight />
            ) : (
              <CityView
                selectedCountry={selectedCountry}
                countryDetails={countryDetails}
                selectedCity={selectedCity}
                cityDetails={cityDetails}
                onBack={() => {
                  setSelectedCity(null);
                  setCityDetails(null);
                }}
                handleCityClick={handleCityClick}
              />
            )}
          </>
        ) : !_isNull(selectedCountry) ? (
          <>
            {callingCountryDetailsAPI.isFetching() ||
            _isNull(countryDetails) ? (
              <LoadingEffectWithCityInsight />
            ) : (
              <CountryView
                selectedCountry={selectedCountry}
                countryDetails={countryDetails}
                onBack={() => {
                  setSelectedCountry(null);
                  setCountryDetails(null);
                }}
                handleCityClick={handleCityClick}
              />
            )}
          </>
        ) : (
          <>
            {callingListCountriesAPI.isFetching() ? (
              <LoadingEffectWithCountriesInsight />
            ) : (
              <AllCountriesView
                countries={countries}
                handleCountryClick={handleCountryClick}
              />
            )}
          </>
        )}
      </div>
    </CountryInsightStyles>
  );
};

export default CountryInsight;

import React from "react";
import { map, isNull, isEmpty } from "lodash";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { Mixpanel } from "../../../../mixpanel";
import { InfoPanel } from "../../../InfoPanel/InfoPanel";
import { routes } from "../../../../routes";
import NotificationRightsidebarStyles from "./NotificationRightsidebarStyles";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import EmptyMiddleBox from "../../../EmptyModdleBox/EmptyMiddleBox";
import JobsListingEffect from "../../../effects/JobsListingEffect/JobsListingEffect";

import MessageIcon from "../../../../assets/Notifications/Networking.svg";
import MyTopicsIcon from "../../../../assets/Notifications/Topics.svg";
import webinarsIcon from "../../../../assets/Notifications/Webinar.svg";
import calendarIcon from "../../../../assets/Notifications/Calendar.svg";
import searchJobsIcon from "../../../../assets/svg/searchJobsIcon.svg";
import resourceIcon from "../../../../assets/Notifications/Resources.svg";
import studentServiceIcon from "../../../../assets/Notifications/Services.svg";
import postIcon from "../../../../assets/Notifications/Post.svg";
import deleteIcon from "../../../../assets/svg/f-delete-icon.svg";

const NotiTextLabel = (type, options = {}) => {
  /** Calendar & job type is remaining not getting in response */
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  const prepareURL = (path) =>
    Object.keys(options).length > 0 ? `${path}?${queryString}` : path;
  switch (type) {
    case "network_messages":
    case "group_messages":
      return {
        label: "View Network",
        url: routes.NETWORK.MESSAGES,
        icon: MessageIcon,
        category: "Messages",
      };
    case "new_topics":
    case "created_topics":
    case "followed_topics":
    case "follow_requests":
      return {
        label: "View Community",
        url: routes.NETWORK.TOPICS,
        icon: MyTopicsIcon,
        category: "Communities",
      };
    case "user_post":
      return {
        label: "View Post",
        url: prepareURL(routes.DASHBOARD),
        icon: postIcon,
        category: "Post",
      };
    case "webinar":
      return {
        label: "View Webinar",
        url: prepareURL(routes.LEARN.WEBINARS),
        icon: webinarsIcon,
        category: "Webinars",
      };
    case "deal":
      return {
        label: `View Service`,
        url: prepareURL(routes.LEARN.STUDENT_SERVICES),
        icon: studentServiceIcon,
        category: "Student services",
      };
    case "video":
      return {
        label: `View Resource`,
        url: prepareURL(routes.LEARN.RESOURCES),
        icon: resourceIcon,
        category: "Resources",
      };
    case "appointment":
      return {
        label: "View appointemnt",
        url: prepareURL(routes.TOOLS.MEET),
        icon: calendarIcon,
        category: "Appointment",
      };
    case "featured_job":
      return {
        label: `View Featured Job`,
        url: prepareURL(routes.JOBS.FEATURED_JOBS),
        icon: searchJobsIcon,
        category: "Jobs",
      };
    case "employer_job":
      return {
        label: `View Employer Job`,
        url: prepareURL(routes.JOBS.FEATURED_JOBS),
        icon: searchJobsIcon,
        category: "Jobs",
      };
    default:
      return { label: `View ${type}`, url: null, icon: null };
  }
};
const extractExtraPayload = (type, payloadId, options = {}) => {
  let payload;
  if (type === "appointment") {
    payload = {
      ...options,
    };
  } else {
    payload = {
      notificationId: payloadId,
      ...options,
    };
  }
  const allowed = ["webinar", "user_post", "deal", "video", "appointment"];
  if (allowed.includes(type)) {
    return payload;
  }
  return {};
};
const NotificationRightsidebar = ({
  title = "",
  clearTitle = "",
  notifications = [],
  handleDelete,
  handleClear,
  isFetching = false,
  noTitle,
  className = "",
  isNew = false,
  readApiCall,
}) => {
  return isFetching ? (
    <div className="notification-loader">
      <JobsListingEffect count={5} />
    </div>
  ) : (
    <NotificationRightsidebarStyles>
      <div className="main-noti">
        <h2 className={`title-primary mb-15 ${className}`}>{title}</h2>
        <div className="noti-clear">
          {clearTitle ? (
            <ButtonCustom
              isSubmitting={isFetching}
              onClick={() => {
                handleClear();
                Mixpanel.track("Clear All Notification Button Clicked");
              }}
              height={50}
              width={120}
              className="bg-gray"
            >
              {clearTitle}
            </ButtonCustom>
          ) : (
            ""
          )}
        </div>
      </div>
      {notifications.length > 0 ? (
        map(notifications, (item, index) => {
          const notificationData = NotiTextLabel(item.type);
          return (
            <InfoPanel
              type={notificationData?.category}
              key={index}
              classname="noti-icon"
              marginTop="0px"
              marginBottom="20px"
              displayStar={false}
              avatar={notificationData?.icon}
              avatarBackground={true}
              avatarHeight="30px"
              avatarWidth="30px"
              title={
                isEmpty(item.message) || isNull(item.message)
                  ? item.title
                  : item.message
              }
              subTitle={[moment(item.created_at).fromNow()]}
              action={
                <>
                  {item.type !== "common" && (
                    <ButtonCustom
                      height={30}
                      width={150}
                      onClick={() => {
                        if (notificationData?.url) {
                          const payload = extractExtraPayload(
                            item.type,
                            item.type_id
                          );
                          readApiCall(
                            item.id,
                            NotiTextLabel(item.type, payload).url
                          );
                        } else {
                          Bugsnag.leaveBreadcrumb(
                            "Unhandled notification type click",
                            {
                              type: item.type,
                            },
                            "error"
                          );
                          readApiCall(item.id, routes.NOTIFICATION.DASHBOARD);
                        }
                        Mixpanel.track("View Notification Clicked", {
                          Type: notificationData?.label.slice(5),
                        });
                      }}
                    >
                      {notificationData?.label}
                    </ButtonCustom>
                  )}
                  <ButtonCustom
                    isSubmitting={isFetching}
                    onClick={() => {
                      handleDelete(item.id, isNew);
                      Mixpanel.track("Delete Notification Clicked", {
                        Type: NotiTextLabel(item.type).label.slice(5),
                      });
                    }}
                    height={30}
                    width={75}
                    className="btn-delete"
                  >
                    <img src={deleteIcon} alt="" />
                  </ButtonCustom>
                </>
              }
            />
          );
        })
      ) : (
        <EmptyMiddleBox title={noTitle} />
      )}
    </NotificationRightsidebarStyles>
  );
};

export default NotificationRightsidebar;

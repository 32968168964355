import React, { useEffect, useState } from "react";
import LearnDashboardStyles from "./LearnDashboardStyles";
import { Grid } from "@material-ui/core";
import MyWebinar from "../../../components/Learn/Comman/MyWebinar/MyWebinar";
import SavedResources from "../../../components/Learn/Comman/SavedResources/SavedResources";
import SavedServices from "../../../components/Learn/Comman/SavedServices/SavedServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import { getMyWebinarsAPI } from "../../../services/webinarServices";
import { useAppState } from "../../../context";
import { getSavedServicesAPI } from "../../../services/learnServices";
import usePersistState from "../../../state/usePersistState";

const LearnDashboard = () => {
  const { permissions = {} } = usePersistState();

  const [callingGetMyWebinarsAPI, refreshGetMyWebinarsAPI] =
    usePromise(getMyWebinarsAPI);
  const { setMyWebinars, myWebinars } = useAppState("learn");

  const [savedServiceList, setSavedServiceList] = useState([]);
  const [callingGetSavedServicesAPI, refreshGetSavedServicesAPI] =
    usePromise(getSavedServicesAPI);

  useEffect(() => {
    refreshGetMyWebinarsAPI();
    refreshGetSavedServicesAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetMyWebinarsAPI.hasFetched() &&
      callingGetMyWebinarsAPI.hasErrors() === false &&
      callingGetMyWebinarsAPI.data() &&
      callingGetMyWebinarsAPI.data().data
    ) {
      setMyWebinars(callingGetMyWebinarsAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetMyWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetSavedServicesAPI.hasFetched() &&
      callingGetSavedServicesAPI.hasErrors() === false &&
      callingGetSavedServicesAPI.data() &&
      callingGetSavedServicesAPI.data().data
    ) {
      // console.log(callingGetSavedServicesAPI.data().data);
      setSavedServiceList(
        callingGetSavedServicesAPI.data().data.favorite_deals
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetSavedServicesAPI.isFetching()]);

  const gridSize =
    12 /
    ((permissions["webinar"] ? 1 : 0) +
      (permissions["resources"] ? 1 : 0) +
      (permissions["student_services"] ? 1 : 0));

  return (
    <LearnDashboardStyles>
      {/* <MainLayout title="Learn Dashboard" withBackground={true} tabs={tabs}> */}
      <div className="learn-dashboard">
        <div className="learn-content">
          <Grid container justify="center" spacing={3}>
            {(permissions["webinar"] ||
              (permissions["student_services"] && gridSize === 4)) && (
              <Grid item xs={4}>
                {permissions["webinar"] && (
                  <MyWebinar
                    title="My Webinars"
                    isBack={false}
                    items={myWebinars}
                    loader={callingGetMyWebinarsAPI.isFetching()}
                  />
                )}

                {permissions["student_services"] && gridSize === 4 && (
                  <div className="ShowResponsiveDiv">
                    <SavedServices
                      savedServiceList={savedServiceList}
                      refreshSavedServices={() => {
                        refreshGetSavedServicesAPI();
                      }}
                      showLoader={callingGetSavedServicesAPI.isFetching()}
                    />
                  </div>
                )}
              </Grid>
            )}

            {permissions["resources"] && (
              <Grid item xs={4}>
                <SavedResources />
              </Grid>
            )}

            {permissions["student_services"] && (
              <Grid item xs={4}>
                <SavedServices
                  savedServiceList={savedServiceList}
                  refreshSavedServices={() => {
                    refreshGetSavedServicesAPI();
                  }}
                  showLoader={callingGetSavedServicesAPI.isFetching()}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      {/* </MainLayout> */}
    </LearnDashboardStyles>
  );
};

export default LearnDashboard;

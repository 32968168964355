import React, { useEffect, useLayoutEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useLocation } from "@reach/router";
import { isEmpty } from "lodash";
import moment from "moment";

import Container from "../../components/Container/Container";
import DashboardLeftSidebar from "../../components/Dashboard/DashboardLeftSidebar/DashboardLeftSidebar";
import DashboardMiddle from "../../components/Dashboard/DashboardMiddle/DashboardMiddle";
import DashboardRightSidebar from "../../components/Dashboard/DashboardRightSidebar/DashboardRightSidebar";
import Footer from "../../components/Layouts/Footer/Footer";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import HomeFeedEffect from "../../components/effects/HomeFeedEffect/HomeFeedEffect";
import { useAppState } from "../../context";
import { addAccessibilityClass } from "../../helper/helper";
import { loginRequired } from "../../hoc/loginRequired";
import { useEssentials } from "../../hooks/Profile/useEssentials";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import usePromise from "../../hooks/usePromise/usePromise";
import { useQueryParams } from "../../hooks/useQueryParams";
import { Mixpanel } from "../../mixpanel";
import {
  getDashboardUserPostsAPI,
  getSavedPostsListAPI,
} from "../../services/dashboardServices";
import usePersistState from "../../state/usePersistState";
import DashboardStyles from "./DashboardStyles";
import FeedbackDialog from "../../components/FeedBack";
import ThanksDialog from "../../components/FeedBack/thanks";

import scrollToTOp from "../../assets/svg/f-scroll-to-top.svg";

const Dashboard = () => {
  // App States
  const { isDisplayFooter } = useAppState("app");
  const { statusesLoaded = false, statusList } = useAppState("boostProfile");
  const { user_posts, setUserPosts, setSavedUserPosts, addMoreUserPosts } =
    useAppState("post");

  // Persist State
  const { users = {} } = usePersistState();

  // URL Query process
  let query = new URLSearchParams(useLocation().search);
  const item_id = query.get("item_id");
  const params = useQueryParams();

  // Hooks
  useEssentials();

  // Component States
  const [isFetchMore, setIsFetchMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isBottom, setIsBottom] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const [showThanksModel, setShowThanksModel] = useState(false);

  // API Hooks
  const [callGetDashboardUserPostsAPI, refreshGetDashboardUserPostsAPI] =
    usePromise(getDashboardUserPostsAPI);
  const [callGetSavedPostsListAPI, refreshGetSavedPostsListAPI] =
    usePromise(getSavedPostsListAPI);

  useDocumentTitle("Homepage Dashboard");

  const fetchMoreData = () => {
    setIsFetchMore(true);
    refreshGetDashboardUserPostsAPI(currentPage + 1, 10);
    setCurrentPage(currentPage + 1);
  };

  const _loadHomeFeeds = () => {
    if (params.has("notificationId")) {
      refreshGetDashboardUserPostsAPI(currentPage, 10, {
        post_id: params.get("notificationId"),
      });
      return;
    }
    refreshGetDashboardUserPostsAPI(currentPage, 10);
  };

  const handleScrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const onFeedbackDialogClose = () => {
    // 2 days delay for next time popup
    localStorage.setItem(
      "feedback_dialog_timestamp",
      moment().add(2, "days").format("YYYY-MM-DD HH:mm")
    );
  };

  useLayoutEffect(() => {
    document.body.className = "";
    addAccessibilityClass();
  }, []);

  useEffect(() => {
    refreshGetSavedPostsListAPI();
    Mixpanel.identify(users.id);
    Mixpanel.people.set({
      $user_Id: users.id ? users.id : "",
      $name: users.name ? users.name : "Not Defined",
      $email: users.email ? users.email : "Not Defined",
      $first_name: users.first_name ? users.first_name : "Not Defined",
      $last_name: users.last_name ? users.last_name : "Not Defined",
      $major: users.major ? users.major : "Not Defined",
      $school_name: users.school_name ? users.school_name : "Not Defined",
      $degree: users.degree ? users.degree : "Not Defined",
      $graduation_year: users.graduation_year
        ? users.graduation_year
        : "Not Defined",
      $department_name:
        users.department_ids && !isEmpty(users.department_ids)
          ? users.department_ids[0].name
          : "Not Defined",
      $nationality: users.country_of_origin
        ? users.country_of_origin
        : "Not Defined",
      $linkedIn: users.linkedin_public_url ? "Connected" : "Not Connected",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Show feeback model based on user's feedback status
    if (users?.sign_in_count && users?.sign_in_count > 3) {
      const rateInterstrideStep = statusList.find(
        (status) => status.key === "rate_interstride"
      );
      const feedbackDialogTimestamp = localStorage.getItem(
        "feedback_dialog_timestamp"
      );

      if (
        statusesLoaded &&
        !rateInterstrideStep?.isCompleted &&
        (!feedbackDialogTimestamp ||
          moment().isAfter(moment(feedbackDialogTimestamp)))
      )
        setShowFeedbackModel(true);
      else setShowFeedbackModel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesLoaded]);

  useEffect(() => {
    if (isDisplayFooter) {
      document.body.classList.add("hide-scroll");
      document.body.tabIndex = -1;
    } else {
      document.body.classList.remove("hide-scroll");
      document.body.tabIndex = 0;
    }
  }, [isDisplayFooter]);

  useEffect(() => {
    if (
      callGetDashboardUserPostsAPI.hasFetched() &&
      callGetDashboardUserPostsAPI.hasErrors() === false &&
      callGetDashboardUserPostsAPI.data() &&
      callGetDashboardUserPostsAPI.data().data
    ) {
      setTotalPages(callGetDashboardUserPostsAPI.data().data.total_pages);
      setLoader(false);
      if (isFetchMore) {
        setIsFetchMore(false);
        addMoreUserPosts(callGetDashboardUserPostsAPI.data().data);
      } else {
        setUserPosts(callGetDashboardUserPostsAPI.data().data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetDashboardUserPostsAPI.hasFetched()]);

  useEffect(() => {
    if (
      callGetSavedPostsListAPI.hasFetched() &&
      callGetSavedPostsListAPI.hasErrors() === false &&
      callGetSavedPostsListAPI.data() &&
      callGetSavedPostsListAPI.data().data
    ) {
      setSavedUserPosts(callGetSavedPostsListAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetSavedPostsListAPI.hasFetched()]);

  useEffect(() => {
    if (item_id && item_id !== undefined) {
      setTimeout(function () {
        const element = document.getElementById(item_id);
        if (element && element !== undefined) {
          document.getElementById(item_id).scrollIntoView();
        }
      }, 500);
    }
  }, [item_id, user_posts]);

  useEffect(() => {
    const handleNavigation = () => {
      const position = window.pageYOffset;

      setIsBottom(position > 300);
    };
    window.addEventListener("scroll", handleNavigation);
    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", handleNavigation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  return (
    <DashboardStyles>
      <LoadHomeFeeds
        loadHomeFeeds={() => {
          setLoader(true);
          _loadHomeFeeds();
        }}
      />
      <MainLayout title="Home Dashboard" viewOnlyNavbar={true}>
        <br />
        <Container className="dashboard-content">
          <Grid container spacing={2}>
            <Grid item xs={3} className="sticky-div">
              <DashboardLeftSidebar />
            </Grid>
            <Grid item xs={6}>
              {showLoader ? (
                <HomeFeedEffect />
              ) : (
                <>
                  <DashboardMiddle
                    fetchMoreData={fetchMoreData}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    refreshSavedPosts={refreshGetSavedPostsListAPI}
                    showPaginationLoader={callGetDashboardUserPostsAPI.isFetching()}
                  />
                  <button
                    className="move-top-button"
                    title="Go to top"
                    style={{ display: isBottom ? "block" : "none" }}
                    onClick={() => handleScrollTop()}
                  >
                    <img src={scrollToTOp} alt="scroll to top" />
                  </button>
                </>
              )}
            </Grid>
            <Grid item xs={3} className="sticky-right-div">
              <DashboardRightSidebar />
            </Grid>
          </Grid>
          {showFeedbackModel && (
            <FeedbackDialog
              showModel={showFeedbackModel}
              handleModel={setShowFeedbackModel}
              subModel={setShowThanksModel}
              onClose={onFeedbackDialogClose}
            />
          )}
          {showThanksModel && (
            <ThanksDialog
              showModel={showThanksModel}
              subModel={setShowThanksModel}
            />
          )}
        </Container>
      </MainLayout>
      {isDisplayFooter && <Footer />}
    </DashboardStyles>
  );
};

const LoadHomeFeeds = ({ loadHomeFeeds = () => {} }) => {
  useEffect(() => {
    loadHomeFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default loginRequired(Dashboard);

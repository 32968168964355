import React from "react";
import { navigate } from "@reach/router";

import CustomDialog from "../CustomDialog/CustomDialog";
import BoostProfileProgressBarStyles from "../BoostProfile/BoostProfileProgressBar/BoostProfileProgressBarStyles";
import CustomProgressBar from "../CustomProgressBar/CustomProgressBar";
import ButtonCustom from "../ButtonCustom/ButtonCustom";

const BoostPopup = ({ totalSteps = 5, ...props }) => {
  const redirectFn = () => {
    const getPathName = window.location.pathname;

    if (getPathName == "/boost-profile") {
      props.closeFn();
    } else {
      navigate("/boost-profile");
    }
  };

  return (
    <div className="feedback-model">
      <CustomDialog
        className="feedback-dialog boost-profile-popup"
        open={true}
        title={
          props.totalTrueValues == totalSteps
            ? "Thank you for your feedback!"
            : "Congratulations!"
        }
        dialogTitleClass="modal-title"
        Title2={props.message}
        showMoreTitle={true}
        handleClose={() => props.closeFn()}
      >
        <div className="model-body">
          <div className="rating-box">
            <div className="custom-rating">
              <BoostProfileProgressBarStyles>
                <CustomProgressBar
                  percent={(props.totalTrueValues + 1) * (100 / totalSteps)}
                  totalStep={totalSteps}
                  currentStep={props.totalTrueValues + 1}
                />
              </BoostProfileProgressBarStyles>
            </div>

            <div className="sub-header-primary">{props.sub_message}</div>
          </div>

          {props.totalTrueValues < totalSteps ? (
            <>
              <div className="btn">
                <ButtonCustom width="248" onClick={() => redirectFn()}>
                  Back to Boost profile
                </ButtonCustom>
              </div>
              <div className="text-center">
                <a
                  href="/#"
                  className="ngray-link"
                  onClick={(e) => {
                    e.preventDefault();
                    props.closeFn();
                  }}
                  aria-label="Continue later"
                >
                  I'll continue later
                </a>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </CustomDialog>
    </div>
  );
};

export default BoostPopup;

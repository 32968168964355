import React from "react";
import { navigate } from "@reach/router";
import { Doughnut } from "react-chartjs-2";

import {
  PRIMARY_MAIN_COLOR,
  ICON_BACKGROUND_HOVER,
  ACCESSIBILITY_SECONDARY_COLOR,
} from "../../../../styles/variables";
import ButtonCustom from "../../../../components/ButtonCustom/ButtonCustom";
import usePersistState from "../../../../state/usePersistState";

const CompletedTest = ({ userScore = 0 }) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { resetTestState } = usePersistState();

  const scoreText =
    userScore >= 54
      ? "Looks like you’re ready to begin your career search!"
      : userScore >= 44 && userScore <= 53
      ? "You are approaching readiness. We’ll help you make career-ready next time. Learn which component you can improve on."
      : userScore >= 24 && userScore <= 43
      ? "Don’t worry, we’ll help you score higher next time. Learn which components you can improve on"
      : userScore <= 23
      ? "Don’t worry, we’ll help you score higher next time. Learn which components you can improve on"
      : "Don’t worry, we’ll help you score higher next time. Learn which components you can improve on";

  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const data = {
    labels: ["Your score", "Improvement"],
    datasets: [
      {
        data: [userScore, 100 - userScore],
        backgroundColor: [
          PRIMARY_MAIN_COLOR,
          accessibilityHelp == "true"
            ? ACCESSIBILITY_SECONDARY_COLOR
            : ICON_BACKGROUND_HOVER,
        ],
      },
    ],
  };

  return (
    <>
      <div className="game-completed-wrapper">
        <div className="test-title">Congrats, you’ve completed the test!</div>
        <div className="score-chart">
          <Doughnut data={data} options={options} width={186} height={186} />
          <span>{userScore}%</span>
        </div>
        <div className="test-sub-title" style={{ width: "440px" }}>
          {scoreText}
        </div>
        <div className="btn-next text-center">
          <ButtonCustom
            width={205}
            onClick={() => {
              resetTestState();
              navigate("/tools/career-gameplan");
            }}
          >
            {userScore === 100 ? "All done" : "Learn more"}
          </ButtonCustom>
        </div>
      </div>
    </>
  );
};

export default CompletedTest;

import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .footer {
    position: fixed;
    bottom: 0;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(128, 148, 171, 0.4);
    display: flex;
    align-items: flex-end;
    .footer-content {
      display: flex;
      width: 100%;
      background: #fff;
      flex-flow: column;
      padding: 50px 40px 30px;
      &__button {
        display: flex;
        justify-content: flex-end;
        img {
          width: 25px;
          cursor: pointer;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-right: 80px;

        li {
          display: flex;
          flex-flow: column;

          .logo-content {
            width: 200px;
            display: flex;
            align-items: flex-end;
            flex-flow: column;
          }

          .footer-logo {
            img {
              height: 43px;
            }
          }
          .tag-line {
            width: 150px;
            font-size: 22px;
            font-family: "TTCommons-Medium";
            color: var(--primary-dark-color);
          }

          .footer-title {
            font-size: 18px;
            font-family: "TTCommons-Medium";
            margin: 0 0 15px;
            color: var(--primary-dark-color);
            font-weight: 500;
            line-height: 19px;
          }

          a {
            margin: 0 0 15px;
            text-decoration: none;
            font-size: 15px;
            color: var(--primary-dark-color);
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`;

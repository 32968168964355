import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { Avatar } from "@material-ui/core";
import { filter, values } from "underscore/modules";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerTitle,
  ShimmerButton,
} from "react-shimmer-effects";
import moment from "moment";
import { get, isNull } from "lodash";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DashboardRightSidebarStyles from "./DashboardRightSidebarStyles";

import usePersistState from "../../../state/usePersistState";
import CardCustom from "../../CardCustom/CardCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { routes } from "../../../routes";
import EventCard from "../../EventCard/EventCard";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../helper/helper";
import { EmptyMyWebinar } from "../../Learn/Comman/MyWebinar/MyWebinar";
import CustomDialog from "../../CustomDialog/CustomDialog";
import EventList from "../../Tools/EventsList/EventList";
import { Mixpanel } from "../../../mixpanel";

import facebookIcon from "../../../assets/SocialIcon/updatedIcons/facebook.svg";
import linkedinIcon from "../../../assets/SocialIcon/updatedIcons/linkedin.svg";
import twitterIcon from "../../../assets/SocialIcon/updatedIcons/twitter.svg";
import BoostPopup from "../../../components/BoostDialog/BoostDialog";
import {
  getUpcomingWebinarsAPI,
  getMyWebinarsAPI,
} from "../../../services/webinarServices";
import { getPromoteAPI } from "../../../services/promoteService";
import {
  getEventList,
  getAppointments,
} from "../../../services/calendarServices";
import ChromeExtensionSlide from "./CustomSliders/ChromeExtensionSlide/ChromeExtensionSlide";
import MobileAppSlide from "./CustomSliders/MobileAppSlide/MobileAppSlide";
import OPTCalculculatorSlide from "./CustomSliders/OPTCalculculatorSlide/OPTCalculculatorSlide";

const DashboardRightSidebar = ({ isResponsive = false }) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { permissions = {} } = usePersistState();
  const { setCalendarEvents, setCalendarAppointments, upCommingEvents } =
    useAppState("tools");
  const { statusList, setStatusList } = useAppState("boostProfile");
  const { myWebinars, setMyWebinars } = useAppState("learn");

  const [callGetUpcominWebinarsAPI, refreshGetUpcomingWebinarsAPI] = usePromise(
    getUpcomingWebinarsAPI
  );
  const [callingGetMyWebinarsAPI, refreshGetMyWebinarsAPI] =
    usePromise(getMyWebinarsAPI);
  const [callPromoteAPI, refreshPromoteAPI] = usePromise(getPromoteAPI);
  const [callgetEventList, refreshgetEventList] = usePromise(getEventList);
  const [callgetAppointment, refreshgetAppointmentList] =
    usePromise(getAppointments);

  const [totalTrueValues, settotalTrueValues] = useState(0);
  const [showBoostPopup, setShowBoostPopup] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [showRefferModel, setRefferModel] = useState(false);
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [upcomingWebinars, setUpcomingWebinars] = useState([]);

  const handleClose = () => {
    setRefferModel(false);
  };

  const takeAction = (item) => {
    if (item.isAdminPromote) {
      Mixpanel.track("Admin portal promote visited", {
        promote_id: item.id,
        promote_title: item.title,
        promote_link: item.buttonLink,
      });
    }

    if (!item.buttonLink) {
      setRefferModel(true);
    } else {
      if (item.newTab) window.open(item.buttonLink, "_blank");
      else navigate(item.buttonLink);
    }
  };

  useEffect(() => {
    refreshgetEventList();
    if (permissions["appointment"]) {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let year = dateObj.getUTCFullYear();
      refreshgetAppointmentList(month, year);
    }
  }, []);

  useEffect(() => {
    if (
      callgetEventList.hasFetched() &&
      callgetEventList.hasErrors() === false &&
      callgetEventList.data() &&
      callgetEventList.data().data
    ) {
      setCalendarEvents(callgetEventList.data().data);
    }
  }, [callgetEventList.isFetching()]);

  useEffect(() => {
    if (
      callgetAppointment.hasFetched() &&
      callgetAppointment.hasErrors() === false
    ) {
      setCalendarAppointments(callgetAppointment.data());
    }
  }, [callgetAppointment.isFetching()]);

  useEffect(() => {
    refreshGetUpcomingWebinarsAPI();
    refreshGetMyWebinarsAPI();
    refreshPromoteAPI();

    if (accessibilityHelp == "true") {
      const nextButtons = document.getElementsByClassName("slick-next");
      const prevButtons = document.getElementsByClassName("slick-prev");
      if (nextButtons && nextButtons.length > 0) {
        nextButtons[0].setAttribute("aria-label", "Next Slide");
        if (nextButtons[1])
          nextButtons[1].setAttribute("aria-label", "Next Slide");
      }
      if (prevButtons && prevButtons.length > 0) {
        prevButtons[0].setAttribute("aria-label", "Previous Slide");
        if (prevButtons[1])
          prevButtons[1].setAttribute("aria-label", "Previous Slide");
      }
    }
  }, []);

  useEffect(() => {
    if (
      callGetUpcominWebinarsAPI.hasFetched() &&
      callGetUpcominWebinarsAPI.hasErrors() === false &&
      callGetUpcominWebinarsAPI.data() &&
      callGetUpcominWebinarsAPI.data().data
    ) {
      setUpcomingWebinars(callGetUpcominWebinarsAPI.data().data);
      if (
        callGetUpcominWebinarsAPI.data().data &&
        callGetUpcominWebinarsAPI.data().data.boost_profile &&
        callGetUpcominWebinarsAPI.data().data.boost_profile.registered_webinar
      ) {
        setStatusList(callGetUpcominWebinarsAPI.data().data.boost_profile);

        const response = filter(
          values(callGetUpcominWebinarsAPI.data().data.boost_profile),
          function (data) {
            return data == true;
          }
        ).length;

        settotalTrueValues(response);
        setShowBoostPopup(true);
      }
    }
  }, [callGetUpcominWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetMyWebinarsAPI.hasFetched() &&
      callingGetMyWebinarsAPI.hasErrors() === false &&
      callingGetMyWebinarsAPI.data() &&
      callingGetMyWebinarsAPI.data().data
    ) {
      setMyWebinars(callingGetMyWebinarsAPI.data().data);
    }
  }, [callingGetMyWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callPromoteAPI.hasFetched() &&
      callPromoteAPI.hasErrors() === false &&
      callPromoteAPI.data() &&
      callPromoteAPI._data.data &&
      callPromoteAPI._data.data.promotions
    ) {
      const { promotions = [] } = callPromoteAPI._data.data;
      const formattedPromotes = promotions.map((promote) => ({
        title: promote.title,
        topImage: promote.file_url,
        buttonText: promote.action_text,
        buttonLink: promote.link,
        subTitle: promote.description,
        className: "w-70",
        isAdminPromote: true,
        id: promote.id,
        newTab: true,
      }));
      setPromotions([
        ...INTERSTRIDE_PRODUCTS_SLIDER,
        ...formattedPromotes,
        ...HOME_SLIDER,
      ]);
    }
  }, [callPromoteAPI.isFetching()]);

  const closeFN = () => {
    setShowBoostPopup(false);
  };

  let settings = {
    accessibility: true,
    dots: true,
    arrows: accessibilityHelp == "true" ? true : false,
    infinite: true,
    speed: 2000,
    pauseOnHover: true,
    autoplay: accessibilityHelp === "true" ? false : true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: accessibilityHelp == "true" ? "accessibility-slider" : "",
    customPaging: function renderButtons(i) {
      return (
        <button
          aria-label={`slide ${i + 1}`}
          id={`${isResponsive ? "responsive-" : ""}btn-slide-${i}`}
          {...(i === currentSlide ? { "aria-current": true } : {})}
        >
          {i + 1}
        </button>
      );
    },
    beforeChange: function (_, next) {
      setNextSlide(next);
    },
    afterChange: function (i) {
      setCurrentSlide(i);
    },
  };

  // const LegalpadPromotes = [
  //   {
  //     isLegalpadPromote: true,
  //     image: "/images/Interstride-Banner-Option.png",
  //     buttonLink: "https://legalpad.io/partner/interstride/",
  //     promoteImageClassname: "us-work-visa__rounded",
  //   },
  // ];

  const INTERSTRIDE_PRODUCTS_SLIDER = [
    {
      customUI: <OPTCalculculatorSlide />,
      permission: permissions["opt_calculator"],
    },
    {
      customUI: <ChromeExtensionSlide />,
    },
    {
      customUI: <MobileAppSlide />,
    },
  ];

  const HOME_SLIDER = [
    // ...(permissions["chat"]
    //   ? [
    //       {
    //         title: "Meet advisors, alumni mentors, and more.",
    //         subImage: [
    //           { name: "Mark Smith", imageSrc: STa3 },
    //           { name: "Mark Smith", imageSrc: STa2 },
    //           { name: "Mark Smith", imageSrc: STa1 },
    //           { name: "Mark Smith", imageSrc: STa4 },
    //         ],
    //         buttonText: "Network",
    //         buttonLink: routes.NETWORK.DASHBOARD,
    //         className: "",
    //       },
    //     ]
    //   : []),
    // ...(permissions["visa_insights"]
    //   ? [
    //       {
    //         title: "Looking for a job in the United States?",
    //         subTitle:
    //           "Get Visa Insights about companies based in the U.S. to understand what opportunities are available.",
    //         buttonText: "Get U.S. Visa Insights",
    //         buttonLink: routes.JOBS.VISA,
    //         className: "",
    //       },
    //     ]
    //   : []),
    // ...(permissions["topics"]
    //   ? [
    //       {
    //         title: "Stay up to date on Topics you care about.",
    //         subImage: [
    //           { name: "Mark Smith", imageSrc: STa5 },
    //           { name: "Mark Smith", imageSrc: STa6 },
    //           { name: "Mark Smith", imageSrc: STa7 },
    //           { name: "Mark Smith", imageSrc: STa8 },
    //         ],
    //         buttonText: "View Topics",
    //         buttonLink: routes.NETWORK.TOPICS,
    //         className: "",
    //       },
    //     ]
    //   : []),
    {
      title: "Discover orientation courses tailored for you.",
      subTitle:
        "Learn more about U.S. work authorization and job search startegies for international students.",
      buttonText: "View Courses",
      buttonLink: `${routes.LEARN.COURSES}?course=orientation`,
      className: "",
    },
    // {
    //   title: "Take our immigration course.",
    //   subTitle:
    //     "Get an in-depth understanding of all the work authorization options.",
    //   buttonText: "View Courses",
    //   buttonLink: routes.LEARN.COURSES,
    //   className: "",
    // },
  ];

  return (
    <DashboardRightSidebarStyles>
      <React.Fragment>
        {callPromoteAPI.isFetching() ? (
          <div className="promote-previews-loading-container">
            <ShimmerTitle />
            <ShimmerText />
            <ShimmerButton size={"lg"} />
          </div>
        ) : (
          <React.Fragment>
            {promotions && promotions.length > 0 && (
              <React.Fragment>
                <Slider {...settings}>
                  {promotions
                    .filter((promotion) => promotion.permission !== false)
                    .map((item, i) => (
                      <CardCustom
                        className={`slider-card ${
                          accessibilityHelp == "true"
                            ? "accessibility-slider-card"
                            : ""
                        }`}
                        key={i}
                      >
                        {item.customUI ? (
                          item.customUI
                        ) : (
                          <>
                            {item.topImage && (
                              <div className={`top-image ${item.className}`}>
                                <img src={item.topImage} alt="Illustration" />
                              </div>
                            )}
                            {item.title && (
                              <div
                                className={`title text-primary ${item.titleClass}`}
                              >
                                {item.title}
                              </div>
                            )}
                            {item.subTitle && (
                              <div className="sub-title text-primary">
                                {item.subTitle}
                              </div>
                            )}
                            {item.subTitle2 && (
                              <div className="title title-visto text-primary">
                                {item.subTitle2}
                              </div>
                            )}
                            {item.subImage && (
                              <div className={`avatar-list ${item.className}`}>
                                <ul>
                                  {item.subImage.map((item, i) => (
                                    <li key={i}>
                                      <Avatar
                                        src={item.imageSrc}
                                        alt="Subimage"
                                      />
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {item.isMobSquadPromote ||
                            item.isLegalpadPromote ? (
                              <div
                                className={`full-image ${
                                  item.promoteImageClassname ?? ""
                                }`}
                              >
                                <a
                                  href={item.buttonLink}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  aria-label="promote ads"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Mixpanel.track("Custom Ads Clicked", {
                                      promote_title: item.isLegalpadPromote
                                        ? "Legalpad"
                                        : "Mobsquad",
                                      page_source: "Dashboard -> Promotes",
                                      promote_link: item.buttonLink,
                                    });
                                    window.open(item.buttonLink);
                                  }}
                                >
                                  <img
                                    src={item.image}
                                    alt={
                                      item.isMobSquadPromote
                                        ? "Mobsquad Ad"
                                        : "Legalpad Ad"
                                    }
                                  />
                                </a>
                              </div>
                            ) : (
                              <ButtonCustom
                                width={265}
                                onClick={() => takeAction(item)}
                                keepLabelCase={true}
                                isDisabled={nextSlide !== i}
                                className="bg-blue"
                              >
                                {item.buttonText}
                              </ButtonCustom>
                            )}
                          </>
                        )}
                      </CardCustom>
                    ))}
                </Slider>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {permissions["appointment"] && upCommingEvents.length > 0 ? (
          <React.Fragment>
            <div className="appointments-wrapper remove-appointments-top">
              <div className="header mb-15">
                <h2 className="title-primary">Appointments</h2>
                <Link to={routes.TOOLS.MEET} className="nlink-secondary">
                  View all {accessibilityHelp == "true" ? " appointments" : ""}
                </Link>
              </div>
              <EventList events={upCommingEvents} />
            </div>
          </React.Fragment>
        ) : null}

        <div className="webinars-wrapper remove-webinar-top mt-30">
          {permissions["webinar"] && (
            <React.Fragment>
              <div className="header mb-15">
                <h2 className="title text-primary">Upcoming webinars</h2>
                <div className="actions">
                  <Link
                    to={routes.LEARN.WEBINARS}
                    className="nlink-secondary link-focus"
                  >
                    Go to webinars
                  </Link>
                </div>
              </div>

              {callGetUpcominWebinarsAPI.isFetching() ? (
                <div className="webinar-previews-loading-container">
                  {Array.from(Array(5).keys()).map((item, index) => (
                    <ShimmerCategoryItem key={index} />
                  ))}
                </div>
              ) : upcomingWebinars && upcomingWebinars.length > 0 ? (
                upcomingWebinars.map((item, i) => {
                  if (i < 3) {
                    return (
                      <div className="webinar-items" key={i}>
                        <EventCard
                          key={i}
                          id={`${isResponsive ? "responsive-" : ""}webinar-${
                            item.id
                          }`}
                          className="event-card cursor-default"
                          title={get(item, "name", "")}
                          subTitle={moment
                            .unix(item?.epoch_time)
                            .local()
                            .format("ddd MMMM Do, YYYY")}
                          myWebinars={myWebinars}
                          duration={`${moment
                            .unix(item?.epoch_time)
                            .local()
                            .format(
                              "hh:mm a"
                            )} - ${calculateLocalEndTimeFromEpoch(
                            item?.epoch_time,
                            item && item.duration && !isNull(item.duration)
                              ? item.duration.split(" ")[0]
                              : 0,
                            "minutes"
                          )} (${getLocalTzAbbr()})`}
                          tagName={item.webinar_category_name}
                          actionName="View details"
                        />
                      </div>
                    );
                  }
                })
              ) : (
                <EmptyMyWebinar className="empty-web" />
              )}
            </React.Fragment>
          )}

          <CustomDialog
            open={showRefferModel}
            title={"Refer a friend"}
            handleClose={handleClose}
            className="friend-model"
          >
            <div className="friend-dialog-content">
              <div className="share-content">
                Interstride is a one-stop-shop that empowers international
                students in their job search, networking, mentorship and career
                exploration. <br />
                <br /> Help me onboard University Name to Interstride by
                clicking on{" "}
                <a
                  href="/#"
                  className="text-secondary link-focus"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  aria-label="onboarding help link"
                >
                  this link!
                </a>
              </div>
              <div className="social-links">
                <ul>
                  <li>
                    <img src={linkedinIcon} alt="linkedin" />
                  </li>
                  <li>
                    <img src={twitterIcon} alt="twitter" />
                  </li>
                  <li>
                    <img src={facebookIcon} alt="facebook" />
                  </li>
                  <li>
                    <img src={facebookIcon} alt="facebook" />
                  </li>
                </ul>
                <ButtonCustom
                  color="primary"
                  width={220}
                  className="bg-light-blue ml-auto"
                >
                  Copy to Clipboard
                </ButtonCustom>
              </div>
              <div className="btn">
                <ButtonCustom
                  onClick={() => handleClose()}
                  width="124"
                  height={30}
                >
                  Got It!
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
        </div>
        {showBoostPopup && (
          <BoostPopup
            message={
              totalTrueValues == statusList.length
                ? "Your profile boost is complete"
                : "You've just registered for your first webinar."
            }
            sub_message={
              totalTrueValues == statusList.length
                ? "You're ready to gain the most from the Interstride platform."
                : "You're on the way to gaining the most from the Interstride platform."
            }
            totalSteps={statusList.length}
            totalTrueValues={parseInt(totalTrueValues)}
            closeFn={closeFN}
          />
        )}
      </React.Fragment>
    </DashboardRightSidebarStyles>
  );
};

export default DashboardRightSidebar;

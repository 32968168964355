import React from "react";
import { Grid } from "@material-ui/core";
import CustomOptionTabsStyles from "./CustomOptionTabsStyles";

const CustomOptionTabs = ({
  onClick = (e) => {},
  keyField = "value",
  valueField = "label",
  value,
  label = "Select one of the options",
  options = [],
  id = "",
}) => {
  return (
    <CustomOptionTabsStyles>
      <div className="label">{label}</div>
      <div className="custom-options-list" id={id}>
        <Grid container xs={12} spacing={2}>
          {options.map((item, i) => (
            <Grid item xs={12} sm={12} md={6} key={i}>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(item);
                }}
                className={
                  value !== null && item[keyField] === value[keyField]
                    ? "active"
                    : ""
                }
                aria-label={item[valueField]}
              >
                <span>{item[valueField]}</span>
              </a>
            </Grid>
          ))}
        </Grid>
      </div>
    </CustomOptionTabsStyles>
  );
};

export default CustomOptionTabs;

import React, { useState, useEffect } from "react";
import { Avatar, ClickAwayListener, Tooltip } from "@material-ui/core";
import moment from "moment";
import Picker from "emoji-picker-react";
import { CircleSpinner } from "react-spinners-kit";

import TopicMiddleStyles from "./TopicMiddleStyles";
import CardCustom from "../../CardCustom/CardCustom";
import Comments from "./Comments";
import InitialAvatar from "../../InitialAvatar/InitialAvatar";
import { getUserById } from "../../../services/authenticationServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import TextboxCustom from "../../Textbox/TextboxCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import ReadMoreReadLess from "../../atoms/ReadMoreLess/ReadMoreLess";
import { sendCommentAPI } from "../../../services/topicServices";
import { toastify } from "../../../helper/helper";
import usePersistState from "../../../state/usePersistState";
import StarIcon from "../../StarIcon/StarIcon";
import {
  favouriteTopic,
  getTopicPostComments,
  updateTopicPostReaction,
  saveUnsaveTopicPost,
  reportTopicPost,
  deleteTopicPost,
  deleteTopicPostComment,
} from "../../../services/topicServices";
import { PRIMARY_COLOR } from "../../../styles/variables";
import CustomDialog from "../../CustomDialog/CustomDialog";
import CreatePost from "./CreatePost";
import CreateComment from "../CreateComment/CreateComment";
import LinkContent from "../../LinkContent/LinkContent";

import messageTopicIcon from "../../../assets/svg/message-topic-icon.svg";
import emojiPlusIcon from "../../../assets/svg/emoji-plus.svg";
import topicMenuOpen from "../../../assets/svg/topic-menu-open.svg";
import topicMenuClose from "../../../assets/svg/topic-menu-close.svg";
import pinTopicIcon from "../../../assets/svg/pin-topic.svg";

// Constants
const MAX_LINES_TOPIC_POST = 13;
const MAX_LENGTH_TOPIC_CREATE_COMMENT = 500;
const INITIAL_REPORT_MODAL_STATE = {
  open: false,
  text: "",
  apiLoading: false,
};
const INITIAL_EDIT_MODAL_STATE = {
  open: false,
  edit_topic_post_id: null,
};
const INITIAL_DELETE_MODAL_STATE = {
  open: false,
  delete_topic_post_id: null,
  apiLoading: false,
};
const INITIAL_COMMENT_MENU_STATE = {
  menuOpen: false,
  comment: {},
  editModalOpen: false,
  deleteModalOpen: false,
};

export default function TopicPost({
  key,
  topic,
  setActiveUser,
  setShowUserPopup,
  fetchTopicDetailsAPI = () => {},
}) {
  const {
    activeFilterTopics,
    followedTopics,
    activeTopicId,
    activeTopic,
    updateTopicFavouriteStatus,
    incrementCommentCount,
    decrementCommentCount,
    updateTopicPostReactions,
    updateSavedPostStatus,
    topicPosts,
    setPreApiCallSavedTopic,
  } = useAppState("topic");

  const [topicOfPost, setTopicOfPost] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [emojiContainer, setEmojiContainer] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const [replies, setReplies] = useState({});
  const [reactionsEmojiContainer, setReactionsEmojiContainer] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [reportModalState, setReportModalState] = useState(
    INITIAL_REPORT_MODAL_STATE
  );
  const [editPostModalState, setEditPostModalState] = useState(
    INITIAL_EDIT_MODAL_STATE
  );
  const [deletePostModalState, setDeletePostModalState] = useState(
    INITIAL_DELETE_MODAL_STATE
  );
  const [commentMenuState, setCommentMenuState] = useState(
    INITIAL_COMMENT_MENU_STATE
  );

  const [, refreshgetUserById] = usePromise(getUserById);
  const [callingSendCommentAPI, refreshSendCommentAPI] =
    usePromise(sendCommentAPI);
  const [callingDeleteTopicPostComment, refreshDeleteTopicPostComment] =
    usePromise(deleteTopicPostComment);

  const { users = {} } = usePersistState();

  useEffect(() => {
    if (
      callingSendCommentAPI.hasFetched() &&
      callingSendCommentAPI.hasErrors() === false
    ) {
      if (emojiContainer) setEmojiContainer(false);

      const responseData = callingSendCommentAPI.data();
      toastify("success", responseData?.message);

      if (commentMenuState.editModalOpen) {
        // Editing existing comment
        let _comments = [...(replies?.comments || [])];
        const index = _comments?.findIndex(
          (comment) => comment?.id === commentMenuState.comment?.id
        );
        if (index !== -1) {
          _comments[index].comment = responseData?.data?.topic?.comment;
        }
        setReplies((current) => ({
          ...current,
          comments: _comments,
        }));
        setCommentMenuState(INITIAL_COMMENT_MENU_STATE);
      } else {
        // Creating new comment
        setCommentText("");
        incrementCommentCount(responseData?.data?.topic?.topic_post_id); // Update the comment count in state
        showReplies &&
          setReplies((current) => ({
            ...current,
            comments: [
              {
                // Standardize comment object before insertion
                id: responseData?.data?.topic?.id,
                topic_id: responseData?.data?.topic?.topic_id,
                topic_post_id: responseData?.data?.topic?.topic_post_id,
                user_id: responseData?.data?.topic?.commneted_by_id,
                comment: responseData?.data?.topic?.comment,
                created_at: responseData?.data?.topic?.commented_at,
                commented_by: {
                  id: responseData?.data?.topic?.commneted_by_id,
                  first_name:
                    responseData?.data?.topic?.commneted_by_name?.split(" ")[0],
                  last_name:
                    responseData?.data?.topic?.commneted_by_name?.split(" ")[1],
                  image: responseData?.data?.topic?.commneted_by_image,
                  school_id: null,
                  school_name: null,
                },
                user_type: responseData?.data?.topic?.user_type,
              },
              ...(current?.comments?.length > 0 ? current.comments : []),
            ],
          }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingSendCommentAPI.isFetching()]);

  useEffect(() => {
    if (
      callingDeleteTopicPostComment.hasFetched() &&
      callingDeleteTopicPostComment.hasErrors() === false
    ) {
      const responseData = callingDeleteTopicPostComment.data();
      toastify("success", responseData?.data?.message);

      // Update the state to remove the deleted comment
      let _comments = [...(replies?.comments || [])];
      const index = _comments?.findIndex(
        (comment) => comment?.id === commentMenuState.comment?.id
      );
      if (index !== -1) {
        _comments.splice(index, 1);
      }
      setReplies((current) => ({
        ...current,
        comments: _comments,
      }));
      decrementCommentCount(topic?.id); // Update the comment count in state

      setCommentMenuState(INITIAL_COMMENT_MENU_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingDeleteTopicPostComment.isFetching()]);

  // Extract topic of current post
  useEffect(() => {
    if (followedTopics?.length > 0 && topic?.topic_id) {
      setTopicOfPost(
        followedTopics.filter((item) => item.id === topic.topic_id)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followedTopics]);

  useEffect(() => {
    // If comment box opened, call API to get comments
    if (showReplies) {
      fetchReplies();
    } else {
      // Clear data if box is closed
      setReplies({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReplies]);

  const fetchReplies = async (page = 1, per_page = 5) => {
    setLoadingReplies(true);
    const response = await getTopicPostComments(topic?.id, page, per_page);
    setLoadingReplies(false);
    if (response.success) {
      setReplies((current) => ({
        ...response?.data,
        comments:
          page === 1
            ? response.data.comments
            : [...current.comments, ...response.data.comments],
      }));
    }
  };

  const getUrlFromDesc = (desc) => {
    const urldata = desc.match(/\bhttps?:\/\/\S+/gi);

    if (urldata && urldata.length) {
      return urldata[0];
    }
  };

  const onContactInfoUserClick = async (user_id = null) => {
    const response = await refreshgetUserById(user_id);
    if (response?.success && response?.data?.user_info) {
      setActiveUser(response.data.user_info);
      setShowUserPopup(true);
    }
  };

  const handleSubmitComment = () => {
    const payload = {
      topic_post_comment: {
        comment: commentMenuState.editModalOpen
          ? commentMenuState?.comment?.comment?.trim()
          : commentText.trim(),
        topic_id: topic?.topic_id,
        topic_post_id: topic?.id,
        ...(commentMenuState.editModalOpen
          ? { post_comment_id: commentMenuState?.comment?.id }
          : {}),
      },
    };
    refreshSendCommentAPI(payload);
  };

  const onReactionsEmojiClick = async (event, emojiObject) => {
    setReactionsEmojiContainer(false);
    const response = await updateTopicPostReaction(topic?.id, {
      emoji: emojiObject?.emoji,
      emoji_unicode: emojiObject?.unified,
      emoji_symbol: emojiObject?.names[0],
    });
    if (response.status === 200) {
      updateTopicPostReactions(response?.data?.data);
    } else {
      toastify("error", response?.data?.message);
    }
  };

  const removeEmojiReaction = async (emojiData = {}, index = null) => {
    const emojiUnicode = Object.keys(topic?.details?.emojies)[index];
    if (emojiUnicode) {
      const response = await updateTopicPostReaction(topic?.id, {
        emoji: emojiData?.emoji,
        emoji_unicode: emojiUnicode,
        emoji_symbol: emojiData?.emoji_symbol,
      });
      if (response.status === 200) {
        updateTopicPostReactions(response?.data?.data);
      } else {
        toastify("error", response?.data?.message);
      }
    }
  };

  const handleFavorite = async () => {
    if (topicOfPost?.id) {
      const response = await favouriteTopic(topicOfPost.id);
      if (response?.status === 200) {
        toastify("success", response?.data?.message);
        // Update local app state
        updateTopicFavouriteStatus(topicOfPost.id);
      }
    } else {
      toastify("error");
    }
  };

  const handleEditTopicPost = () => {
    setOptionsOpen(false);
    setEditPostModalState({
      open: true,
      edit_topic_post_id: topic?.id,
    });
  };

  const handleSavePost = async () => {
    setOptionsOpen(false);
    const response = await saveUnsaveTopicPost(topic?.id);
    if (response.status === 200) {
      toastify("success", response.data.message);
      updateSavedPostStatus(topic?.id);
    } else {
      toastify("error", response.data.message);
    }
  };

  const handleSubmitReportPost = async () => {
    setReportModalState((current) => ({
      ...current,
      apiLoading: true,
    }));
    const reportTextTrimmed = reportModalState.text?.trim();
    if (reportTextTrimmed?.length > 0) {
      const response = await reportTopicPost(topic?.id, {
        report_text: reportTextTrimmed,
      });
      if (response?.status === 200) {
        toastify("success", response?.data?.message);
        setReportModalState(INITIAL_REPORT_MODAL_STATE);
      } else {
        toastify("error", response?.data?.message);
        setReportModalState((current) => ({
          ...current,
          apiLoading: false,
        }));
      }
    }
  };

  const handleCloseTopicEditModal = () =>
    setEditPostModalState(INITIAL_EDIT_MODAL_STATE);

  const handleCloseDeletePostModal = () =>
    setDeletePostModalState(INITIAL_DELETE_MODAL_STATE);

  const handleDeletePost = async () => {
    setOptionsOpen(false);
    setDeletePostModalState((current) => ({ ...current, apiLoading: true }));
    const response = await deleteTopicPost(topic?.id);
    if (response?.status === 200) {
      setPreApiCallSavedTopic("topic");
      fetchTopicDetailsAPI(activeTopicId);
      toastify("success", response?.data?.message);
      handleCloseDeletePostModal();
    } else {
      toastify("error", response?.data?.message);
      setDeletePostModalState((current) => ({ ...current, apiLoading: false }));
    }
  };

  const handleDeleteTopicComment = () => {
    refreshDeleteTopicPostComment(commentMenuState.comment.id);
  };

  return (
    <TopicMiddleStyles>
      <CardCustom key={key} id={topic?.id} className="post-card">
        <div className="topic-post">
          <div className="topic-post__title">
            <div
              className="topic-post__title__avatar"
              onClick={() =>
                !topic?.anonymously &&
                topic?.posted_by?.id &&
                onContactInfoUserClick(topic?.posted_by?.id)
              }
              style={{
                cursor: topic?.anonymously ? "initial" : "pointer",
              }}
            >
              {topic?.anonymously ? (
                <InitialAvatar alt="Private post" index={-1} />
              ) : topic?.posted_by?.image?.length > 0 ? (
                <Avatar
                  src={topic?.posted_by?.image}
                  alt={`${
                    topic?.posted_by?.first_name +
                    " " +
                    topic?.posted_by?.last_name
                  }'s profile picture`}
                />
              ) : (
                <InitialAvatar
                  title={
                    topic?.posted_by?.first_name +
                    " " +
                    topic?.posted_by?.last_name
                  }
                  alt={`${
                    topic?.posted_by?.first_name +
                    " " +
                    topic?.posted_by?.last_name
                  }'s profile picture`}
                  index={-1}
                />
              )}
            </div>
            <div className="topic-post__title__name-wrapper">
              <div
                className="topic-post__title__name"
                onClick={() =>
                  !topic?.anonymously &&
                  topic?.posted_by?.id &&
                  onContactInfoUserClick(topic?.posted_by?.id)
                }
                style={{
                  cursor: topic?.anonymously ? "initial" : "pointer",
                }}
              >
                <h4>
                  {topic?.anonymously
                    ? "Anonymous User"
                    : topic?.posted_by?.first_name +
                      " " +
                      topic?.posted_by?.last_name}
                </h4>
              </div>
              {topic?.posted_by?.id === activeTopic?.created_by_id &&
                !topic?.anonymously && (
                  <div className="topic-post__title__tag topic-post__title__tag--community-creator">
                    Community Creator
                  </div>
                )}
              {topic?.external && !topic?.anonymously && (
                <div className="topic-post__title__tag topic-post__title__tag--external">
                  External
                </div>
              )}
              {!topic?.anonymously &&
                topic?.user_types
                  ?.filter((type) =>
                    activeFilterTopics === "student" &&
                    (type === "Admission" || type === "Ambassador")
                      ? false
                      : true
                  )
                  .map((type, index) => (
                    <div
                      className={`topic-post__title__tag ${
                        type === "Mentor"
                          ? "topic-post__title__tag--mentor"
                          : type === "Alumni"
                          ? "topic-post__title__tag--alumni"
                          : type === "Student"
                          ? "topic-post__title__tag--student"
                          : type === "Staff"
                          ? "topic-post__title__tag--staff"
                          : type === "Ambassador"
                          ? "topic-post__title__tag--ambassador"
                          : ""
                      }`}
                      key={index}
                    >
                      {type}
                    </div>
                  ))}
            </div>
            <h4 className="topic-post__title__time">
              {moment(topic?.posted_at).calendar()}
            </h4>
            {topicPosts?.type === "topic" && (
              <div className="topic-post__title__options">
                {topic?.pinned && (
                  <Tooltip title="This post has been pinned by Admin">
                    <img src={pinTopicIcon} alt="pin" />
                  </Tooltip>
                )}
                <img
                  className="topic-post__title__options__menu-icon"
                  alt="menu-icon"
                  src={optionsOpen ? topicMenuClose : topicMenuOpen}
                  onClick={() => {
                    setOptionsOpen((current) => !current);
                  }}
                />
                {optionsOpen && (
                  <ClickAwayListener onClickAway={() => setOptionsOpen(false)}>
                    <div className="topic-post__title__options__menu">
                      {topic?.posted_by?.id === users.id && (
                        <span onClick={handleEditTopicPost}>Edit</span>
                      )}
                      {topic?.posted_by?.id === users.id && (
                        <span
                          onClick={() =>
                            setDeletePostModalState((current) => ({
                              ...current,
                              open: true,
                            }))
                          }
                        >
                          Delete
                        </span>
                      )}
                      <span onClick={handleSavePost}>
                        {topic?.saved === true ? "Unsave post" : "Save post"}
                      </span>
                      <span
                        onClick={() => {
                          setOptionsOpen(false);
                          setReportModalState({
                            ...INITIAL_REPORT_MODAL_STATE,
                            open: true,
                          });
                        }}
                      >
                        Report
                      </span>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            )}
          </div>
          {!activeTopicId && topicOfPost?.topic_name && (
            <Tooltip title={topicOfPost.topic_name}>
              <div className="topic-post__topic">
                <span className="topic-post__topic__name">
                  {topicOfPost.topic_name?.length > 30
                    ? topicOfPost.topic_name?.slice(0, 60) + "..."
                    : topicOfPost.topic_name}
                </span>
                <StarIcon
                  className="topic-post__topic__star"
                  variant={topicOfPost?.favourite ? "filled" : "un-filled"}
                  onClick={handleFavorite}
                />
              </div>
            </Tooltip>
          )}

          <div className="header-primary">
            {topic?.content?.length > 0 && (
              <div className="header-primary__content">
                <h3>
                  <LinkContent>
                    <ReadMoreReadLess
                      maxLines={MAX_LINES_TOPIC_POST}
                      moreComp={
                        <div className="header-primary__content__read-more-less">
                          Read more
                        </div>
                      }
                      lessComp={
                        <div className="header-primary__content__read-more-less">
                          Read less
                        </div>
                      }
                    >
                      {topic?.content}
                    </ReadMoreReadLess>
                  </LinkContent>
                </h3>
                {topic?.image_url?.length > 0 && !topic?.url_title && (
                  <div className="header-primary__content__attachment">
                    <img src={topic?.image_url} alt="topic post" />
                  </div>
                )}
              </div>
            )}
            {topic?.url_title && (
              <div
                className="header-primary__url"
                onClick={() =>
                  getUrlFromDesc(topic?.content)?.length > 0
                    ? window
                        .open(getUrlFromDesc(topic?.content), "_blank")
                        .focus()
                    : null
                }
              >
                {topic?.image_url?.length > 0 && (
                  <div className="header-primary__url__image">
                    <img src={topic?.image_url} alt="topic post" />
                  </div>
                )}
                {topic?.url_title?.length > 0 && (
                  <h4 className="header-primary__url__title">
                    {topic?.url_title}
                  </h4>
                )}
                {topic?.url_description?.length > 0 &&
                  topic?.url_description !== "null" && (
                    <span className="header-primary__url__description">
                      {topic?.url_description}
                    </span>
                  )}
              </div>
            )}
          </div>

          <div className="actions">
            {topic?.details?.comment_counts >= 0 && (
              <div
                className="actions__replies"
                onClick={() => setShowReplies((current) => !current)}
              >
                <img src={messageTopicIcon} alt="message" />
                {showReplies ? (
                  <span>Hide replies</span>
                ) : (
                  <span>
                    {topic?.details?.comment_counts === 0
                      ? "Reply"
                      : `${topic?.details?.comment_counts} ${
                          topic?.details?.comment_counts === 1
                            ? "Reply"
                            : "Replies"
                        }`}
                  </span>
                )}
              </div>
            )}
            {Object.values(topic?.details?.emojies || {})?.map(
              (emoji, index) => (
                <div
                  className="actions__reaction"
                  key={index}
                  onClick={() => removeEmojiReaction(emoji, index)}
                >
                  {emoji?.emoji && emoji?.count && (
                    <span>
                      {emoji?.emoji} {emoji?.count}
                    </span>
                  )}
                </div>
              )
            )}
            <div className="actions__emoji">
              <img
                src={emojiPlusIcon}
                alt="emoji-plus"
                onClick={() => setReactionsEmojiContainer(true)}
              />
              {reactionsEmojiContainer && (
                <ClickAwayListener
                  onClickAway={() => setReactionsEmojiContainer(false)}
                >
                  <div className="actions__emoji__picker">
                    <Picker
                      onEmojiClick={onReactionsEmojiClick}
                      pickerStyle={{ width: "100%" }}
                      disableSkinTonePicker={true}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
          {showReplies && (
            <>
              <CreateComment
                commentText={commentText}
                setCommentText={setCommentText}
                emojiContainer={emojiContainer}
                setEmojiContainer={setEmojiContainer}
                handleSubmitComment={handleSubmitComment}
                maxCommentLength={MAX_LENGTH_TOPIC_CREATE_COMMENT}
                apiFetching={callingSendCommentAPI.isFetching()}
              />
              <div className="comment-section">
                <Comments
                  data={replies}
                  fetchReplies={fetchReplies}
                  loadingReplies={loadingReplies}
                  onContactInfoUserClick={onContactInfoUserClick}
                  topic={topic}
                  commentMenuState={commentMenuState}
                  setCommentMenuState={setCommentMenuState}
                  INITIAL_COMMENT_MENU_STATE={INITIAL_COMMENT_MENU_STATE}
                />
              </div>
            </>
          )}
          {loadingReplies && (
            <div className="comment-loader">
              <CircleSpinner size={20} color={PRIMARY_COLOR} />
            </div>
          )}
          <CustomDialog
            className="feedback-dialog"
            open={reportModalState.open}
            title="Please let us know the reason of the report."
            dialogTitleClass="modal-title"
            handleClose={() => setReportModalState(INITIAL_REPORT_MODAL_STATE)}
          >
            <div className="report-modal">
              <div className="report-modal__textbox">
                <TextboxCustom
                  name="report-text"
                  placeholder="Write your report"
                  value={reportModalState.text}
                  onChange={(e) =>
                    setReportModalState((current) => ({
                      ...current,
                      text: e?.target?.value,
                    }))
                  }
                  isMultiline={true}
                  rows={2}
                />
              </div>
              <div className="report-modal__button">
                <ButtonCustom
                  isSubmitting={reportModalState.apiLoading}
                  isDisabled={reportModalState?.text?.trim() === ""}
                  width={100}
                  height={35}
                  onClick={handleSubmitReportPost}
                >
                  Submit
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
          <CustomDialog
            className="edit-topic"
            open={editPostModalState.open}
            title="Edit Community post"
            dialogTitleClass="modal-title"
            handleClose={handleCloseTopicEditModal}
          >
            <div className="edit-topic__body">
              <CreatePost
                editTopicPostId={editPostModalState.edit_topic_post_id}
                handleCloseTopicEditModal={handleCloseTopicEditModal}
                fetchTopicDetailsAPI={fetchTopicDetailsAPI}
              />
            </div>
          </CustomDialog>
          <CustomDialog
            className="delete-post"
            open={deletePostModalState.open}
            title="Are you sure you want to delete this post?"
            dialogTitleClass="modal-title"
            handleClose={handleCloseDeletePostModal}
          >
            <div className="delete-post__body">
              <div className="delete-post__body__button">
                <ButtonCustom
                  isSubmitting={deletePostModalState.apiLoading}
                  width={100}
                  height={35}
                  onClick={handleDeletePost}
                >
                  Submit
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
          <CustomDialog
            className="edit-topic"
            open={commentMenuState.editModalOpen}
            title="Edit comment"
            dialogTitleClass="modal-title"
            handleClose={() => setCommentMenuState(INITIAL_COMMENT_MENU_STATE)}
          >
            <div className="edit-topic__body">
              <CreateComment
                commentText={commentText}
                setCommentText={setCommentText}
                emojiContainer={emojiContainer}
                setEmojiContainer={setEmojiContainer}
                handleSubmitComment={handleSubmitComment}
                maxCommentLength={MAX_LENGTH_TOPIC_CREATE_COMMENT}
                edit={commentMenuState.editModalOpen}
                commentMenuState={commentMenuState}
                setCommentMenuState={setCommentMenuState}
                apiFetching={callingSendCommentAPI.isFetching()}
              />
            </div>
          </CustomDialog>
          <CustomDialog
            className="delete-post"
            open={commentMenuState.deleteModalOpen}
            title="Are you sure you want to delete your comment?"
            dialogTitleClass="modal-title"
            handleClose={() => setCommentMenuState(INITIAL_COMMENT_MENU_STATE)}
          >
            <div className="delete-post__body">
              <div className="delete-post__body__button">
                <ButtonCustom
                  isSubmitting={callingDeleteTopicPostComment.isFetching()}
                  width={100}
                  height={35}
                  onClick={handleDeleteTopicComment}
                >
                  Submit
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
        </div>
      </CardCustom>
    </TopicMiddleStyles>
  );
}

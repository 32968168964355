import React, { useEffect, useState } from "react";
import moment from "moment";
import { navigate } from "@reach/router";
import { Grid, Button } from "@material-ui/core";
import { filter, values, findWhere, find } from "underscore/modules";
import { ShimmerContentBlock } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroll-component";
import isNull from "underscore/modules/isNull";

import WebinarsStyles from "./WebinarsStyles";
import webinarIcon from "../../../assets/png/Others.png";
import CircleCheck from "../../../assets/svg/f-circle-check.svg";
import smallPersonIcon from "../../../assets/svg/smallPersonIcon.svg";
import Image from "../../../assets/svg/discover_no_people.svg";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../helper/helper";
import usePersistState from "../../../state/usePersistState";
import { useAppState } from "../../../context";
import { WEBINARS_TABS } from "../../../constant/mockdata";
import { config } from "../../../constant/config";
import { useQueryParams } from "../../../hooks/useQueryParams";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

import SearchFilter from "../../../components/Learn/Webinar/SearchFilter/SearchFilter";
import MyWebinar from "../../../components/Learn/Comman/MyWebinar/MyWebinar";
import WebinarList from "../../../components/Learn/Webinar/Webinar/WebinarList";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import CustomCalendarDemo from "../../../components/CustomCalendar/CustomCalendarDemo";
import BoostPopup from "../../../components/BoostDialog/BoostDialog";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import Container from "../../../components/Container/Container";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import {
  getWebinarsAPI,
  getPastWebinarsAPI,
  getUpcomingWebinarsAPI,
  getMyWebinarsAPI,
} from "../../../services/webinarServices";

const Webinars = (props) => {
  const query = useQueryParams();
  const { users = {} } = usePersistState();
  const isAdmin = users ? users.user_type === "school_admin" || "school" : null;
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { mode } = useAppState("modeManger");
  const { statusList, setStatusList } = useAppState("boostProfile");
  const {
    tab,
    webId,
    webinars,
    myWebinars,
    myCalendarEvents,
    setMyWebinars,
    setWebinars,
    showWebinarRightSide,
    switchTab,
  } = useAppState("learn");

  const [viewed, setIsViewed] = useState([]);
  const [totalTrueValues, settotalTrueValues] = useState(0);
  const [showBoostPopup, setShowBoostPopup] = useState(false);
  const [pastWebinars, setPastWebinars] = useState([]);
  const [upcomingWebinars, setUpcomingWebinars] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isMore, setIsMore] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const [loadingPastWebinars, setIsLoadingPastWebinars] = useState(false);
  const [showDialoug, setDialoug] = useState(false);
  const [wb_details, setWbDetails] = useState(null);

  const [callGetWebinarsAPI, refreshGetWebinarsAPI] =
    usePromise(getWebinarsAPI);
  const [callGetUpcomingWebinarsAPI, refreshGetUpcomingWebinarsAPI] =
    usePromise(getUpcomingWebinarsAPI);
  const [callGetPastWebinarsAPI, refreshGetPastWebinarsAPI] =
    usePromise(getPastWebinarsAPI);
  const [callingGetMyWebinarsAPI, refreshGetMyWebinarsAPI] =
    usePromise(getMyWebinarsAPI);

  const [, setDocumentTitle] = useDocumentTitle("");

  useEffect(() => {
    setDocumentTitle("Webinars Dashboard");
  });

  useEffect(() => {
    refreshGetMyWebinarsAPI();
  }, []);

  useEffect(() => {
    if (props.path === "/webinars/on-demand") {
      switchTab(WEBINARS_TABS.ONDEMAND);
    } else if (props.path === "/webinars/registered") {
      switchTab(WEBINARS_TABS.REGISTERED);
    } else {
      switchTab(WEBINARS_TABS.LIVE);
    }
  }, [props.path]);

  useEffect(() => {
    if (
      callingGetMyWebinarsAPI.hasFetched() &&
      callingGetMyWebinarsAPI.hasErrors() === false &&
      callingGetMyWebinarsAPI.data() &&
      callingGetMyWebinarsAPI.data().data
    ) {
      setMyWebinars(callingGetMyWebinarsAPI.data().data);

      if (
        callingGetMyWebinarsAPI.data().data &&
        callingGetMyWebinarsAPI.data().data.boost_profile &&
        callingGetMyWebinarsAPI.data().data.boost_profile.registered_webinar
      ) {
        setStatusList(callingGetMyWebinarsAPI.data().data.boost_profile);
        const response = filter(
          values(callingGetMyWebinarsAPI.data().data.boost_profile),
          function (data) {
            return data == true;
          }
        ).length;

        settotalTrueValues(response);
        setShowBoostPopup(true);
      }
    }
  }, [callingGetMyWebinarsAPI.isFetching()]);

  const closeFN = () => {
    setShowBoostPopup(false);
  };

  const handleEventClick = (info) => {
    let existWebinarDetails;
    if (info.event._def.extendedProps.type === "upcoming") {
      existWebinarDetails = findWhere(webinars.upcoming_webinars, {
        id: parseInt(info.event._def.publicId),
      });
    } else {
      existWebinarDetails = findWhere(webinars.past_webinars, {
        id: parseInt(info.event._def.publicId),
      });
    }
    setWbDetails(existWebinarDetails);
    setDialoug(true);
  };

  const closeModal = () => {
    setWbDetails(null);
    setDialoug(false);
  };

  const fetchPastWebinarsWithNotificationId = () => {
    if (query.has("notificationId")) {
      const notificationId = query.get("notificationId");
      if (viewed.includes(notificationId)) {
        return;
      }
      setIsViewed([...viewed, notificationId]);
      refreshGetPastWebinarsAPI(currentPage, config().WEBINARS_PER_PAGE, {
        webinar_id: notificationId,
      });
    }
  };
  useEffect(() => {
    setLoader(true);
    if (mode) {
      setIsMore(true);
      refreshGetWebinarsAPI();
      refreshGetUpcomingWebinarsAPI();
      if (query.has("notificationId")) return;
      refreshGetPastWebinarsAPI(currentPage);
      setIsLoadingPastWebinars(true);
    }
  }, [mode]);

  useEffect(() => {
    fetchPastWebinarsWithNotificationId();
  }, [query]);

  useEffect(() => {
    if (
      callGetPastWebinarsAPI.hasFetched() &&
      callGetPastWebinarsAPI.hasErrors() === false
    ) {
      setPastWebinars([
        ...pastWebinars,
        ...callGetPastWebinarsAPI.data().data.past_webinars,
      ]);

      setTotalPages(callGetPastWebinarsAPI.data().data.total_pages);
      setTimeout(() => {
        setLoader(false);
        setIsLoadingPastWebinars(false);
      }, 500);
    }
  }, [callGetPastWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callGetUpcomingWebinarsAPI.hasFetched() &&
      callGetUpcomingWebinarsAPI.hasErrors() === false
    ) {
      setUpcomingWebinars(callGetUpcomingWebinarsAPI.data().data);
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  }, [callGetUpcomingWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callGetWebinarsAPI.hasFetched() &&
      callGetWebinarsAPI.hasErrors() === false
    ) {
      setWebinars(callGetWebinarsAPI.data().data);
      setCategories(callGetWebinarsAPI.data().data.categories);
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  }, [callGetWebinarsAPI.isFetching()]);

  const getPastWebinarsPaginationData = () => {
    refreshGetPastWebinarsAPI(currentPage + 1, config().WEBINARS_PER_PAGE);
    setCurrentPage(currentPage + 1);
  };

  const setUpcomingAndPastWebinars = (UpcomingAndPastWebinars) => {
    setIsMore(false);
    setPastWebinars(UpcomingAndPastWebinars.past_webinars);
    setUpcomingWebinars(UpcomingAndPastWebinars.upcoming_webinars);
  };

  useEffect(() => {
    if (query.has("notificationId")) {
      const notificationId = parseInt(query.get("notificationId"));
      const webinar = pastWebinars.find((item) => item.id === notificationId);
      if (webinar) {
        switchTab(WEBINARS_TABS.ONDEMAND);
        navigate("/learn/webinars/on-demand");
      }
    }
  }, [pastWebinars]);

  useEffect(() => {
    if (query.has("notificationId")) {
      const notificationId = parseInt(query.get("notificationId"));
      const webinar = upcomingWebinars.find(
        (item) => item.id === notificationId
      );
      if (webinar) {
        switchTab(WEBINARS_TABS.LIVE);
        navigate("/learn/webinars/live");
      }
    }
  }, [upcomingWebinars, query]);
  const btn = {};
  let type =
    wb_details && wb_details !== null
      ? moment(wb_details.webinar_date).isBefore(moment().format("YYYY-MM-DD"))
        ? "past"
        : "upcoming"
      : null;

  if (type !== null) {
    if (type === "upcoming") {
      if (
        find(myWebinars, (o) => {
          return o.id === wb_details.id;
        })
      ) {
        btn.disabled = true;
        btn.text = "Registered";
        btn.className = "bg-gray";
        btn.showIcon = true;
      } else {
        btn.disabled = false;
        btn.text = "Register";
        btn.className = "";
        btn.showIcon = false;
      }
    } else {
      btn.disabled = false;
      btn.text = "View Recording";
      btn.className = "";
    }
  }
  return (
    <WebinarsStyles>
      <Container className="no-padding-container relative">
        <div className="webinar-section__header-heading">
          <h2 className="f-main-title mb-15 w-100">Explore our Webinars</h2>
          <div className="custom-tab">
            <Button
              name="my_topic"
              aria-label={"My Communities"}
              disableRipple={true}
              aria-selected={tab === WEBINARS_TABS.LIVE ? true : false}
              onClick={(e) => {
                e.preventDefault();
                switchTab(WEBINARS_TABS.LIVE);
                navigate("/learn/webinars/live");
              }}
              className={`flex-item ${
                tab === WEBINARS_TABS.LIVE ? "flex-item--selected active" : ""
              }`}
            >
              Live
            </Button>
            <Button
              name="discover_topic"
              onClick={(e) => {
                e.preventDefault();
                switchTab(WEBINARS_TABS.ONDEMAND);
                navigate("/learn/webinars/on-demand");
              }}
              className={`flex-item ${
                tab === WEBINARS_TABS.ONDEMAND
                  ? "flex-item--selected active"
                  : ""
              }`}
              aria-label={"Discover Communities"}
              disableRipple={true}
              aria-selected={tab === WEBINARS_TABS.ONDEMAND ? true : false}
            >
              On-demand
            </Button>
            <Button
              name="discover_topic"
              onClick={(e) => {
                e.preventDefault();
                switchTab(WEBINARS_TABS.REGISTERED);
                //chageViewMode(e)
                navigate("/learn/webinars/registered");
              }}
              className={`flex-item ${
                tab === WEBINARS_TABS.REGISTERED
                  ? "flex-item--selected active"
                  : ""
              }`}
              aria-label={"Discover Communities"}
              disableRipple={true}
              aria-selected={tab === WEBINARS_TABS.REGISTERED ? true : false}
            >
              Registered
            </Button>
          </div>
        </div>
        {tab !== WEBINARS_TABS.REGISTERED && (
          <SearchFilter
            categoryOptions={categories.map((rec) => ({
              value: rec.id,
              label: rec.category_name,
              selected: false,
            }))}
            setUpcomingAndPastWebinars={setUpcomingAndPastWebinars}
            setLoader={setLoader}
          />
        )}

        <div className="webinar-mainpage">
          <div className={`webinar-section ${!mode ? "cal-mode" : ""}`}>
            {tab !== WEBINARS_TABS.REGISTERED ? (
              <div>
                {showLoader ? (
                  <React.Fragment>
                    {Array.from(Array(5).keys()).map((_, index) => (
                      <div className="webinar-loading-item" key={index}>
                        <ShimmerContentBlock
                          title
                          text
                          thumbnailWidth={250}
                          thumbnailHeight={130}
                        />
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {tab === WEBINARS_TABS.LIVE && (
                      <WebinarList
                        id="upcoming-webinars"
                        title="Upcoming"
                        showBtn={true}
                        webinars={upcomingWebinars}
                        btnText="Register"
                        type="upcoming"
                        myWebinars={myWebinars}
                      />
                    )}

                    {loadingPastWebinars && tab === WEBINARS_TABS.ONDEMAND && (
                      <React.Fragment>
                        {Array.from(Array(5).keys()).map((_, index) => (
                          <div className="webinar-loading-item" key={index}>
                            <ShimmerContentBlock
                              title
                              text
                              thumbnailWidth={250}
                              thumbnailHeight={130}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                    {pastWebinars.length === 0 &&
                      !loadingPastWebinars &&
                      tab === WEBINARS_TABS.ONDEMAND && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <EmptyListWithImage
                              Image={Image}
                              title="Looks like there are no past webinars."
                              subTitle="Try expanding your search to discover other webinars!"
                              image
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    {pastWebinars && pastWebinars.length > 0 && (
                      <React.Fragment>
                        {accessibilityHelp == "true"
                          ? tab === WEBINARS_TABS.ONDEMAND && (
                              <div>
                                <WebinarList
                                  id="past-webinars"
                                  title={pastWebinars.length > 0 ? `Past` : ""}
                                  showBtn={false}
                                  webinars={pastWebinars}
                                  btnText="View Recording"
                                  type="past"
                                />
                                {currentPage != totalPages && isMore && (
                                  <center>
                                    {callGetPastWebinarsAPI.isFetching() ? (
                                      Array.from(Array(2).keys()).map(
                                        (_, index) => (
                                          <div
                                            className="webinar-loading-item"
                                            key={index}
                                          >
                                            <ShimmerContentBlock
                                              title
                                              text
                                              thumbnailWidth={250}
                                              thumbnailHeight={130}
                                            />
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <ButtonCustom
                                        onClick={getPastWebinarsPaginationData}
                                      >
                                        Load More
                                      </ButtonCustom>
                                    )}
                                  </center>
                                )}
                              </div>
                            )
                          : tab === WEBINARS_TABS.ONDEMAND && (
                              <InfiniteScroll
                                dataLength={pastWebinars.length}
                                next={getPastWebinarsPaginationData}
                                hasMore={currentPage != totalPages && isMore}
                                loader={
                                  <div style={{ margin: "20px 0 0 0" }}>
                                    {Array.from(Array(3).keys()).map(
                                      (_, index) => (
                                        <div
                                          className="webinar-loading-item"
                                          key={index}
                                        >
                                          <ShimmerContentBlock
                                            title
                                            text
                                            thumbnailWidth={250}
                                            thumbnailHeight={130}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                }
                                style={{ overflow: "none" }}
                              >
                                <WebinarList
                                  id="past-webinars"
                                  title={pastWebinars.length > 0 ? `Past` : ""}
                                  showBtn={false}
                                  webinars={pastWebinars}
                                  btnText="View Recording"
                                  type="past"
                                />
                              </InfiniteScroll>
                            )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            ) : myCalendarEvents.length === 0 ? (
              <Grid item xs={12}>
                <EmptyListWithImage
                  Image={Image}
                  title="Looks like you haven't registered for any live webinars."
                  subTitle="All your registered webinars will show here."
                  image
                />
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <MyWebinar
                    title="Webinars"
                    isBack={true}
                    openWebinar={true}
                    items={myWebinars}
                    showEmpty={false}
                  />
                </Grid>

                <Grid item xs={7}>
                  <CustomCalendarDemo
                    events={myCalendarEvents}
                    handleEventClick={handleEventClick}
                    selectedEvent={webId}
                  />
                </Grid>

                {(showDialoug || showWebinarRightSide) && (
                  <CustomDialog
                    open={showDialoug}
                    handleClose={() => closeModal(false)}
                    isDisplayCloseButton={true}
                    className={`webinar-dialog`}
                  >
                    <div className="webinar-dialog-content">
                      <div className="img-left">
                        {wb_details.webinar_image &&
                        wb_details.webinar_image.length > 0 ? (
                          <img
                            src={wb_details.webinar_image}
                            alt="Webinar-Image"
                          />
                        ) : (
                          <img
                            className="default-image"
                            src={webinarIcon}
                            alt="Webinar Image"
                          />
                        )}
                        {wb_details.webinar_category_name &&
                          wb_details.webinar_category_name !== "undefined" && (
                            <div className="webinarCategory">
                              {wb_details.webinar_category_name}
                            </div>
                          )}
                      </div>
                      <div className="header-primary">{wb_details.name} </div>
                      <div className="decsription">
                        {wb_details.description}
                      </div>
                      {wb_details.presenter &&
                        wb_details.presenter.length > 0 && (
                          <h5>
                            <img
                              src={smallPersonIcon}
                              alt="Small person icon"
                            />
                            {wb_details.presenter}
                          </h5>
                        )}
                      <h3 className="date">
                        {moment
                          .unix(wb_details?.epoch_time)
                          .local()
                          .format("dddd, MMMM Do, YYYY")}
                      </h3>
                      <h3 className="time">
                        {`${moment
                          .unix(wb_details?.epoch_time)
                          .local()
                          .format(
                            "hh:mm a"
                          )} - ${calculateLocalEndTimeFromEpoch(
                          wb_details?.epoch_time,
                          wb_details &&
                            wb_details.duration &&
                            !isNull(wb_details.duration)
                            ? wb_details.duration.split(" ")[0]
                            : 30,
                          "minutes"
                        )}`}{" "}
                        &nbsp; ({getLocalTzAbbr()})
                      </h3>
                      <div className="btn">
                        {type === "upcoming" &&
                          wb_details.register_link !== "" && (
                            <ButtonCustom
                              width="200"
                              height="50"
                              className={`up-btn ${btn.className}`}
                              onClick={() =>
                                window.open(wb_details.register_link)
                              }
                              isDisabled={
                                btn.disabled ||
                                (wb_details?.webinar_category_name ===
                                  "Admin Training" &&
                                  !isAdmin)
                              }
                            >
                              {btn.showIcon ? (
                                <div className="flex justify-between align-items-center">
                                  <span className="pt-3">{btn.text}</span>
                                  <img
                                    src={CircleCheck}
                                    className="button-image"
                                  />
                                </div>
                              ) : (
                                <div>{btn.text}</div>
                              )}
                            </ButtonCustom>
                          )}
                        {type === "past" &&
                          wb_details.recording_link !== "" && (
                            <ButtonCustom
                              width="200"
                              height="50"
                              className="past-btn"
                              onClick={() =>
                                window.open(wb_details.recording_link)
                              }
                            >
                              {btn.text}
                            </ButtonCustom>
                          )}
                      </div>
                    </div>
                  </CustomDialog>
                )}
              </Grid>
            )}
          </div>
        </div>
      </Container>

      {showBoostPopup && (
        <BoostPopup
          message={
            totalTrueValues == statusList.length
              ? "Your profile boost is complete"
              : "You've just registered for your first webinar."
          }
          sub_message={
            totalTrueValues == statusList.length
              ? "You're ready to gain the most from the Interstride platform."
              : "You're on the way to gaining the most from the Interstride platform."
          }
          totalSteps={statusList.length}
          totalTrueValues={parseInt(totalTrueValues)}
          closeFn={closeFN}
        />
      )}
    </WebinarsStyles>
  );
};

export default Webinars;

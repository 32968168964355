//--------------- Updated methods

import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

/**
 * Fetch Chats List
 * @returns
 */
export const fetchChatsList = async (options = {}) => {
  var link = `network_modules/list_messages`;
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  link = Object.keys(options).length > 0 ? `${link}?${queryString}` : link;

  const response = await axiosInstance.get(`${link}`, headers_with_token());
  return response.data;
};

/**
 * Fetch conversation
 * @param {*} conversationId
 * @returns
 */
export const fetchConversation = async (
  conversationId,
  options = {},
  userId
) => {
  let link = userId
    ? `network_modules/view_messages?user_id=${userId}`
    : `network_modules/view_messages?conversation_id=${conversationId}`;
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  link = Object.keys(options).length > 0 ? `${link}&${queryString}` : link;
  const response = await axiosInstance.get(link, headers_with_token());
  return response.data;
};

/**
 * Fetch users list
 * @returns
 */
export const fetchContactsList = async (options = {}) => {
  //country=&school=&degree=&major=&graduation_year=&interest=
  var link = "network_modules/discover_network";
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  link = Object.keys(options).length > 0 ? `${link}?${queryString}` : link;
  const response = await axiosInstance.get(`${link}`, headers_with_token());
  return response.data;
};

/**
 * Get all filters for dropdown
 * @returns
 */
export const fetchDiscoverNetworkFilters = async () => {
  const response = await axiosInstance.get(
    `network_modules/discover_search_list`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Create or Update group
 * @param {*} payload
 * @returns
 */
export const createOrUpdateGroup = async (payload) => {
  const response = await axiosInstance.post(
    `network_modules/send_message`,
    payload,
    headers_with_token()
  );
  return response.data;
};

/**
 * Remove conversation/chat
 * @param {*} conversationId
 * @returns
 */
export const deleteChat = async (conversationId) => {
  const response = await axiosInstance.get(
    `network_modules/leave_or_delete_chat?conversation_id=${conversationId}`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Mute or Unmute chat
 * @param {*} conversationId
 * @returns
 */
export const muteOrUnmuteChat = async (conversationId) => {
  const response = await axiosInstance.get(
    `network_modules/mute_chat?conversation_id=${conversationId}`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Report chat
 * @param {*} data
 * @returns
 */
export const reportChat = async (data) => {
  const response = await axiosInstance.post(
    `network_modules/report_chat`,
    data,
    headers_with_token()
  );
  return response.data;
};

/**
 * Search Conversation
 * @param {*} search
 * @returns
 */
export const searchConversation = async (search, page = 1) => {
  const response = await axiosInstance.get(
    `network_modules/search_messages?search=${search}&page=${page}`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Get total unread messages count
 * @returns
 */
export const fetchTotalUnreadMessagesCount = async () => {
  const response = await axiosInstance.get(
    "/network_modules/total_unread_messages",
    headers_with_token()
  );
  return response.data;
};

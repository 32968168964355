import React from "react";
import { Router } from "@reach/router";
import PrivacyPolicyDB from "./PrivacyPolicyDB/PrivacyPolicyDB";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Container from "../../components/Container/Container";

const PrivacyPolicy = () => {
  return (
    <MainLayout title="Privacy Policy" withSubHeader={false}>
      <Container>
        <Router>
          <PrivacyPolicyDB path="/" />
        </Router>
      </Container>
    </MainLayout>
  );
};

export default PrivacyPolicy;

import React, { useState } from "react";
import { Fab } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import CustomMenu from "../../components/CustomMenu/CustomMenu";
import { PRIMARY_DARK_COLOR } from "../../styles/variables";
import ExportDropdownStyles from "./ExportDropdownStyles";

import DownloadIcon from "../../assets/svg/f-download.svg";

const ExportDropdown = ({ options = [], onExport = () => {} }) => {
  const [open, setOpen] = useState(false);
  return (
    <ExportDropdownStyles>
      <div className="export-dropdown">
        <div id={`export-dropdown`}>
          <Fab
            id="export-menu"
            size="small"
            color="inherit"
            aria-label={`Export Menu`}
            onClick={() => setOpen(open ? false : "export-menu")}
            disableRipple={true}
            aria-expanded={open}
          >
            {open ? (
              <Close htmlColor={PRIMARY_DARK_COLOR} />
            ) : (
              <img src={DownloadIcon} alt="Export" />
            )}
          </Fab>

          <CustomMenu
            isopen={open}
            menuList={options}
            menuOnClick={({ id }) => {
              onExport(id);
              setOpen(false);
            }}
            align="top"
          />
        </div>
      </div>
    </ExportDropdownStyles>
  );
};

export default ExportDropdown;

import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .mobile-app-slide {
    background: #e4f2ff;
    .title {
      font-size: 25px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      font-family: "TTCommons-DemiBold";
      color: var(--primary-dark-color);
    }
    .sub-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 17px;
      font-weight: 400;
      line-height: 23px;
      text-align: center;
      max-width: 216px;
      margin: 6px 0 !important;
    }
    .app-image {
      position: absolute;
      left: 0;
      bottom: -50px;
      img {
        max-width: 100%;
      }
    }
    .app-buttons {
      text-align: right;
      display: flex;
      flex-direction: column;
      max-width: 132px;
      margin-left: auto;
      margin-top: 30px;
      a {
        cursor: pointer;
        + a {
          cursor: pointer;
          margin-top: 8px;
        }
      }
    }
  }
`;

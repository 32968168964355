import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .search-mainpage {
    .jobs-listing-loading-container{
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
    }
    .job-section {
      .cityjobguide-wrapper {
        margin-top:15px;
        .cityjobguide {
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: var(--card-box-shadow);
          text-align: center;
          padding: 20px 30px 30px;
          margin-bottom: 15px;
          .flag-icon {
            max-width: 38px;
            margin: 0 auto 10px;
            border-radius: 3px;
            background-color: #d8d8d8;
            display: flex;
            align-items: center;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              }
            }
          }
          h3 {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 21px;
            text-align: center;
            margin: 0 0 4px 0;
          }
          p {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            margin: 0px auto 20px;
          }
          button {
            font-size: 22px;
            max-width: 265px;
          }
        }
      }
      .subscription-model {
        .model-body {
          text-align: center;
        }
      }
    }
      .recommended-wrapper {
        .recommended-network-dialog {
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: var(--card-box-shadow);
          text-align: center;
          padding: 20px 20px 30px 20px;
          margin-bottom: 15px;
          .recommended-header{
            height: auto;
            align-items: center;
            margin-bottom:15px;
          }
          .recommended-card{
            @media(max-width:991px){
              max-width:280px;
              margin:0 auto;
            }
            align-items: center;
            + .recommended-card{
              margin-top: 15px;
            }
          }

         .main-title {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 25px;
            text-align: center;
          }
          .user-title {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
            text-align: left;
            margin-left: 10px;
            margin-bottom: 0px;
          }
          .user-sub-title {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            margin-top: auto;
            text-align: left;
            margin-left: 10px;
            line-height: 17px;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            .left-right-space-span{
              @media(max-width:600px){
                font-size: 0;
              }
            }
            @media(max-width:600px){
              flex-wrap:wrap;
            }
          }
          p {
            color: ${PRIMARY_COLOR};
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            margin: 3px auto 25px;
            max-width: 265px;
          }
          button {
            font-size: 22px;
            width: 100%
            max-width: 290px;
          }

        }
      }
  recommended-network-title{
    color: ${PRIMARY_COLOR};
      font-family: "TTCommons-Medium";
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      margin-top: auto;
      text-align: left;
      margin-left: 10px
  }
`;

import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
.slick-prev {
  left: 3px;
  z-index: 1;
  &:before{
    color: var(--light-gray-color);
    font-size: 20px;
  }
}
.slick-next {
  right: 3px;
  z-index: 1;
  &:before{
    color:var(--light-gray-color);
    font-size: 20px;
  }
}
.slick-slider{
    border-radius: 10px;
    background-color: #e4f2ff;
    // box-shadow: var(--card-box-shadow);
    padding-bottom: 10px;
    &.accessibility-slider{
      .slick-slide{
        visibility: hidden;
      }
      .slick-active,.slick-current{
        visibility: visible;
      }
      .slick-dots{
        li{
          button{
            :before {
              display:none;
            }
            &:focus{
              outline: 1px solid ${SECONDARY_COLOR};
              outline-offset: 2px;
            }
          }
        }
      }
    }
    .slick-dots {
      li {
        button {
          width: 8px;
          height: 8px;
          min-width:8px;
          background: var(--gray-outline-color);
          border-radius: 100px;
          margin: 5px 0 0 0;
          position: relative;
          top: 2px;
          :before {
            display:none;
          }
        }
        &.slick-active{
          button{
            background:var(--primary-dark-color);
          }
        }
      }
    }
    .slick-arrow{
      &.slick-prev {
        left: 10px;
      }
      &.slick-next {
        right: 20px;
      }
      &:before {
        font-size: 30px;
        color: ${SECONDARY_COLOR};
        opacity:1;
      }
      &:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100px;
        box-shadow: 0 0 8px 5px #000c3d !important;
        top: 1px;
        left: 3px;
        display:none;
      }
      &:focus{
        &:after{
          display:block;
        }
      }
    }
    .slick-slide{
     position: relative;
     text-align: center;
      > div {
        width: 100%;
        .card-wrapper{
          .slider-card{
            .card-content {
              width: 100%;
            }
          }
        }
      }
    }
  .slick-list{
    padding-bottom:35px;
    .slick-track{
      display:flex;
      align-items:center;
      flex-wrap: wrap;
    }
  }
  .slick-dots{
    bottom: 0;
    position: relative;
    margin-top: -28px;
    li{
      margin: 0 2px;
      width: auto;
      height: auto;
      button {
        padding: 0;
        width: 8px;
        height: 8px;
        &:before {
          width: 10px;
          height: 10px;
          line-height: 10px;
          font-size: 8px;
          color: #d3e0f6;
          opacity: 1;
        }
      }
      &.slick-active,
      &:hover{
        button{
          &:before{
            color: ${SECONDARY_COLOR} !important;
          }
        }
      }
    }
  }
}
  .slider-card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: none;
    border-radius: 0;
    background: transparent;

    .card-content {
        padding:10px;
        padding-bottom: 2px;
        @media(max-width:767px){
          padding-bottom: 2px;
        }
    }

    .top-image {
      margin: 0 0 12px 0;
      width: 46px;
      .MuiAvatar-root {
        width: 46px;
        height: 46px;
      }
      img{
        width: 100%;
      }
      &.gradute-image{
        width:77px;
      }
      &.visto-img{
        margin: 0 0 20px 0;
        width: 152px;
      }
    }

     .full-image {
      margin: -20px -20px -10px -20px;
      &.us-work-visa__rounded{
        img{
          border-radius: 5px;
        }
      }
      img {
        height: 100%;
        width: 100%;
      }
    }

    .title{
      max-width: 266px;
      margin: 0 auto;
      +.sub-title{
        margin-top:8px;
      }
      &.title-visto {
        color: ${PRIMARY_COLOR};
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin: 10px 0 28px 0;
    }
    }



    .sub-title {
      margin: 0px 0 8px 0;
      width: 100%;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      color: var(--primary-dark-color);
    }

    button {
      width: auto;
      margin: 8px auto 0;
      padding: 8px 22px 4px;
      height: auto;
      min-height: 39px;
      font-family: "TTCommons-Medium";
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      background: #00043C;
      .MuiButton-label{
        .content {
          min-width: auto;
        }
      }
    }

    .avatar-list{
      margin:15px 0 15px;
      max-width: 320px;
      ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        li{
          margin: 0 5px;
          .MuiAvatar-root{
            width: 40px;
            height: 40px;
          }
        }
      }
      + button {
        padding: 17px 35.5px 12px 36.87px;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        height: auto;
        border-radius: 45px;
        font-family: "TTCommons-Medium";
        background:var(--primary-main-color);
      }
      &.b-images{
        width: 100%;
        ul{
          justify-content: space-between;
          align-items: center;
          li{
            .MuiAvatar-root{
              width: auto;
              height: 35px;
              border-radius: 0;
              img {
                display: block;
                width: auto;
                height: auto;
                object-fit: initial !important;
                text-indent: initial;
              }
            }
          }
        }
      }
      &.resources-images{
        ul{
          align-items: center;
          li{
            margin:0 12.5px;
            .MuiAvatar-root{
              width: auto;
              height: auto;
              border-radius: 0;
              img {
                display: block;
                width: auto;
                height: auto;
                object-fit: initial !important;
                text-indent: initial;
              }
            }
          }
        }
      }
      &.graduate-img{
        margin: 15px 0 15px;
        ul{
          li{
            .MuiAvatar-root{
              width:77px;
              height:auto;
              border-radius:0;
            }
          }
        }
      }
    }

    .MuiCardContent-root{
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    .title {
      color:var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
    }
    &.accessibility-slider-card{
      .sub-title{
        max-width: 250px;
      }
    }
  }

  .appointments-wrapper {
    margin:16px 0;
    .header{
      display: flex;
      align-items: center;
      .title-primary{
        margin-right: 12px;
      }
    }
  }

  .webinars-wrapper{
    margin-top: 30px;
    .header{
      display: flex;
      align-items: center;
      margin-bottom: 15px !important;
      .title {
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: 20px;
        padding-top: 4px;
        margin: 0;
      }
      .actions {
        margin-left: auto;
      }
    }
  }


  .webinar-items{
    .webinar-item{
      .title{
        font-family: "TTCommons-Medium";
        color: ${PRIMARY_COLOR};
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
      .sub-title{
        margin-top: 10px;
        font-family: "TTCommons-Regular";
        color: ${PRIMARY_COLOR};
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 5px;
      }
      .time{
        color: var(--gray-text-color);
      }
    }
    +.webinar-items{
      margin-top:12px;
    }
  }

  .webinar-previews-loading-container{
    background: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .promote-previews-loading-container{
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    height : 300px;
    margin-bottom : 50px;
  }
}`;

import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Link, useNavigate } from "@reach/router";

import SaveSearchListStyles from "./SaveSearchListStyles";
import Saved from "../../../assets/svg/f-saved-search.svg";
import ArrowImg from "../../../assets/svg/DownArrow-01.svg";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import usePersistState from "../../../state/usePersistState";
import { toastify, processJobLocationTime } from "../../../helper/helper";
import { routes } from "../../../routes";

import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import CardCustom from "../../CardCustom/CardCustom";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import RemoveSearchModal from "../RemoveSearchModal/RemoveSearchModal";
import SavedPostsWidget from "../../SavedPostWidget/SavedPostWidget";
import {
  deleteSavedSearchAPI,
  getSavedSearchesAPI,
} from "../../../services/JobServices";

import editIcon from "../../../assets/svg/f-edit-icon.svg";
import deleteIcon from "../../../assets/svg/f-delete-icon.svg";

const EmptySaveSearch = () => {
  return (
    <Grid item xs={12}>
      <CardCustom className="empty-search-box">
        <div className="empty-item">
          <h3 className="header-primary">
            Looks like you have no job alerts here.
          </h3>
          <p>
            Create a new search and click Save Search in the top right corner.
            Once you save a search you can choose how often you want to be
            notified.
          </p>
        </div>
      </CardCustom>
    </Grid>
  );
};

const SaveSearchList = ({
  isWidget = false,
  showCount = false,
  backPage,
  refreshSearches = true,
}) => {
  const { setJobsFilter } = useAppState("job");
  const { jobsCountryList } = usePersistState();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const [searchList, setSearchList] = useState([]);
  const [show, setIsShow] = useState(false);
  const [selectedSearchItem, setSelectedSearchItem] = useState(null);
  const navigate = useNavigate();

  const [callingDeleteSavedSearchAPI, refreshDeleteSavedSearchAPI] =
    usePromise(deleteSavedSearchAPI);
  const [callingGetSavedSearchesAPI, refreshGetSavedSearchesAPI] =
    usePromise(getSavedSearchesAPI);

  useEffect(() => {
    refreshGetSavedSearchesAPI();
  }, [refreshSearches]);

  useEffect(() => {
    if (
      callingGetSavedSearchesAPI.hasFetched() &&
      callingGetSavedSearchesAPI.hasErrors() === false &&
      callingGetSavedSearchesAPI.data() &&
      callingGetSavedSearchesAPI.data().data &&
      callingGetSavedSearchesAPI.data().data.saved_jobs
    ) {
      setSearchList(callingGetSavedSearchesAPI.data().data.saved_jobs);
    }
  }, [callingGetSavedSearchesAPI.isFetching()]);

  useEffect(() => {
    if (
      callingDeleteSavedSearchAPI.hasFetched() &&
      callingDeleteSavedSearchAPI.hasErrors() === false &&
      callingDeleteSavedSearchAPI.data() &&
      callingDeleteSavedSearchAPI.data().message
    ) {
      toastify("success", callingDeleteSavedSearchAPI.data().message);
      setSelectedSearchItem(null);
      setIsShow(false);
      refreshGetSavedSearchesAPI();
    }
  }, [callingDeleteSavedSearchAPI.isFetching()]);

  const onSearchAgainClick = (searchData) => {
    setJobsFilter({
      ...searchData,
    });
    navigate("/jobs/search");
  };

  const handleClose = () => {
    setIsShow(false);
  };
  const deleteSavedSearch = () => {
    if (!selectedSearchItem) return;
    refreshDeleteSavedSearchAPI(selectedSearchItem);
  };

  const handleDeleteOperation = (item) => {
    setIsShow(true);
    setSelectedSearchItem(item?.id);
  };

  const handleEditOperation = (data) => {
    navigate(`/jobs/saved-searches?id=${data?.id}`);
  };
  const renderSaveSearchList = (data, i) => {
    const { title = "", state, city, created_at } = data;
    const country =
      data.country !== null
        ? jobsCountryList.find((rec) => rec.value === data.country)
        : "";

    return (
      <React.Fragment>
        <RemoveSearchModal
          showDeleteSearchPopup={show}
          handleClose={handleClose}
          deleteSavedSearch={deleteSavedSearch}
        />
        <Grid item xs={12} key={i}>
          <CardCustom className="search-list-card">
            <div className="search-item">
              <div className="content">
                <div className="header-primary">{title}</div>
                <div className="text-grey">
                  {processJobLocationTime({
                    city,
                    state,
                    country:
                      country?.label === "United States" ||
                      country?.label === "USA"
                        ? "US"
                        : country?.label,
                    time: created_at,
                  })}
                </div>
              </div>
              <div
                className={`card-actions-wrapper ${
                  accessibilityHelp == "true" ? "show-actions" : ""
                }`}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditOperation(data);
                  }}
                  className="apply-btn search-again-button delete--btn"
                >
                  <img src={editIcon} alt="edit" />
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteOperation(data);
                  }}
                  className="apply-btn search-again-button delete--btn"
                >
                  <img src={deleteIcon} alt="Delete" />
                </a>
                <div className="apply-btn search-again-button">
                  <ButtonCustom
                    width="125"
                    height="40"
                    onClick={() => onSearchAgainClick(data)}
                    className={"bg-light-blue"}
                  >
                    View results
                  </ButtonCustom>
                </div>
              </div>
            </div>
          </CardCustom>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {isWidget ? (
        <SavedPostsWidget
          widgetText="Saved searches"
          count={showCount ? searchList.length : null}
          onClick={(e) => {
            e.preventDefault();
            if (searchList.length > 0) {
              navigate(
                `${routes.JOBS.ALERT}${backPage ? `?from=${backPage}` : ``}`
              );
            }
          }}
          customClassName="mt-15"
          icon={Saved}
          hideIfEmpty={
            !!(backPage === "jobs" || callingGetSavedSearchesAPI.isFetching())
          }
          emptyTitle="Save your searches"
          emptySubTitle={`You can click "Save Search" at the bottom of the search in Search Jobs.`}
        />
      ) : (
        <SaveSearchListStyles arrowImg={ArrowImg}>
          <div className="search-list">
            <div className="title-boxs mb-15">
              <h2
                className="title-primary mb-0"
                id={"saved-searches-section-title"}
              >
                Saved searches
              </h2>
              {searchList && searchList.length > 0 && (
                <Link
                  to="/jobs/saved-searches"
                  className="nlink-secondary view-all link-focus"
                  aria-describedby={`saved-searches-section-title`}
                  aria-label={"View all saved searches"}
                >
                  Manage{accessibilityHelp == "true" ? " saved searches" : ""} (
                  {searchList.length})
                </Link>
              )}
            </div>

            {callingGetSavedSearchesAPI.isFetching() ? (
              <JobsListingEffect count={3} />
            ) : (
              <Grid container spacing={3}>
                {searchList && searchList.length > 0 ? (
                  searchList
                    .slice(0, 3)
                    .map((data, i) => renderSaveSearchList(data, i))
                ) : (
                  <EmptySaveSearch />
                )}
              </Grid>
            )}
          </div>
        </SaveSearchListStyles>
      )}
    </React.Fragment>
  );
};
export default SaveSearchList;

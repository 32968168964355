import React, { useEffect } from "react";
import { useLocation, navigate } from "@reach/router";
import { routes } from "../../routes";
import { ambassadorAcceptInviteAPI } from "../../services/authenticationServices";
import { INTERSTRIDE_DEFAULT_LOGO, toastify } from "../../helper/helper";
import usePromise from "../../hooks/usePromise/usePromise";

const AmbassadorAcceptInvite = () => {
  let query = new URLSearchParams(useLocation().search);
  const token = query.get("ambassador_accept_token");

  const [callAmbassadorAcceptInviteAPI, refreshAmbassadorAcceptInviteAPI] =
    usePromise(ambassadorAcceptInviteAPI);

  useEffect(() => {
    if (token) refreshAmbassadorAcceptInviteAPI(token);
    else navigate(routes.LOGIN);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callAmbassadorAcceptInviteAPI.hasFetched() &&
      callAmbassadorAcceptInviteAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        "Thanks for accepting the invitation. You may log in now. "
      );
      navigate(`${routes.LOGIN}?ambassador_invite=true`);
    } else if (
      callAmbassadorAcceptInviteAPI.hasFetched() &&
      callAmbassadorAcceptInviteAPI.hasErrors()
    ) {
      toastify(
        "error",
        "Unable to accept invitation. Please contact us at contact@interstride.com"
      );
      navigate(routes.LOGIN);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAmbassadorAcceptInviteAPI]);

  return (
    <div className="page-loader">
      <div className="">
        <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default AmbassadorAcceptInvite;

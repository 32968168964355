import CustomDialog from "../../CustomDialog/CustomDialog";
import styled from "styled-components";
import { ACTIVE_BTN_HOVER_BLUE_COLOR, PRIMARY_COLOR } from "../../../styles/variables";

export const FirstLoginStyles = styled.div.attrs({ className: "" })`
  .form-label{
    justify-content: center;
  }
  .secondary-email-label-wrapper {
    display: flex;
    > div {
      margin-right: 6px;
    }
    > div:first-child {
      padding-left: 10px;
    }
    span {
      font-size: 15px;
      color: ${PRIMARY_COLOR};
    }
  }
  .YourBG {
    position: relative;
    > div {
      width: 100%;
    }
    .edit-link {
      font-size: 18px;
      font-family: "TTCommons-Regular";
      color: var(--secondary-color);
      border-bottom: 1px solid var(--secondary-color);
      line-height: 19px;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      z-index: 0;
      &.no-underline {
        border-bottom: none;
      }
    }
  }
  .widgets-item > button {
    width: 100%;
  }
  .textbox-with-icon {
    position: relative;
    > img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      opacity: 1;
      &.blur-icon {
        opacity: 0.5;
      }
    }
    > div {
      margin: 0 0 10px;
    }
    .MuiFormControl-root {
      .MuiInputBase-root {
        input.MuiInputBase-input {
          padding: 21px 27.31px 17.3px 60px;
        }
      }
    }
  }
  .info-card {
    overflow: inherit;
    .card-content {
      padding: 30px;
      padding-bottom: 0;

      .profile-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;

        .profile-img {
          height: 110px;
          width: 110px;
          border-radius: 50%;
        }
        .profile-upload-link {
          margin-top: 10px;
          .upload-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 16px;
              width: 16px;
              margin-right: 10px;
              margin-bottom: 0px;
            }
            .btn-upload {
              font-size: 18px;
              font-family: "TTCommons-Regular";
              color: var(--secondary-color);
              text-decoration: none;
              border-bottom: 1px solid var(--secondary-color);
              line-height: 19px;
            }
          }
        }
      }
      .profile-info-content {
        margin-top: 36px;
      }
    }
  }
  .sso-para {
    display: flex;
    justify-content: space-between;
  }
  .sso-para > p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    color: ${PRIMARY_COLOR};
    cursor: alias;
  }
  .linkedIn-btn:focus {
    box-shadow: none !important;
    div:first-child {
      background: ${ACTIVE_BTN_HOVER_BLUE_COLOR};
      opacity: 0.9;
      box-shadow: 0 0 0px 3px ${PRIMARY_COLOR}, inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
    }
  }
`;

export const SsoDiv = styled.div`
  align-items: center;
  display: flex;
  border-radius: 32px;
  border: 2px solid #0030b5;
  height: 58px;
  color: #ffffff;
  font-size: 20px;
  justify-content: start;
  background-color: #0030b5;
  margin-top: 5px;
  margin-bottom: 30px;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  & > img {
    margin: 0 4% 0 3%;
    color: blue;
  }
  .linkedin-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-right: 20px;
  }
`;

export const CustomDialogBox = styled(CustomDialog)`
  .checkbox-wrap {
    margin: 20px 0;
  }
  .form-label {
    margin: 20px 0 8px 0 !important;
    justify-content: center;
  }
  .form-control {
    input {
      text-align: center !important;
    }
    min-width: 332px;
  }
`;

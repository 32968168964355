import { useReducer, useEffect } from "react";
import { get, filter } from "lodash";

import {
  communitiesFilterOptions,
  communitiesTabOptions,
} from "../utils/common";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  tab: communitiesTabOptions[0],
  activeTopicId: null,
  activeTopic: {},
  activeFilterTopics: communitiesFilterOptions[0],
  followedTopics: [],
  suggestedTopics: [],
  topicPosts: {},
  loadingTopicPosts: false,
  topicPendingRequest: [],
  viewTopic: null,
  newTopicDetail: {
    topicName: "",
    description: "",
    file: "",
    topicProfile: "",
    department: { value: "all", label: "All Departments" }, // {}
    topicType: true, // true - public , false - private
    selectedTagsForTopic: [],
  },
};

const useTopicV2 = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  // Logger
  useEffect(() => {
    console.log("useTopicV2: ", state);
  }, [state]);

  const resetStateTopics = () => {
    setState({
      ...initialArgs,
    });
  };

  const changeTab = (tab = communitiesTabOptions[0]) => {
    setState({
      tab,
    });
  };

  const setActiveFilterTopics = (
    activeFilterTopics = communitiesFilterOptions[0]
  ) => {
    setState({
      activeFilterTopics,
      activeTopicId: null,
      activeTopic: {},
    });
  };

  const setActiveTopicId = (activeTopicId = null) => {
    setState({
      activeTopicId,
      topicPosts: {}, // Resets topic posts for new data
    });
  };

  // type options: topic, feed, all-saved, topic-saved
  const setTopicPosts = (topicPosts = {}, type = null) => {
    setState({
      loadingTopicPosts: false,
      ...(type === "feed" || type === "all-saved"
        ? { activeTopic: {}, activeTopicId: null }
        : {}),
      topicPosts: {
        ...topicPosts,
        type,
        posts:
          topicPosts?.current_page === 1
            ? topicPosts?.posts
            : [
                ...(state?.topicPosts?.posts ? state?.topicPosts?.posts : {}),
                ...(topicPosts?.posts ? topicPosts?.posts : {}),
              ],
      },
    });
  };

  const setLoadingTopicPosts = (loadingTopicPosts = false) => {
    setState({
      loadingTopicPosts,
    });
  };

  const setFollowingAndSuggestedTopics = (
    followedTopics = [],
    suggestedTopics = []
  ) => {
    setState({
      followedTopics,
      suggestedTopics,
    });
  };

  const updateTopicFavouriteStatus = (topicId = null) => {
    // Find topic index
    const topic_index = state.followedTopics.findIndex(
      (topic) => topic.id === topicId
    );

    if (topic_index !== -1) {
      let _followedTopics = [...state?.followedTopics];
      _followedTopics[topic_index].favourite =
        !_followedTopics[topic_index].favourite;
      setState({
        followedTopics: _followedTopics,
      });
    }
  };

  const setTopicPendingRequest = (data = {}) => {
    setState({
      topicPendingRequest: data?.topic_followers || [],
    });
  };

  const managePendingRequest = (data) => {
    const topic_follower_obj = get(data, "topic_follower", null);
    if (topic_follower_obj?.id) {
      setState({
        topicPendingRequest: filter(state.topicPendingRequest, (o) => {
          return o.id !== topic_follower_obj.id;
        }),
      });
    }
  };

  // Inserts reactions data into topic posts object
  const updateReactionsData = (reactionsData = []) => {
    let _topicPosts = state.topicPosts;

    reactionsData.forEach((reaction) => {
      const indexOfPost = _topicPosts.posts.findIndex(
        (post) => post.id === reaction.id
      );
      if (indexOfPost !== -1) {
        _topicPosts.posts[indexOfPost].details = reaction;
      }
    });

    setState({
      topicPosts: _topicPosts,
    });
  };

  const incrementCommentCount = (topic_post_id = null) => {
    let _topicPosts = state.topicPosts;

    const index = _topicPosts.posts.findIndex(
      (topic) => topic.id === topic_post_id
    );
    if (index !== -1) _topicPosts.posts[index].details.comment_counts += 1;

    setState({
      topicPosts: _topicPosts,
    });
  };

  const decrementCommentCount = (topic_post_id = null) => {
    let _topicPosts = state.topicPosts;

    const index = _topicPosts.posts.findIndex(
      (topic) => topic.id === topic_post_id
    );
    if (index !== -1) _topicPosts.posts[index].details.comment_counts -= 1;

    setState({
      topicPosts: _topicPosts,
    });
  };

  const updateTopicPostReactions = (reactionData) => {
    let _topicPosts = state.topicPosts;

    const index = _topicPosts.posts.findIndex(
      (topic) => topic.id === reactionData?.topic_post_id
    );

    if (index !== -1) {
      if (reactionData?.total_count === 0) {
        delete _topicPosts.posts[index].details.emojies[
          reactionData?.emoji_unicode
        ];
      } else {
        _topicPosts.posts[index].details.emojies[reactionData?.emoji_unicode] =
          {
            count: reactionData?.total_count,
            emoji: reactionData?.emoji,
            emoji_symbol: reactionData?.emoji_symbol,
          };
      }
    }

    setState({
      topicPosts: _topicPosts,
    });
  };

  const updateSavedPostStatus = (topic_post_id = null) => {
    let _topicPosts = state.topicPosts;

    const index = _topicPosts.posts.findIndex(
      (topic) => topic.id === topic_post_id
    );

    if (index !== -1) {
      if (_topicPosts.posts[index].saved) {
        _topicPosts.saved_posts -= 1;
      } else {
        _topicPosts.saved_posts += 1;
      }
      _topicPosts.posts[index].saved = !_topicPosts.posts[index].saved;
    }

    setState({
      topicPosts: _topicPosts,
    });
  };

  const toggleActiveTopicMuteStatus = () => {
    const _followedTopics = [...state?.followedTopics];

    const index = _followedTopics.findIndex(
      (topic) => topic.id === state.activeTopic.id
    );
    if (index !== -1) {
      _followedTopics[index].mute_topic = !_followedTopics[index].mute_topic;
    }

    setState({
      activeTopic: {
        ...state?.activeTopic,
        mute_topic: !state?.activeTopic?.mute_topic,
      },
      followedTopics: _followedTopics,
    });
  };

  const setPreApiCallSavedTopic = (type = null) => {
    setState({
      loadingTopicPosts: true,
      topicPosts: { ...(type ? { type } : {}) },
    });
  };

  const navigateSavedToFeedHandler = () => {
    setState({
      loadingTopicPosts: true,
      topicPosts: {
        type: "feed",
      },
    });
  };

  const navigateSavedToFollowing = () => {
    setState({
      loadingTopicPosts: true,
      topicPosts: {
        type: "topics",
      },
    });
  };

  const postViewSuggestedTopic = (topic = {}) => {
    setState({
      tab: communitiesTabOptions[1],
      viewTopic: topic,
    });
  };

  const setNewTopicDetail = (obj) => {
    setState({
      newTopicDetail: {
        ...state.newTopicDetail,
        topicName: get(obj, "topicName", ""),
        description: get(obj, "description", ""),
        file: get(obj, "file", ""),
        topicProfile: get(obj, "topicProfile", ""),
        department: get(obj, "department", {}),
        topicType: get(obj, "topicType", true),
        selectedTagsForTopic: get(obj, "selectedTagsForTopic", []),
      },
    });
  };

  const handleCommunitySubHeaderClick = () => {
    // Reset state to show topic feed
    setState({
      activeFilterTopics: "all",
      activeTopic: {},
      activeTopicId: null,
      tab: communitiesTabOptions[0],
      viewTopic: null,
      ...(state.tab === communitiesTabOptions[1] ? { topicPosts: {} } : {}),
    });
  };

  const handlePostTopicCloseUnfollow = (topic_id = null) => {
    // Reset state to show topic feed
    // Remove closed or unfollowed topic from following Communities list
    setState({
      activeFilterTopics: "all",
      activeTopic: {},
      activeTopicId: null,
      tab: communitiesTabOptions[0],
      viewTopic: null,
      followedTopics: state.followedTopics?.filter(
        (topic) => topic.id !== topic_id
      ),
    });
  };

  // App state level side effects

  useEffect(() => {
    if (state.activeTopicId === null || state.followedTopics?.length === 0) {
      setState({
        activeTopic: {},
      });
    } else if (state.activeTopicId && state.followedTopics?.length > 0) {
      setState({
        activeTopic: state.followedTopics.filter(
          (topic) => topic.id === state.activeTopicId
        )[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeTopicId, state?.followedTopics]);

  return {
    ...state,
    resetStateTopics,
    changeTab,
    setActiveFilterTopics,
    setActiveTopicId,
    setTopicPosts,
    setLoadingTopicPosts,
    setFollowingAndSuggestedTopics,
    updateTopicFavouriteStatus,
    setTopicPendingRequest,
    managePendingRequest,
    updateReactionsData,
    incrementCommentCount,
    decrementCommentCount,
    updateTopicPostReactions,
    updateSavedPostStatus,
    toggleActiveTopicMuteStatus,
    setPreApiCallSavedTopic,
    navigateSavedToFeedHandler,
    navigateSavedToFollowing,
    postViewSuggestedTopic,
    setNewTopicDetail,
    handleCommunitySubHeaderClick,
    handlePostTopicCloseUnfollow,
  };
};

export default useTopicV2;

import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import _filter from "lodash/filter";

import FilterIcon from "../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../assets/svg/filled-filter-Icon.svg";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import GoogleMapCustom from "../../../components/GoogleMapCustom/GoogleMapCustom";
import EmptyMiddleBox from "../../../components/EmptyModdleBox/EmptyMiddleBox";

const CountryInsight = ({ countries = [], handleCountryClick = () => {} }) => {
  const [openFilter, toggleFilter] = useState(false);
  const [filterResult, setFilterResult] = useState([]);

  useEffect(() => {
    setFilterResult([...countries]);
  }, [countries]);

  return (
    <div className={`search-filter`}>
      <div className="country-heading">
        <div className="serach-country">
          <div className="form-field-group">
            <TextboxCustom
              name="search"
              placeholder={"Search country"}
              lableaignment="left"
              onChange={(e) => {
                let data = _filter(countries, function (o) {
                  return o.name.match(new RegExp("^" + e.target.value, "i"));
                });
                setFilterResult(data);
              }}
            />
          </div>
        </div>
      </div>
      <div className="country-content-wrapper">
        <Grid container spacing={3}>
          <Grid item md={6}>
            <div
              className={`search-filter collapsible-view ${
                openFilter ? "open" : ""
              }`}
            >
              <div className="country_listing">
                <h2 className="title-primary mb-15">
                  Countries
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleFilter(!openFilter);
                    }}
                    className="collapse-icon"
                  >
                    {openFilter ? (
                      <img src={FilledFilterIcon} alt="Filter Icon" />
                    ) : (
                      <img src={FilterIcon} alt="Filter Icon" />
                    )}
                  </a>
                </h2>

                <ul className={`search-card ${openFilter ? "mt-5" : ""}`}>
                  {filterResult.length > 0 ? (
                    <>
                      {filterResult.map((country) => (
                        <li key={country.name}>
                          <a
                            href="#"
                            onClick={(e) => handleCountryClick(e, country)}
                          >
                            {country.name}
                          </a>
                        </li>
                      ))}
                    </>
                  ) : (
                    <EmptyMiddleBox title="There aren’t any countries with this name. Please try again." />
                  )}
                </ul>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="mapcontent-wrapper">
              <h2 className="title-primary mb-15">World map</h2>
              <div className="map-wrapper">
                <GoogleMapCustom zoom={2} wordMap={true} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CountryInsight;

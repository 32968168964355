import React from "react";
import CardCustomStyles from "./CardCustomStyles";
import { Card, CardHeader, CardContent } from "@material-ui/core";

const CardBody = ({
  action,
  title,
  className,
  noTabIndex,
  avatar,
  subheader,
  onHeaderClick,
  props,
  cardContentStyles,
}) => {
  return (
    <Card className={className} {...(noTabIndex ? { tabIndex: 0 } : {})}>
      {title && (
        <CardHeader
          avatar={avatar}
          title={title}
          subheader={subheader}
          action={action}
          onClick={onHeaderClick}
          className="cursor-pointer"
        />
      )}
      {props && (
        <CardContent style={cardContentStyles} className="card-content">
          {props}
        </CardContent>
      )}
    </Card>
  );
};

const CardCustom = ({
  children,
  action,
  title,
  subheader,
  avatar,
  className,
  borderRadius = 10,
  noTabIndex = false,
  onClick = null,
  cardContentStyles = {},
  ariaHidden = false,
  onHeaderClick = () => {},
}) => {
  return (
    <CardCustomStyles borderRadius={borderRadius} className="card-wrapper">
      {onClick /* || accessibilityHelp == "false" */ ? (
        <a
          className="no-underline card-accessibility-focus"
          href
          onClick={(e) => {
            e.preventDefault();
            if (onClick) {
              onClick(e);
            }
          }}
          aria-hidden={ariaHidden}
          {...(ariaHidden ? { tabIndex: -1 } : {})}
        >
          <CardBody
            action={action}
            title={title}
            subheader={subheader}
            avatar={avatar}
            className={className}
            noTabIndex={noTabIndex}
            cardContentStyles={cardContentStyles}
            onHeaderClick={onHeaderClick}
            props={children}
          />
        </a>
      ) : (
        <div>
          <CardBody
            action={action}
            title={title}
            subheader={subheader}
            avatar={avatar}
            className={className}
            noTabIndex={noTabIndex}
            cardContentStyles={cardContentStyles}
            onHeaderClick={onHeaderClick}
            props={children}
          />
        </div>
      )}
    </CardCustomStyles>
  );
};

export default CardCustom;

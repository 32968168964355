import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { updateFavouriteServiceAPI } from "../../../../services/learnServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { toastify } from "../../../../helper/helper";
import { createModuleStyleExtractor } from "../../../../utils/css";
import ServiceCard from "../../Service/ServiceCard/ServiceCard";
import styles from './SavedServices.module.scss'

import IllustrationNoUser from '../../../../assets/svg/Illustration-no-user.svg'

const cx = createModuleStyleExtractor(styles)

export default function SavedServices ({
  savedServiceList,
  refreshSavedServices,
  showLoader = false,
}) {
  const [callingUpdateFavouriteServiceAPI, refreshUpdateFavouriteServiceAPI] = usePromise(updateFavouriteServiceAPI);

  useEffect(() => {
    if (
      callingUpdateFavouriteServiceAPI.hasFetched() &&
      callingUpdateFavouriteServiceAPI.hasErrors() === false
    ) {
      refreshSavedServices();
      if (
        callingUpdateFavouriteServiceAPI.data() &&
        callingUpdateFavouriteServiceAPI.data().message
      )
        toastify("success", callingUpdateFavouriteServiceAPI.data().message);
    }
  }, [callingUpdateFavouriteServiceAPI.isFetching()]);

  const handleFavoriteClick = ( payload ) => {
    refreshUpdateFavouriteServiceAPI(payload)
  }

  return (
    <div className={cx("saved-services")}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={cx("saved-services__content")}>
            {
              showLoader ?
              <div className={cx("saved-services__content__loader")}>
                {[...Array(4)].map((_, index) =>
                  <ShimmerThumbnail width={267} height={345} key={index}/>
                )}
              </div>:
              savedServiceList && savedServiceList.length > 0 ?
              <div className={cx("saved-services__content__item")}>
                {
                  savedServiceList.map((data, index) =>
                    <ServiceCard
                      key={index}
                      data={data.deal || {}}
                      isFavourite={true}
                      onClickStar={handleFavoriteClick}
                      label
                    />)
                }
              </div>:
              <div className={cx("saved-services__content__placeholder")}>
                <img src={IllustrationNoUser} alt="no-services"/>
                <h2>Looks like there are no saved services</h2>
              </div>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import React, { memo, useEffect, useState } from "react";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import ClearIcon from "@material-ui/icons/Clear";

import { Input } from "../../../atoms/Input/Input";
import SearchMessageBoxStyles from "./SearchMessageBoxStyles";
import { useSearchDebounce } from "../../../../hooks/useSearchMessage";
import { renderTimestamp } from "../../../../helper/helper";
import { useAppState } from "../../../../context";
import { fetchConversation } from "../../../../services/chat.service";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";

const SearchMessageBox = ({ isComponentVisible, setIsComponentVisible }) => {
  const [page, setCurrentPage] = useState(1);
  const [cache, setCache] = useState({});

  const {
    getConversationByConversationID,
    setCurrentSearchMessageItem,
    activeConversation,
    setCurrentConversationId,
    currentSearchMessageItem,
    setConversationInformation,
  } = useAppState("chat");

  const currentConversation =
    getConversationByConversationID(activeConversation);

  const {
    searchQuery,
    setSearchQuery,
    isLoading,
    searchResults,
    loaded,
    searchedConversations,
  } = useSearchDebounce({
    delay: 350,
    current_page: page,
  });

  const _getDeliveryTime = (deliveryTime) => {
    if (cache[deliveryTime]) {
      return cache[deliveryTime];
    }
    const response = deliveryTime ? renderTimestamp(deliveryTime) : "";
    setCache({
      ...cache,
      [deliveryTime]: response,
    });
    return response;
  };

  useEffect(() => {
    if (currentSearchMessageItem?.conversation_id === activeConversation) {
      if (currentConversation) {
        const messageItem = currentConversation?.messages.find(
          (message) => message.id == currentSearchMessageItem.id
        );
        if (messageItem) {
          //ready to move scroller
          const element = document.getElementById(
            `single_chat_item_id_${messageItem.id}`
          );
          if (element) {
            element.scrollIntoView({ behavior: "auto" });
            setTimeout(() => {
              // setCurrentSearchMessageItem(null);
            }, 3000);
          }
        }
      }
    }
  }, [currentSearchMessageItem, activeConversation, currentConversation]);

  const doFetchConversationMessages = async (item) => {
    const { conversation_id, id } = item;
    try {
      const response = await fetchConversation(conversation_id, {
        id_gt_inclusive: id,
      });
      const { data } = response || {};
      if (data) {
        setCurrentSearchMessageItem(item);
        setConversationInformation(conversation_id, data);
        if (activeConversation !== conversation_id) {
          setCurrentConversationId(conversation_id);
        }

        onClearSearch();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onItemClicked = (item) => {
    const { conversation_id, id } = item;

    setCurrentSearchMessageItem(null);

    const conversation = getConversationByConversationID(conversation_id);

    //check if conversation already exists
    if (conversation) {
      // if message already exists
      const { messages } = conversation || {};

      if (!messages) {
        // call API
        doFetchConversationMessages(item);
        return;
      }

      const _message = messages.find((message) => message.id === id);
      if (!_message) {
        //call API
        doFetchConversationMessages(item);
        return;
      }
      /**
       * if id_gt then return all messages from latest to the id_gt
       * update the pagination based on total messages and messages per page
       * for example if per page limit is 20 messages and total messages are 100
       * with id_gt it returns  80 messages
       * it should say that current page = 1 next_page = 2
       */
      if (activeConversation !== conversation_id) {
        setCurrentConversationId(conversation_id);
      }
      setCurrentSearchMessageItem(item); // added inside state
      // set conversation Id if current and this not equal
      // scroll to that specific message
      onClearSearch();
      return;
    }
    // otherwise have to call API.
    doFetchConversationMessages(item);
    onClearSearch();
  };

  const onClickLoadMore = (page) => {
    setCurrentPage(page);
  };

  const onClearSearch = () => {
    setSearchQuery("");
    setCurrentPage(1);
  };

  const isDropdownOpen =
    (!isLoading || page > 1) &&
    searchResults &&
    (searchResults?.messages?.length > 0 || searchedConversations?.length > 0);

  useEffect(() => {
    setIsComponentVisible(isDropdownOpen);
  }, [isDropdownOpen]);

  return (
    <SearchMessageBoxStyles>
      <div className="chat-search-box-container">
        <div className="chat-search-box-container__input">
          <Input
            type="text"
            name="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Messages"
            className="a-input__startchat--search"
          />
          {searchQuery && (
            <span className="chat-search-box-container__input__clear">
              <ClearIcon onClick={() => onClearSearch()} size={"xs"} />
            </span>
          )}
        </div>
        {isLoading && searchQuery?.length > 0 && (
          <div className="loading-container">
            {Array.from(Array(3).keys()).map((_, index) => (
              <ShimmerCategoryItem key={index} />
            ))}
          </div>
        )}

        {!isLoading &&
          loaded &&
          searchResults?.messages?.length === 0 &&
          searchResults?.conversations?.length === 0 &&
          isComponentVisible && (
            <div className="dropdown-container nempty-message-container">
              <div className="dropdown-container__content">
                Oops! No Results Found
              </div>
            </div>
          )}

        {isDropdownOpen && isComponentVisible && (
          <div className="dropdown-container">
            <div className="dropdown-container__content result-search-message-div">
              {searchedConversations?.length > 0 && (
                <div className="content-title-container">People</div>
              )}
              {searchedConversations?.length > 0 ? (
                searchedConversations?.map((item) => (
                  <div
                    className="content-container people-container"
                    onClick={() => onItemClicked(item)}
                    key={item?.id}
                  >
                    <div className={"chat-user-list-item"}>
                      <CircularAvatar
                        src={item.user_image}
                        size={"30"}
                        name={item.title}
                        round={true}
                        wrapperClass={"chat-user-list-item__avatar-wrapper"}
                      />
                      <h4 className={"title"}>
                        <span>{item.title}</span>
                      </h4>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {searchResults?.messages?.length > 0 && (
                <div className="content-title-container">Messages</div>
              )}
              {searchResults?.messages?.length > 0 ? (
                searchResults.messages.map((item) => (
                  <div
                    className="content-container"
                    onClick={() => onItemClicked(item)}
                    key={item?.id}
                  >
                    <div className="content-container__message">
                      {item?.message.substring(0, 600)}
                      {item?.message?.length > 600 && "..."}
                    </div>
                    <div className="content-container__title">
                      <span>
                        {item?.sender_name || ""}{" "}
                        <span className="divider">|</span>
                        {cache[item.send_at] || _getDeliveryTime(item.send_at)}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {searchResults.next_page && (
                <div
                  id={"search-load-more"}
                  className="load-more-search-messages"
                  onClick={() => onClickLoadMore(searchResults.next_page)}
                >
                  Load more{" "}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </SearchMessageBoxStyles>
  );
};
export default memo(SearchMessageBox);

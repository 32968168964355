import React from "react";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import SubscriptionStyles from "./SubscriptionStyles";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import CardCustom from "../CardCustom/CardCustom";

function Subscription({
  title,
  subTitle1,
  subTitle2,
  btnName,
  modelHandler,
  closeDialog,
  showCloseBtn = true,
  onSubscribeClick,
  contactUs,
  isSmallCard = false,
  ...props
}) {
  return (
    <SubscriptionStyles>
      <CardCustom
        className={`subscribe create-alert ${isSmallCard ? "small" : ""}`}
      >
        {isSmallCard ? (
          <h3 className="text-white small-title">{title}</h3>
        ) : (
          <h2 className="text-white title">{title}</h2>
        )}
        <div className="close-btn">
          {showCloseBtn && (
            <div className="closeModal">
              <IconButton onClick={closeDialog} aria-label="close dialog">
                <Close htmlColor="#fff" className="cursor-pointer" />
              </IconButton>
            </div>
          )}
        </div>
        <div
          className={`text-white ${isSmallCard ? "text-name" : "sub-title"} `}
        >
          {subTitle1}
        </div>
        <div>
          <ButtonCustom
            className={`${isSmallCard ? "" : "subscribe-btn"} focus-white ${
              btnName === "Unsubscribe" ? "bismark-grey-color bg-gray" : ""
            }`}
            width={isSmallCard ? "100" : "230"}
            height={isSmallCard ? 30 : "45"}
            onClick={() => {
              modelHandler &&
                modelHandler({
                  open: true,
                  title: "Are you sure you want to subscribe to this alert?",
                  description: `You will receive notifications about new jobs postings. You can unsubscribe or manage your preferences at any time from the Notifications section.`,
                  btnText: "Save Search and Subscribe",
                  type: "subscribe",
                  cancelLink: true,
                  defaultFrequency: "bi-weekly",
                });

              onSubscribeClick && onSubscribeClick();
              contactUs && contactUs();
            }}
          >
            {btnName}
          </ButtonCustom>
        </div>
        {subTitle2 && <div className="text-white text-name">{subTitle2}</div>}
      </CardCustom>
    </SubscriptionStyles>
  );
}

export default Subscription;

import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { navigate } from "@reach/router";

import JobsDashboardStyles from "./JobsDashboardStyles";
import PlayMiniIcon from "../../../../src/assets/svg/watch-tutorial.svg";
import usePersistState from "../../../state/usePersistState";
import { BACKEND_FLAG_URL } from "../../../constant/mockdata";
import FindJob from "../../../components/Jobs/FindJob/FindJob";
import SaveSearchList from "../../../components/Jobs/SaveSearchList/SaveSearchList";
import SaveJobList from "../../../components/Jobs/SaveJobsList/SaveJobsList";
import TopFeaturedJobsList from "../../../components/Jobs/TopFeaturedJobsList/TopFeaturedJobsList";
import JobsTutorialTour from "../../../components/Jobs/JobsTutorialTour/JobsTutorialTour";
import RecommendedJobSearches from "../../../components/Jobs/RecommendedJobSearches/RecommendedJobSearches";
import { useAppState } from "../../../context";

import ChromeWebStore from "../../../assets/svg/chrome-web-store.svg";

const JobProtal = () => {
  const [selectedCountry, setSelectedCountry] = useState();
  const {
    users = {},
    permissions = {},
    firstLoginProfilePopup,
  } = usePersistState();
  const { country_of_origin } = users || {};
  const [show, setShow] = useState(true);
  const { ambassadorPopup } = useAppState("ambassadorProfile");

  const getJobsTutorialWatchedUsers = () => {
    return JSON.parse(localStorage.getItem("users_jobs_tutorial_status")) || [];
  };

  const handleSkipEvent = () => {
    const existingIds = getJobsTutorialWatchedUsers();
    const watched = [...existingIds, users?.id];
    localStorage.setItem(`users_jobs_tutorial_status`, JSON.stringify(watched));
    const { screenX, screenY } = window;
    setShow(true);
    window.scrollTo(screenX, screenY);
  };

  useEffect(() => {
    if (users?.id) {
      const existingIds = getJobsTutorialWatchedUsers();
      setShow(existingIds.includes(users?.id));
    }
  }, [users]);
  return (
    <JobsDashboardStyles>
      <>
        <JobsTutorialTour
          isOpen={!show && !ambassadorPopup && !firstLoginProfilePopup}
          onSkipClick={() => handleSkipEvent()}
          country_of_origin={country_of_origin}
        />
        <div className="job-dashboard">
          <div className="job-content">
            <div className="job-content__jobs-tutorial-container">
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setShow(!true);
                }}
              >
                <span>Watch tutorial</span>
                <span>
                  <img src={PlayMiniIcon} alt="Play icon small" />
                </span>
              </a>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FindJob
                  setSelectedCountry={(country) => setSelectedCountry(country)}
                />
                <div className="ShowResponsiveDiv">
                  <SaveJobList isWidget={true} backPage="jobs" />
                  <SaveSearchList
                    isWidget={true}
                    showCount={true}
                    backPage="jobs"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <TopFeaturedJobsList />
                <RecommendedJobSearches />
              </Grid>
              <Grid item xs={3}>
                <div className="mt-40">
                  <SaveJobList isWidget={true} backPage="jobs" />
                  <SaveSearchList
                    isWidget={true}
                    showCount={true}
                    backPage="jobs"
                  />
                </div>
                <div className="extension-card-wrapper">
                  <div className="extension-card">
                    <div className="interstride-products-banner">
                      <div className="title">Chrome Extension</div>
                      <div className="subtitle">
                        Download Interstride H-1B Sponsorship Finder extension.
                      </div>
                      <div className="action-images">
                        <a
                          href={
                            "https://chromewebstore.google.com/detail/interstride-h-1b-sponsors/oonholcildbjjpikkfiapadhiicmnimb"
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img src={ChromeWebStore} alt="click" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {permissions["country_insights"] && selectedCountry && (
                  <div className="cityjobguide-wrapper">
                    <div className="cityjobguide">
                      <div className="flag-icon">
                        <img
                          src={`${BACKEND_FLAG_URL}${
                            selectedCountry ? selectedCountry.value : ""
                          }.svg`}
                          alt="Country flag"
                        />
                      </div>
                      <h3>
                        Our {selectedCountry ? selectedCountry.label : ""} Guide
                      </h3>
                      <p>
                        Learn about relocating, visa & work permits, housing,
                        and more.
                      </p>
                      <a
                        href="/#"
                        className="nlink-secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `/jobs/country-insights?country=${selectedCountry.value}`
                          );
                        }}
                        keepLabelCase={true}
                      >
                        View Country Insights
                      </a>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    </JobsDashboardStyles>
  );
};

export default JobProtal;

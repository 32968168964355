import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import { isUndefined } from "lodash";

import BookAppointmentSearchStyles from "./BookAppointmentSearchStyles";
import Image from "../../../../assets/svg/discover_no_people.svg";
import usePersistState from "../../../../state/usePersistState";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { getUserFromList } from "../../../../helper/helper";

import Loader from "../../../../components/Loader/Loader";
import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import BookAppointmentSearchList from "./BookAppointmentSearchList";
import ViewProfileCard from "../ViewProfileCard/ViewProfileCard";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import EmptyListWithImage from "../../../EmptyListWithImage/EmptyListWithImage";

import {
  getAppointmentById,
} from "../../../../services/appointmentServices";
import {
  getUsersByMeetingPurposeId,
} from "../../../../services/meetingPurposeServices";

import FilledFilterIcon from "../../../../assets/svg/filled-filter-Icon.svg";
import FilterIcon from "../../../../assets/svg/filter-Icon.svg";

const BookAppointmentSearch = ({ bookingId }) => {
  const {
    users = {},
    appointmentType = null,
    meetingPurposes,
    setAppointmentType,
  } = usePersistState();

  const [appointment, setAppointment] = useState(appointmentType);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [closeViewProfile, setCloseViewProfile] = useState(false);
  const [list, setList] = useState([]);

  const [scheduledAppointment, setScheduledAppointment] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState({
    value: users.time_zone,
    id: users.timezone_id,
  });

  const [callGetUsersByMeetingPurposeId, refreshGetUsersByMeetingPurposeId] =
    usePromise(getUsersByMeetingPurposeId);
  const [, refreshGetAppointmentById] = usePromise(getAppointmentById);

  const meetingPurposeLabel = meetingPurposes?.find(
    (rec) => rec.value === appointment
  )?.label;

  useEffect(() => {
    async function callMeetingPurpose() {
      if (!isUndefined(meetingPurposes) && meetingPurposes?.length > 0) {
        setAppointment(meetingPurposes[0].value);
        let getList = await refreshGetUsersByMeetingPurposeId(
          meetingPurposes[0].value
        );
        if (typeof getList !== "undefined") {
          setList(getList.appointment_settings);
        }
      }
    }
    callMeetingPurpose();
  }, [meetingPurposes?.length]);

  useEffect(() => {
    async function getAppointment() {
      let appointmentById = await refreshGetAppointmentById(
        parseInt(bookingId),
        selectedTimezone.value,
        selectedTimezone.id
      );
      setScheduledAppointment(appointmentById?.appointment);
      setAppointment(appointmentById?.appointment?.meeting_purpose.id);
      const createdById = appointmentById?.appointment?.admin_user?.id;
      if (list?.length > 0) {
        list.filter((i, k) => {
          if (i?.user?.id === createdById) {
            setSelectedProfile(i?.user?.id);
          }
          return i?.user?.id === createdById;
        });
      }
    }
    if (bookingId) {
      getAppointment();
    } else {
      setScheduledAppointment(null);
    }
  }, [bookingId, list]);

  useEffect(() => {
    const createdById = scheduledAppointment?.admin_user?.id;
    if (list?.length > 0) {
      list.filter((i, k) => {
        if (i?.user?.id === createdById) {
          setSelectedProfile(i?.user?.id);
        }
        return i?.user?.id === createdById;
      });
    }
  }, [list, scheduledAppointment]);

  useEffect(() => {
    if (
      callGetUsersByMeetingPurposeId.hasFetched() &&
      !callGetUsersByMeetingPurposeId.hasErrors() &&
      callGetUsersByMeetingPurposeId.data()
    ) {
      setList(callGetUsersByMeetingPurposeId.data().appointment_settings);
    }
  }, [callGetUsersByMeetingPurposeId.isFetching()]);

  useEffect(() => {
    if (closeViewProfile) {
      setSelectedProfile(null);
    }
  }, [closeViewProfile]);

  const applyCurrentFilters = async () => {
    let getList = await refreshGetUsersByMeetingPurposeId(appointment, search);
    if (typeof getList !== "undefined") {
      setList(getList.appointment_settings);
      if (getList?.appointment_settings?.length > 0) {
        setSelectedProfile(getList?.appointment_settings[0]?.id);
        setAppointmentType(appointment);
      }
    }
  };
  const [openFilter, toggleFilter] = useState(false);
  const BookAppointmentSearchFilter = (
    <div
      className={`find-job-section collapsible-view ${
        openFilter ? "open" : ""
      }`}
    >
      <h2 className="title-primary mb-15">
        Search & filter
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            toggleFilter(!openFilter);
          }}
          className="collapse-icon"
          aria-label="Toggle filter"
        >
          {openFilter ? (
            <img src={FilledFilterIcon} alt="Filter Icon" />
          ) : (
            <img src={FilterIcon} alt="Filter Icon" />
          )}
        </a>
      </h2>
      <Grid container>
        <Grid item xs={12}>
          <CardCustom className="search-card">
            <div className="form-field-group">
              <label className="form-label textbox-label">
                Search a person
              </label>
              <TextboxCustom
                name="search"
                type="text"
                placeholder="Enter a name"
                label=""
                value={search}
                lableaignment="left"
                onChange={(e) => setSearch(e.target.value)}
                onEnterKey={applyCurrentFilters}
              />
            </div>
            <div className="form-field-group">
              <DropdownCustom
                name="purpose"
                label="Select purpose"
                placeholder={"Select purpose"}
                justifyContent="left"
                options={meetingPurposes}
                value={
                  !isUndefined(meetingPurposes) &&
                  meetingPurposes.find((rec) => rec.value === appointment)
                }
                onChange={(e) => setAppointment(e.value)}
              />
            </div>
            <div className="searchBtn">
              <ButtonCustom onClick={applyCurrentFilters} width="250">
                Search
              </ButtonCustom>
            </div>
          </CardCustom>
        </Grid>
      </Grid>
    </div>
  );
  const selectedProfileData =
    list?.length > 0 && selectedProfile
      ? getUserFromList(list, selectedProfile)
      : null;

  return (
    <BookAppointmentSearchStyles>
      <div className="book-appointment-search-wrapper">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            {BookAppointmentSearchFilter}
          </Grid>
          <Grid item xs={9}>
            <div className={`search-list`}>
              <Grid container spacing={3}>
                <Grid item xs={selectedProfile !== null && list ? 8 : 12}>
                  <h2 className="title-primary mb-15">{meetingPurposeLabel}</h2>
                  {callGetUsersByMeetingPurposeId.isFetching() ? (
                    <Loader />
                  ) : (
                    <>
                      {list && !isUndefined(list) ? (
                        <BookAppointmentSearchList
                          list={list}
                          selectedProfile={selectedProfile}
                          setSelectedProfile={setSelectedProfile}
                          closeViewProfile={closeViewProfile}
                          setSelectedTimezone={setSelectedTimezone}
                          bookingId={bookingId}
                        />
                      ) : (
                        <EmptyListWithImage
                          Image={Image}
                          title="Looks like there are no advisors."
                          subTitle="Try expanding your search to discover other advisors!"
                          image
                        />
                      )}
                    </>
                  )}
                </Grid>
                {selectedProfile !== null &&
                  list &&
                  selectedProfileData &&
                  Object.keys(selectedProfileData)?.length > 0 && (
                    <Grid item xs={4}>
                      <ViewProfileCard
                        data={selectedProfileData}
                        scheduledAppointment={scheduledAppointment}
                        showBtn={false}
                        onBtnClick={() => {
                          setCloseViewProfile(false);
                        }}
                        onClose={() => {
                          setSelectedProfile(null);
                          setCloseViewProfile(true);
                        }}
                        appointmentType={appointment}
                      />
                    </Grid>
                  )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </BookAppointmentSearchStyles>
  );
};

export default BookAppointmentSearch;

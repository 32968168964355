import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { navigate } from "@reach/router";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleSpinner } from "react-spinners-kit";
import { ShimmerSocialPost } from "react-shimmer-effects";

import SavedPostStyles from "./SavedPostStyles";
import BackArrow from "../../assets/svg/f-btn-arrow-gray.svg";
import FallbackImage from "../../assets/svg/saved-posts-fallback-image.svg";
import UserImg from "../../assets/user.jpg";
import { routes } from "../../routes";
import { useAppState } from "../../context";
import usePromise from "../../hooks/usePromise/usePromise";
import { PRIMARY_COLOR } from "../../styles/variables";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import DashboardPost from "../../components/Dashboard/DashboardMiddle/DashboardPost";
import BreakpointMasonry from "../../components/BreakpointMasonry/BreakpointMasonry";
import ButtonCustom from "../../components/ButtonCustom/ButtonCustom";
import EmptyListWithImage from "../../components/EmptyListWithImage/EmptyListWithImage";
import { getSavedPostsListAPI } from "../../services/dashboardServices";


const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center spinner-div">
      <CircleSpinner color={PRIMARY_COLOR} size={25} />
    </div>
  );
};

const SavedPost = () => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { saved_user_posts, setSavedUserPosts } = useAppState("post");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [callgetSavedPostsListAPI, refreshgetSavedPostsListAPI] =
    usePromise(getSavedPostsListAPI);
  const [showLoader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    refreshgetSavedPostsListAPI(currentPage);
  }, []);

  useEffect(() => {
    if (
      callgetSavedPostsListAPI.hasFetched() &&
      callgetSavedPostsListAPI.hasErrors() === false &&
      callgetSavedPostsListAPI.data() &&
      callgetSavedPostsListAPI.data().data
    ) {
      setTotalPages(callgetSavedPostsListAPI.data().data.total_pages);
      setSavedUserPosts(callgetSavedPostsListAPI.data().data);
      setLoader(false);
    }
  }, [callgetSavedPostsListAPI.isFetching()]);

  const fetchMoreData = () => {
    refreshgetSavedPostsListAPI(currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  return (
    <SavedPostStyles>
      <MainLayout
        title="Saved Posts"
        breadCrumb={[
          { title: "Home", path: routes.DASHBOARD },
          { title: "Saved Posts", path: routes.SAVED_POSTS },
        ]}
        viewOnlyNavbar={true}
      >
        <div className="custom-container">
          <div className="savedposts-content">
            <div className="f-main-title-with-button mt-30 mb-30">
              <div className="back-btn-wrapper">
                <a
                  className="shadow-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(routes.DASHBOARD);
                  }}
                  href="/dashboard"
                  aria-label="Back to Dashboard"
                >
                  <img
                    src={BackArrow}
                    id="BackArrow"
                    width="18px"
                    alt="Back arrow"
                  />
                  <span>Back to Dashboard</span>
                </a>
              </div>
              <h2 className="f-main-title mb-0">Your Saved Posts </h2>
            </div>
            <div className="saved-posts-wrapper">
              {showLoader ? (
                <Grid spacing={2} container>
                  {Array.from(Array(4).keys()).map((item, index) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                      <ShimmerSocialPost />
                    </Grid>
                  ))}
                </Grid>
              ) : saved_user_posts.length > 0 ? (
                <>
                  {accessibilityHelp == "true" ? (
                    <div>
                      <BreakpointMasonry grid={1}>
                        {saved_user_posts.map((item, k) => (
                          <DashboardPost
                            key={k}
                            avatarImg={UserImg}
                            post={item}
                            refreshSavedPosts={() => {
                              setLoader(true);
                              refreshgetSavedPostsListAPI();
                            }}
                          />
                        ))}
                      </BreakpointMasonry>

                      {currentPage < totalPages && (
                        <center>
                          {callgetSavedPostsListAPI.isFetching() ? (
                            <Loader />
                          ) : (
                            <ButtonCustom onClick={fetchMoreData}>
                              Load More
                            </ButtonCustom>
                          )}
                        </center>
                      )}
                    </div>
                  ) : (
                    <InfiniteScroll
                      dataLength={saved_user_posts.length}
                      next={fetchMoreData}
                      hasMore={currentPage < totalPages}
                      loader={<Loader />}
                      style={{ overflow: "none" }}
                    >
                      <div>
                        {saved_user_posts.map((item, k) => (
                          <DashboardPost
                            key={k}
                            avatarImg={UserImg}
                            post={item}
                            refreshSavedPosts={() => {
                              setLoader(true);
                              refreshgetSavedPostsListAPI();
                            }}
                          />
                        ))}
                      </div>
                    </InfiniteScroll>
                  )}
                </>
              ) : (
                <EmptyListWithImage
                  Image={FallbackImage}
                  whoops
                  title="Looks like you don’t have any saved posts here."
                  subTitle={
                    "To save a post, click on the “More” icon on the top right of a post and select “Save post”."
                  }
                  containerClassname={"empty-saved-posts"}
                />
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    </SavedPostStyles>
  );
};

export default SavedPost;

import { useState } from "react";
import _, { isString } from "lodash"
import { useAppState } from "../../context";
import { toastify } from "../../helper/helper";
import { updateUserProfile } from "../../services/profile.service";
import {
  isStaffAccountType,
  validateEducationForm,
  validateLinkedinURL,
  validatePersonalForm,
} from "../../utils/common";
import usePersistState from "../../state/usePersistState";

export const useUpdateProfile = (callback) => {
  const state = useAppState("profile");

  const {
    personalForm,
    educationsForm,
    careerForm,
    profile,
    experienceForm,
    updateProfileState,
    departmentList,
    accessibility_help,
  } = state || {};
  const [loading, setLoading] = useState(false);
  const { users = {}, updateUsersPersistState } = usePersistState();
  const updateProfile = async () => {
    try {
      setLoading(true);
      const data = {
        ...profile,
        personal: personalForm,
        educations: educationsForm,
        career: { ...careerForm },
        experience: {
          ...experienceForm,
        },
        isProfileCompleted: true,
        accessibility_help,
      };
      const response = await updateUserProfile(data);
      if (response?.success) {
        toastify("error", "Profile updated successfully");
        //setProfile(response.data);
        const { personal, educations, experience, career } =
          response?.data || {};
        let replaceObj = {}
        Object.keys(personal).forEach((key) => {
          if(_.has(users, key)) {
            replaceObj[key] = personal[key]
          }
        });

        ///improved
        updateProfileState({
          profile: { ...response.data },
          personalForm: personal,
          educationsForm: educations,
          experienceForm: { ...experience },
          careerForm: career,
        });
        updateUsersPersistState({...replaceObj, educations: educations});
        setLoading(false);
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      setLoading(false);
      const { data } = error || {};
      const { success, message } = data || {};
      if (!success) {
        toastify("error", message ?? "Something went wrong. Please try again");
      }
    }
  };
  const handleSave = () => {
    let response = validatePersonalForm(personalForm);

    if (response !== 200) {
      toastify("error", response);
      return;
    }

    /**
     * Don't handle validation for staff
     */
    if (isStaffAccountType(personalForm.account_type, personalForm.user_type, true)) {
      updateProfile();
      return;
    }
    const invalidEducation = validateEducationForm(
      educationsForm,
      personalForm.account_type,
      { departmentList }
    );

    if (invalidEducation ) {
      if(isString(invalidEducation)) {
        toastify("error", invalidEducation);
      } else {
        toastify("error", "Please fix errors in education section");
      }

      return;
    }

    const hasLanguageError = experienceForm?.languages?.some(
      ({ level, name, draftId }) => {
        if (draftId) {
          if ((!level && name) || (!name && level)) return true;
        }
      }
    );
    if (hasLanguageError) {
      toastify("error", "Please fix errors in language section");
      return;
    }

    const { linkedIn_id } = careerForm;
    if (linkedIn_id && !validateLinkedinURL(linkedIn_id)) {
      toastify("error", "LinkedIn profile url is invalid");
      return;
    }
    updateProfile();

    // orignal educations
    // what inside the local state
  };

  const handleAccessibilitySave = async (accessibility) => {
    try {
      setLoading(true);
      const data = {
        ...profile,
        accessibility_help: accessibility,
      };
      const response = await updateUserProfile(data);
      if (response?.success) {
        toastify("error", "Profile updated successfully");
        //setProfile(response.data);
        const { personal, educations, experience, career } =
          response?.data || {};
        ///improved
        updateProfileState({
          profile: { ...response.data },
          personalForm: personal,
          educationsForm: educations,
          experienceForm: { ...experience },
          careerForm: career,
        });
        updateUsersPersistState({time_zone: personal.time_zone, timezone_id: personal.timezone_id});
        setLoading(false);
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      setLoading(false);
      const { data } = error || {};
      const { success, message } = data || {};
      if (!success) {
        toastify("error", message ?? "Something went wrong. Please try again");
      }
    }
  }
  const handleVisibilitySave = async (visibility, isVisible, personalForm = null) => {
    try {
      setLoading(true);
      const data = {
        ...profile,
        is_network_popup_shown: visibility,
      };
      const response = await updateUserProfile(data);
      if (response?.success) {
        toastify("success", isVisible ? "Your profile is successfully visible to network." : "Your profile is successfully hidden to network.");
        //setProfile(response.data);
        const { personal, educations, experience, career } =
          response?.data || {};
        ///improved
        updateProfileState({
          profile: { ...response.data },
          personalForm: personalForm ? personalForm : personal,
          educationsForm: educations,
          experienceForm: { ...experience },
          careerForm: career,
        });
        updateUsersPersistState({time_zone: personal.time_zone, timezone_id: personal.timezone_id});
        setLoading(false);
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      setLoading(false);
      const { data } = error || {};
      const { success, message } = data || {};
      if (!success) {
        toastify("error", message ?? "Something went wrong. Please try again");
      }
    }
  }

  return {
    handleSave,
    loading,
    handleAccessibilitySave,
    handleVisibilitySave
  };
};

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import webSocketReducer from "./reducers/webSocketReducer";
import optReducer from "./reducers/optReducer";
import preservedReducer from "./reducers/preservedReducer";

const persistConfig = {
  key: "interstride_student",
  storage: storage,
  blacklist: ["webSocketStore"],
};

const rootReducer = combineReducers({
  webSocketStore: webSocketReducer,
  optStore: optReducer,
  preservedStore: preservedReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [],
});

export const persistor = persistStore(store);

import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";
import { communitiesFilterOptions } from "../utils/common";

/** -----------------------------DICSOVER TOPICS PAGE APIS------------------------------------- */
export const getDiscoverTopicsByNameAPI = ({
  search_keyword,
  topic_type,
  interests = "",
}) => {
  let url = `topics/discover_dashboard?search_keyword=${search_keyword}&interests=${interests}`;
  if (topic_type === "student" || topic_type === "admissions") {
    url += "&user_type=" + topic_type;
    url += "&filter=" + topic_type;
  } else {
    url += "&topic_type=" + topic_type;
  }
  return axiosInstance.get(url, headers_with_token()).then((res) => {
    return res.data;
  });
};

export const getTopicDetailsAPI = (topicId = null, page = 1, per_page = 10) => {
  return axiosInstance
    .get(`communities/${topicId}`, {
      headers: headers_with_token().headers,
      params: {
        page,
        per_page,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getTopicParticipantsDetailsAPI = (
  topicId = null,
  page = 1,
  filters = {},
  search = ""
) => {
  let query = `?pagination[page]=${page}&pagination[per_page]=10`;
  if (search?.length > 0) query += "&search[name]=" + search;
  if (filters?.country?.value)
    query += "&search[country]=" + filters?.country.value;
  if (filters?.account_type?.value)
    query += "&search[user_type]=" + filters?.account_type.value;
  if (filters?.degree?.value)
    query += "&search[degree]=" + filters?.degree.value;
  if (filters?.major?.value) query += "&search[major]=" + filters?.major.value;
  if (filters?.graduation_year?.value)
    query += "&search[graduation_year]=" + filters?.graduation_year.value;
  if (filters?.department?.value)
    query += "&search[department]=" + filters?.department.value;

  return axiosInstance
    .get(`topics/${topicId}/participants${query}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** ----------My TOPICS PAGE APIS--------------- */

export const getMyTopicsAPI = (filter = communitiesFilterOptions[0]) => {
  return axiosInstance
    .get(`topics?filter=${filter}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getTopicPreview = (topic_url) => {
  return axiosInstance
    .get(
      `topics/topic_thumbnailer_url?topic_url=${topic_url}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return { success: false };
    });
};

export const getTopicPendingRequestAPI = (topicId) => {
  return axiosInstance
    .get(
      `topics/following_request_list?topic_id=${topicId}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const AcceptDeclineTopicRequestAPI = (payload) => {
  return axiosInstance
    .post(`topics/follow_unfollow_topic`, payload, headers_with_token())
    .then((res) => {
      // console.log("AcceptDeclineTopicRequestAPI=>>", res.data);
      return res.data;
    });
};

export const createNewTopicAPI = (payload) => {
  return axiosInstance
    .post(`topics`, payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const editTopicAPI = (payload, topicId) => {
  return axiosInstance
    .put(`topics/${topicId}`, payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const createNewPostAPI = (payload) => {
  return axiosInstance
    .post(`topics/create_or_update_post`, payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const sendCommentAPI = (payload) => {
  return axiosInstance
    .post(`topics/create_or_update_post_comment`, payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const muteUnmuteTopicAPI = (
  topic_follower_id = null,
  topic_id = null
) => {
  console.log(topic_follower_id, topic_id);
  return axiosInstance
    .get("topics/mute_or_unmute_topic", {
      params: {
        topic_follower_id,
        topic_id,
      },
      ...headers_with_token(),
    })
    .then((res) => {
      return res.data;
    });
};

export const closeTopicAPI = (id) => {
  return axiosInstance
    .delete(`topics/${id}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const reportTopicAPI = (reportData) => {
  return axiosInstance
    .post(`topics/report_topic`, reportData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const departmentListAPI = (id) => {
  return axiosInstance
    .get(`department_list?school_id=${id}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getSuggestedTopicUsersAPI = ({ user_name = "" } = {}) => {
  return axiosInstance
    .get(
      `network_modules/users_list?user_name=${user_name}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getTopicsNotificationCountsAPI = () => {
  return axiosInstance
    .get(`notifications/all_topic_notifications`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const readTopicNotificationAPI = (topicID) => {
  return axiosInstance
    .post(
      `notifications/read_topic_notifications`,
      { topic_id: topicID },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getTopicParticipantsFilters = (topicId) => {
  return axiosInstance
    .get(`topics/${topicId}/participants_search_list`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const favouriteTopic = (topicId) => {
  return axiosInstance
    .patch(`communities/${topicId}/favourite`, null, headers_with_token())
    .then((res) => {
      return res;
    });
};

export const getTopicFeed = (page = 1, per_page = 10) => {
  return axiosInstance
    .get("topic_posts", {
      headers: headers_with_token().headers,
      params: {
        page,
        per_page,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getTopicPostComments = (
  topic_post_id,
  page = 1,
  per_page = 10
) => {
  return axiosInstance
    .get(`topic_posts/${topic_post_id}/comments`, {
      headers: headers_with_token().headers,
      params: {
        page,
        per_page,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getTopicPostReactions = (topic_post_ids = "") => {
  return axiosInstance
    .get(`topic_posts/details${topic_post_ids}`, {
      headers: headers_with_token().headers,
    })
    .then((res) => {
      return res.data;
    });
};

export const updateTopicPostReaction = (topic_post_id = null, payload = {}) => {
  return axiosInstance
    .post(
      `topic_posts/${topic_post_id}/reaction`,
      payload,
      headers_with_token()
    )
    .then((res) => {
      return res;
    });
};

export const saveUnsaveTopicPost = (topic_post_id = null) => {
  return axiosInstance
    .post(`topic_posts/${topic_post_id}/save`, null, headers_with_token())
    .then((res) => {
      return res;
    });
};

export const reportTopicPost = (topic_post_id = null, params) => {
  return axiosInstance
    .post(`topic_posts/${topic_post_id}/report`, params, headers_with_token())
    .then((res) => {
      return res;
    });
};

export const getFollowingTopics = (
  filter = communitiesFilterOptions[0],
  page = 1,
  per_page = 50
) => {
  return axiosInstance
    .get("communities/followings", {
      headers: headers_with_token().headers,
      params: {
        filter,
        page,
        per_page,
      },
    })
    .then((res) => {
      return res;
    });
};

export const suggestCommunityName = (params = {}) => {
  return axiosInstance
    .post("communities/suggest", params, headers_with_token())
    .then((res) => {
      return res;
    });
};

export const getSavedTopicPosts = (
  topic_id = null, // Optional param
  page = 1,
  per_page = 10
) => {
  return axiosInstance
    .get("topic_posts/saved", {
      headers: headers_with_token().headers,
      params: {
        ...(topic_id ? { topic_id } : {}),
        page,
        per_page,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const deleteTopicPost = (topic_post_id = null) => {
  return axiosInstance
    .delete(
      `topics/delete_post?topic_post_id=${topic_post_id}`,
      headers_with_token()
    )
    .then((res) => {
      return res;
    });
};

export const deleteTopicPostComment = (post_comment_id = null) => {
  return axiosInstance
    .delete("topics/delete_post_comment", {
      data: {
        post_comment_id,
      },
      ...headers_with_token(),
    })
    .then((res) => {
      return res;
    });
};

import React, {useState, useEffect} from "react";
import { Grid } from "@material-ui/core";
import { navigate } from "@reach/router";
import { isUndefined } from "lodash";

import { CustomDialogBox } from "./BookAppointmentSearchListStyles";
import usePersistState from "../../../../state/usePersistState";
import { NoUserImage } from "../../../../helper/helper";

import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import TimezoneUpdateDialog from "../TimezoneUpdateDialog";
import { fetchUserProfile } from "../../../../services/profile.service";



const BookAppointmentSearchList = ({
  list = [],
  selectedProfile,
  setSelectedProfile,
  setSelectedTimezone,
  closeViewProfile,
  bookingId
}) => {
  const { users = {} } = usePersistState();
  const { updatePersistState } = usePersistState();
  const [showPopup, setShowPopup] = useState(true);
  const [isDDClicked, setIsDDClicked] = useState(false);

  const fetchProfile = async () => {
    try {
      const response = await fetchUserProfile();
      const { personal } = response?.data || {};
      updatePersistState({users: {...personal}})
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if(!users.timezone_id) {
      fetchProfile()
    }
  }, [])

  if(list && !isUndefined(list) && list.length > 0 && !selectedProfile && !closeViewProfile) {
    setSelectedProfile(list[0].id);
  }

  const renderListItem = (data, i) => {
  const userName = (data?.user?.first_name +" "+ data?.user?.last_name) || "NAME";

    return (
      <div key={i}>
        {
          (showPopup && !users.timezone_id) ? (
            <CustomDialogBox
              open={showPopup}
              title={"Enter Timezone"}
              handleClose={() => setShowPopup(false)}
              isDisplayCloseButton={false}
              className="set-timezone-popup"
              dialogTitleClass="modal-title"
              dialogSubTitleClass="text-para"
              height={isDDClicked ? "550" : "350"}
            >
              <TimezoneUpdateDialog
                setUpdateTimezonePopUp={(e) => setShowPopup(e)}
                setIsDDClicked={setIsDDClicked}
                isDDClicked={isDDClicked}
                setSelectedTimezone={setSelectedTimezone}
              />
            </CustomDialogBox>
          ) : null
        }
        {data.user && (
          <Grid item xs={12} key={i}>
            <CardCustom
              className={`AppointmentSearch ${
                selectedProfile !== null && selectedProfile === data?.id
                  ? "active"
                  : ""
              }`}
            >
              <div className="search-item" onClick={() => {
                setSelectedProfile(data?.id)
              }}>
                <div className="imgWrapper">
                  <img
                    src={data.user.user_profile_url || NoUserImage}
                    alt="User profile"
                  />
                </div>
                <div className="content">
                  <div className="header-primary">
                    {userName}
                  </div>
                  <div className="sub-header-primary">{data.title}</div>
                </div>
                <div className="btn-wrap">
                  <ButtonCustom
                    width="155"
                    height="35"
                    onClick={() => {
                      let navigateUrl
                      if(bookingId) {
                        navigateUrl = "/tools/book-meeting/" + data?.id + "?bookingid=" + bookingId
                      } else {
                        navigateUrl = "/tools/book-meeting/" + data?.id
                      }
                      if (navigateUrl) {
                        navigate(navigateUrl);
                      }
                    }}
                  >
                    Check availability
                  </ButtonCustom>
                </div>
              </div>
            </CardCustom>
          </Grid>
        )}
      </div>
    );
  };

  return (
    <>
      {list &&
        list.length > 0 &&
        list.map((data, i) => renderListItem(data, i))}
    </>
  );
};

export default BookAppointmentSearchList;

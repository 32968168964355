import { Popover, Typography } from "@material-ui/core";
import React from "react";
import { memo } from "react";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
const ProfilePopup = ({ anchorEl, open, text, onClose }) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      className="popover-wrapper"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Typography>{text}</Typography>
      {accessibilityHelp == "true" && (
        <div className="close-button">
          <ButtonCustom onClick={onClose}>Got It!</ButtonCustom>
        </div>
      )}
    </Popover>
  );
};

export default memo(ProfilePopup);

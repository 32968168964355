import React, { useEffect, useState } from "react";
import { Router, navigate } from "@reach/router";

import { routes } from "../../routes";
import usePromise from "../../hooks/usePromise/usePromise";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import CareerGameplan from "./CareerGameplan/CareerGameplan";
import CareerGameplanTest from "./CareerGameplan/CareerGameplanTest/CareerGameplanTest";
import Checklist from "./CheckList/Checklist";
import Calendar from "./Calendar/Calendar";
import BookAppointment from "./BookAppointment/BookAppointment";
import OPTCalculator from "./OPTCalculator/OPTCalculator";
import Container from "../../components/Container/Container";
import BookMeeting from "./BookAppointment/BookMeeting/BookMeeting";
import BookAppointmentWithAdmin from "../../components/Tools/BookAppointment/BookAppointmentWithAdmin/BookAppointmentWithAdmin";

import usePersistState from "../../state/usePersistState";

import { getMeetingPurposes } from "../../services/meetingPurposeServices";

import toolsCareerIcon from "../../assets/svg/toolsCareerIcon.svg";
import toolsChecklistIcon from "../../assets/svg/toolsChecklistIcon.svg";
import CareerGameplanFocusIcon from "../../assets/FocusIcons/MenuIcons/Tools/CareerGameplan.svg";
import ChecklistFocusIcon from "../../assets/FocusIcons/MenuIcons/Tools/Checklist.svg";
import optCalculatorIcon from "../../assets/svg/opt-calculator-header.svg";
import toolsAppointmentIcon from "../../assets/png/f-meet.png";

const Tools = ({ location, permissions = {} }) => {
  const [showBookAppointment, setShowBookAppointment] = useState(false);
  const [callGetMeetingPurposes, refreshGetMeetingPurposes] =
    usePromise(getMeetingPurposes);
  useEffect(() => {
    if (
      (location.pathname.includes(routes.TOOLS.CAREER) &&
        !permissions["gameplan"]) ||
      (location.pathname.includes(routes.TOOLS.CHECKLIST) &&
        !permissions["checklist"]) ||
      (location.pathname.includes(routes.TOOLS.OPT_CALCULATOR) &&
        !permissions["opt_calculator"])
    )
      navigate(routes.DASHBOARD);
  }, [permissions]);

  useEffect(() => {
    if (
      callGetMeetingPurposes.hasFetched() &&
      !callGetMeetingPurposes.hasErrors()
    ) {
      let appointmentSettings =
        callGetMeetingPurposes.data()?.data?.appointment_settings;
      if (appointmentSettings && appointmentSettings.length > 0) {
        setShowBookAppointment(true);
      }
    }
  }, [callGetMeetingPurposes.isFetching()]);

  useEffect(() => {
    async function getMeetingPurposes() {
      let response = await refreshGetMeetingPurposes();
      let appointmentSettings = response?.data?.appointment_settings;
      if (appointmentSettings && appointmentSettings.length > 0) {
        setShowBookAppointment(true);
      }
    }
    getMeetingPurposes();
  }, []);
  const tabs = [
    ...(permissions["gameplan"]
      ? [
          {
            title: "Career Gameplan",
            toolTip: "Test your career readiness",
            avatar: toolsCareerIcon,
            focusAvatar: CareerGameplanFocusIcon,
            navigateURL: routes.TOOLS.CAREER,
            isActive: location.pathname === routes.TOOLS.CAREER,
          },
          {
            title: "Career Gameplan Questionnaire",
            toolTip: "",
            avatar: toolsCareerIcon,
            focusAvatar: CareerGameplanFocusIcon,
            navigateURL: routes.TOOLS.CAREER_TEST,
            isActive: location.pathname === routes.TOOLS.CAREER_TEST,
            isDisplay: false,
          },
        ]
      : []),
    ...(permissions["opt_calculator"]
      ? [
          {
            title: "OPT Calculator",
            avatar: optCalculatorIcon,
            focusAvatar: optCalculatorIcon,
            navigateURL: routes.TOOLS.OPT_CALCULATOR,
            fullURL: location.href,
            isActive: location.pathname === routes.TOOLS.OPT_CALCULATOR,
          },
        ]
      : []),
    ...(permissions["checklist"]
      ? [
          {
            title: "Checklist",
            toolTip:
              "To prepare for your job search, interviews, and negotiations",
            avatar: toolsChecklistIcon,
            focusAvatar: ChecklistFocusIcon,
            navigateURL: routes.TOOLS.CHECKLIST,
            isActive: location.pathname === routes.TOOLS.CHECKLIST,
          },
        ]
      : []),
    ...(permissions["appointment"] && showBookAppointment
      ? [
          {
            title: "Meet",
            avatar: toolsAppointmentIcon,
            focusAvatar: toolsAppointmentIcon,
            navigateURL: routes.TOOLS.MEET,
            fullURL: location.href,
            isActive: location.pathname === routes.TOOLS.MEET,
          },
        ]
      : []),
  ];
  return (
    <MainLayout
      title="Tools Dashboard"
      withSubHeader={true}
      tabs={tabs}
      breadCrumb={["Tools", "Career Gameplan"]}
      location={location}
      viewOnlyNavbar
      containerClassName={
        location.pathname === routes.TOOLS.CAREER_TEST
          ? "career-game-plan__wrapper"
          : ""
      }
    >
      <Container>
        <Router>
          <CareerGameplan path="/" />

          {permissions["gameplan"] && (
            <>
              <CareerGameplan path="/career-gameplan" />
              <CareerGameplanTest path="/career-gameplan/career-gameplan-test" />
            </>
          )}

          {permissions["checklist"] && <Checklist path="/checklist" />}

          {permissions["appointment"] && (
            <>
              <Calendar path="/calendar" />
              <BookAppointment path="/meet" />
              <BookMeeting path="/book-meeting" />
              <BookAppointmentWithAdmin path="/book-meeting/:id" />
            </>
          )}
          {permissions["opt_calculator"] && (
            <OPTCalculator path="/opt-calculator" />
          )}
        </Router>
      </Container>
    </MainLayout>
  );
};

export default Tools;

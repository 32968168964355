import React, { useEffect, Suspense, useState, lazy, useCallback } from "react";
import { navigate, Router, globalHistory } from "@reach/router";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import without from "underscore/modules/without";
import moment from "moment";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import TagManager from "react-gtm-module";
import Bugsnag from "@bugsnag/js";

import "./App.css";
import "./styles/main.scss";
import "./constant/firebase";

import { theme } from "./theme/theme";
import { AppStateProvider } from "./context";
import { BASE_PATH, routes } from "./routes";
import usePersistState from "./state/usePersistState";
import {
  camelCase,
  updateLastActiveSession,
  applyAccessibilityHelp,
  addMicrosoftClarity,
  INTERSTRIDE_DEFAULT_LOGO,
  clearLocalStorage,
  toastify,
} from "./helper/helper";
import usePromise from "./hooks/usePromise/usePromise";
import { getUserPermissionsAPI } from "./services/authenticationServices";
import WebsocketProvider from "./WebsocketProvider";
import { config } from "./constant/config";
import TutorialAmbassador from "./helper/tutorialAmbassador";
import {
  cacheTimezone,
  fetchRouteParamsFromURL,
  getRedirectRouteFromURL,
  isPageAccessible,
} from "./utils/common";

/** App State */
import usePosts from "./state/usePosts";
import useApp from "./state/useApp";
import useBoostProfile from "./state/useBoostProfile";
import useMode from "./state/useMode";
import useAuth from "./state/useAuth";
import useCountryInsight from "./state/useCountryInsight";
import useNotification from "./state/useNotification";
import useJob from "./state/useJobs";
import useNetwork from "./state/useNetwork";
import useTopicV2 from "./state/useTopicV2";
import useTools from "./state/useTools";
import useLearn from "./state/useLearn";
import useAmbassadorTutorial from "./state/useAmbassadorTutorial";

/** App Pages Without Lazy Loading */
import Dashboard from "./pages/Dashboard/Dashboard";
import Notification from "./pages/Notification/Notification";
import Jobs from "./pages/JobPortal/Jobs";
import Learn from "./pages/Learn/Learn";
import Network from "./pages/Network/Network";
import Tools from "./pages/Tools/Tools";
import SavedPost from "./pages/SavedPost/SavedPost";
import BoostProfile from "./pages/BoostProfile/BoostProfile";
import HelpCenter from "./pages/HelpCenter/HelpCenter";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import useChat from "./state/useChat";
import { ViewportProvider } from "./context/viewportContext";
import KommunicateChat from "./components/ChatBot/ChatBot";
import ProfileModule from "./pages/ProfileModule";
import useProfile from "./state/useProfile";
import useAmbassadorProfile from "./state/useAmbassadorProfile";
import AmbassadorAcceptInvite from "./pages/Ambssador/AmbassadorAcceptInvite";
import { VerifySecondaryEmail } from "./components/VerifyEmail/VerifySecondaryEmail";
import Smartbanner from "./components/Smartbanner/Smartbanner";
import Widget from "./pages/Widget/Widget";
import { AppVersionManager } from "./components/AppVersionManager/AppVersionManager";
import ParentEffect from "./components/atoms/ParentEffect/ParentEffect";
import {
  setSavedRoute,
  wipeOutSavedRoute,
} from "./redux/reducers/preservedReducer";

const Login = lazy(() => import("./pages/Onboarding/Login/Login"));
const Onboarding = lazy(() => import("./pages/Onboarding/Onboarding"));
const UnsubscribeNotifications = lazy(() =>
  import("./pages/Notification/Unsubscribe/Unsubscribe")
);
const EditEmail = lazy(() => import("./pages/EditEmail/EditEmail"));

const containers = {
  post: usePosts,
  auth: useAuth,
  app: useApp,
  boostProfile: useBoostProfile,
  modeManger: useMode,
  tools: useTools,
  learn: useLearn,
  job: useJob,
  countryInsight: useCountryInsight,
  notifications: useNotification,
  network: useNetwork,
  topic: useTopicV2,
  chat: useChat,
  profile: useProfile,
  ambassadorTutorial: useAmbassadorTutorial,
  ambassadorProfile: useAmbassadorProfile,
};

var requestFirebaseNotificationPermission = null;
var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
  );

function App() {
  const {
    updateUserPermissions,
    permissions = {},
    studentViewPreference = "international",
  } = usePersistState();
  const [loggedIn, setloggedIn] = useState(false);
  const [callGetUserPermissionsAPI, refreshGetUserPermissionsAPI] = usePromise(
    getUserPermissionsAPI
  );
  const { savedRoute = {} } = useSelector((store) => store.preservedStore);
  const dispatch = useDispatch();

  useEffect(() => {
    const accessibilityHelp = localStorage.getItem("accessibilityHelp");
    const authToken = localStorage.getItem("authToken");
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    const keepSignedIn = localStorage.getItem("keepSignedIn");
    const isLogin = sessionStorage.getItem("isLogin");
    const authExpire = localStorage.getItem("authTokenExpiration");
    const authExpiration = moment(authExpire).unix();
    const currDate = moment().add(5, "minutes").unix();

    applyAccessibilityHelp(isLogin === "1" && accessibilityHelp == "true");

    if (
      userId &&
      email &&
      authToken &&
      (isLogin === "1" || keepSignedIn === "1" || currDate <= authExpiration)
    ) {
      refreshGetUserPermissionsAPI();
      setloggedIn(true);

      // Leave breadcrumb
      Bugsnag.leaveBreadcrumb(
        "Login state change",
        {
          prevState: loggedIn,
          login: true,
          instance: "On load",
        },
        "state"
      );

      if (!isLogin) sessionStorage.setItem("isLogin", "1");
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/login"
      ) {
        navigate(routes.DASHBOARD);
      }
    }

    globalHistory.listen(({ location }) => {
      // Leave breadcrumb
      Bugsnag.leaveBreadcrumb(
        "Location change",
        {
          location,
        },
        "navigation"
      );

      const isLogin = sessionStorage.getItem("isLogin");

      if (loggedIn !== (isLogin === "1"))
        // Leave breadcrumb
        Bugsnag.leaveBreadcrumb(
          "Login state change",
          {
            prevState: loggedIn,
            login: isLogin === "1",
            instance: "On location change",
            location,
          },
          "state"
        );

      setloggedIn(isLogin === "1");

      setTitle(location.pathname);
      window.scrollTo(0, 0); //To solve the issue of scrolling while navigating - TBS

      // On url change, update last active session via API call
      updateLastActiveSession(3600000); // time in milliseconds
    });

    window.addEventListener("storage", (event) => {
      // handle case where localstorage is cleared somehow but sessionstorage isn't
      if (
        ["authToken", "authTokenExpiration"].includes(event.key) &&
        !event.newValue
      ) {
        if (sessionStorage.getItem("isLogin") === "1") {
          // Leave breadcrumb
          Bugsnag.leaveBreadcrumb(
            "Localstorage wipe out",
            {
              key: event.key,
              newValue: event.newValue,
              oldValue: event.oldValue,
            },
            "state"
          );

          // wipe out all data for proper logout
          clearLocalStorage();
          navigate(routes.LOGIN);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if the user is logged in, timezones list will be fetched once per session
  useEffect(() => {
    const timezones = sessionStorage.getItem("get_rails_timezone");
    if (
      loggedIn &&
      (!timezones || timezones === "undefined" || timezones === "null")
    ) {
      cacheTimezone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: config().GOOGLE_TAG_MANAGER_ID,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (
      callGetUserPermissionsAPI.hasFetched() &&
      callGetUserPermissionsAPI.hasErrors() === false
    ) {
      if (callGetUserPermissionsAPI.data()) {
        updateUserPermissions(callGetUserPermissionsAPI.data());
      }
    }
  }, [callGetUserPermissionsAPI.isFetching()]);

  useEffect(() => {
    // Integration of Microsoft Clarity - Dhaval's task
    if (process.env.REACT_APP_ENV !== "stage") addMicrosoftClarity();

    if (!isSafari && window.location.protocol == "https:") {
      import("./constant/firebase").then((something) => {
        requestFirebaseNotificationPermission =
          something.requestFirebaseNotificationPermission;
        setTimeout(() => {
          RequestFirebaseNotification();
        }, 3000);
      });
    }
  }, []);

  const RequestFirebaseNotification = () => {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        localStorage.setItem("firebaseToken", firebaseToken);
      })
      .catch((err) => {
        console.log("erroroor", err);
        return err;
      });
  };

  const setTitle = (path) => {
    let title = "Interstride";
    if (path) {
      let existData = path.split("/");
      if (existData && existData.length > 1) {
        const pathArray = without(existData, "");
        const titleName = pathArray[pathArray.length - 1];
        title = camelCase(
          titleName && titleName.length > 0 && titleName !== "noninvite"
            ? titleName === "forgotpassword"
              ? "Forgot-Password"
              : titleName === "resetpassword"
              ? "Reset-Password"
              : titleName
            : "Interstride"
        );
      }
    }

    document.title = title;
  };

  useEffect(() => {
    const handleRejection = (e) => {
      console.warn(`UNHANDLED PROMISE REJECTION: ${e.reason}`);
      e.preventDefault();
    };
    window.addEventListener("unhandledrejection", handleRejection);
    return () =>
      window.removeEventListener("unhandledrejection", handleRejection);
  }, []);

  const fallBackUI = (
    <div className="page-loader">
      <div className="">
        <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );

  const NoPageFound = () => {
    useEffect(() => {
      navigate(BASE_PATH);
    }, []);

    return <Login />;
  };

  /**
   * Important effect : 2
   * This effect will run after school permissions are set upon login
   * This effect will then check if redirectPath / page is permissible to access by user
   * This effect will then redirect to dashboard / saved route as per permission
   */
  useEffect(() => {
    if (loggedIn && !isEmpty(permissions)) {
      const redirectPath = getRedirectRouteFromURL();
      if (isPageAccessible(permissions, redirectPath)) {
        // Check request page permission
        navigate(redirectPath, { replace: true });
      } else {
        navigate(routes.DASHBOARD, { replace: true });
        toastify(
          "You do not have the necessary permissions to access this page."
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const initialEffect = useCallback(() => {
    // Get and save route params if present to be used after signup/login
    (() => {
      const routeParams = fetchRouteParamsFromURL();
      if (routeParams && !isEmpty(routeParams)) {
        dispatch(setSavedRoute(routeParams));
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* We need to save initial app load route for further redirection after login/signup, before any of the child components performs any redirection*/}
      <ParentEffect effect={initialEffect} />
      <AppVersionManager />
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <AppStateProvider containers={containers}>
            <ViewportProvider>
              <Suspense fallback={fallBackUI}>
                <Router>
                  {window.location.protocol == "https:" ? (
                    <>
                      <Login
                        RequestFirebaseNotification={
                          RequestFirebaseNotification
                        }
                        path={BASE_PATH}
                        loggedIn={loggedIn}
                      />
                      <Login
                        path={routes.LOGIN}
                        RequestFirebaseNotification={
                          RequestFirebaseNotification
                        }
                        loggedIn={loggedIn}
                      />
                    </>
                  ) : (
                    <>
                      <Login path={BASE_PATH} loggedIn={loggedIn} />
                      <Login path={routes.LOGIN} loggedIn={loggedIn} />
                    </>
                  )}

                  <Onboarding path={routes.ONBOARDING.PATH} />
                  <VerifySecondaryEmail path={routes.VERIFY_SECONDARY_EMAIL} />
                  <NoPageFound default />
                  <LinkedInPopUp exact path="/linkedin" />
                  <EditEmail path={routes.EDIT_EMAIL} />
                  <AmbassadorAcceptInvite
                    path={routes.AMBASSADOR_ACCEPT_INVITE}
                  />
                  <UnsubscribeNotifications
                    path={routes.NOTIFICATION.UNSUBSCRIBE}
                  />
                  <Widget path={routes.WIDGET} />
                  {loggedIn && (
                    <>
                      <Dashboard path={routes.DASHBOARD} />
                      <SavedPost path={routes.SAVED_POSTS} />
                      <BoostProfile path={routes.BOOST_PROFILE} />
                      <Jobs
                        path={routes.JOBS.PATH}
                        permissions={permissions}
                        studentViewPreference={studentViewPreference}
                      />
                      <Learn
                        path={routes.LEARN.PATH}
                        permissions={permissions}
                      />
                      <Network
                        path={routes.NETWORK.PATH}
                        permissions={permissions}
                      />
                      <Tools
                        path={routes.TOOLS.PATH}
                        permissions={permissions}
                      />
                      <ProfileModule path={routes.PROFILE.PATH} />
                      <Notification path={routes.NOTIFICATION.PATH} />
                      <HelpCenter path={routes.HELP.PATH} />
                      <PrivacyPolicy path={routes.PRIVACY_POLICY} />
                      <TermsAndConditions path={routes.TERMS_AND_CONDITIONS} />
                    </>
                  )}
                </Router>
                <ToastContainer
                  autoClose={5000}
                  style={{ width: "max-content" }}
                />
                <TutorialAmbassador />
                <WebsocketProvider loggedIn={loggedIn} />
                <Smartbanner />
              </Suspense>
            </ViewportProvider>
          </AppStateProvider>
        </StylesProvider>
      </ThemeProvider>
      {/* Chatbot should not appear on career widget */}
      {loggedIn &&
        !(window.location.pathname ?? "").includes("/university") && (
          <KommunicateChat />
        )}
    </>
  );
}

export default App;

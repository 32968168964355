import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";

import CareerGamePlanMiddleStyles from "./CareerGamePlanMiddleStyles";

const CareerGamePlanMiddle = ({ categoryList = [] }) => {
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <CareerGamePlanMiddleStyles>
      <h2 className="title-primary mb-15">Tips to improve</h2>
      {categoryList.length > 0 &&
        categoryList.map((category, index) => (
          <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`category-${index}`}
              aria-label={category.category_name}
            >
              <Typography className="header-primary">
                {category.category_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="career-body">
                <p>{category.category_content}</p>
                <ul>
                  {(category.resources && category.resources !== null
                    ? category.resources
                    : []
                  ).map((resource, i) => (
                    <li
                      key={`category-${index}-resource-${i}`}
                      dangerouslySetInnerHTML={{
                        __html: resource,
                      }}
                    />
                  ))}
                </ul>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
    </CareerGamePlanMiddleStyles>
  );
};

export default CareerGamePlanMiddle;

import React from "react";
import { InfoPanel } from "../../../InfoPanel/InfoPanel";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import EmptyListWithImage from "../../../EmptyListWithImage/EmptyListWithImage";
import Image from "../../../../assets/svg/discover_no_people.svg";
import JobsListingEffect from "../../../effects/JobsListingEffect/JobsListingEffect";
import usePersistState from "../../../../state/usePersistState";

const DiscoverPeople = ({
  networks = [],
  isFetching = false,
  activePanelIndex,
  handleView,
  handleMessage,
  handleClose,
  handleFavorite,
  selectedUserData = false,
  userId = null,
  networkData, ///////// Due to existing blunder, I have to add an extra condition to render data correctly as per the response first
}) => {
  const { favoriteUsers = [] } = usePersistState();

  return (
    <>
      <div className="people-container">
        {isFetching || !networkData ? (
          <JobsListingEffect count={8} />
        ) : networks.length > 0 ? (
          networks.map((item, index) => (
            <InfoPanel
              variant="discover-network"
              key={index}
              index={index}
              avatar={item.user_profile}
              userAvatar={true}
              title={item.full_name}
              subTitle={[`${item.major}`, `${item.graduation_year}`]}
              tags={item?.user_types || []}
              isFavorites={favoriteUsers.includes(item.id) ? true : false}
              onClick={(e) => handleFavorite(e, item.id)}
              onPanelClick={(e) =>
                activePanelIndex === item.id
                  ? handleClose(e)
                  : handleView(e, item.id)
              }
              intro={
                item.country_image !== "" && (
                  <>
                    <div className="c-flag">
                      <img
                        src={item.country_image}
                        height="15px"
                        width="22px"
                        alt={`Flag of ${item.country_of_origin}`}
                      />
                    </div>
                    <div style={{ marginLeft: "8px" }}>
                      {item.country_of_origin}
                    </div>
                  </>
                )
              }
              isActive={
                activePanelIndex === item.id ||
                (selectedUserData &&
                  selectedUserData === true &&
                  item.id === userId)
              }
              action={
                <>
                  <ButtonCustom
                    height={30}
                    width={100}
                    color="secondary"
                    className={
                      activePanelIndex === item.id ? "focus-white" : ""
                    }
                    onClick={(e) => {
                      handleMessage(e, item);
                    }}
                    aria-label={`Message ${item.full_name}`}
                  >
                    Message
                  </ButtonCustom>
                </>
              }
              marginTop="0px"
            />
          ))
        ) : (
          <EmptyListWithImage
            Image={Image}
            title={"Looks like there are no people in this search."}
            subTitle={
              "Try removing some filters to expand your search and discover other people!"
            }
            containerClassname={"empty-discover-people"}
          />
        )}
      </div>
    </>
  );
};

export default DiscoverPeople;

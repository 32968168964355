import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./YouAllSet.module.scss";
import FinalIcon from "../../../assets/svg/FinalIcon";
import { CoverHeader } from "../../atoms/CoverHeader/CoverHeader";
import ViewIcon from "../../../assets/svg/ViewIcon";
import CustomSwitch from "../../CustomSwitch/CustomSwitch";
import { useState } from "react";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import InformationIcon from "../../../assets/svg/InformationIcon";
import { useAppState } from "../../../context";
import CustomDialog from "../../CustomDialog/CustomDialog";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";

const cx = createModuleStyleExtractor(styles);

export const ProfileFinish = () => {
  const { profile, updateProfileState } = useAppState("profile");
  const { accessibility_help, is_network_popup_shown } = profile || {};
  //to show profile popup
  const [show, setShow] = useState(false);

  const [visibleNetwork, setIsVisibleNetwork] = useState(
    is_network_popup_shown
  );
  const [visibleNetworkPopup, setIsVisibleNetworkPopup] = useState(false);

  const [isAccessible, setIsAccessible] = useState(accessibility_help);
  const [isAccessiblePopup, setIsAccessiblePopup] = useState(false);

  const handleChangeAccessiblity = () => {
    setIsAccessible(!isAccessible);
    updateProfileState({ accessibility_help: !accessibility_help });
  };
  const handlePopup = () => {
    setIsAccessiblePopup(false);
    setIsVisibleNetworkPopup(false);
  };

  return (
    <div className={cx(["o-final-container"])}>
      <ProfilePreview
        defaultState={show}
        handleClose={() => setShow(false)}
        tab="student"
      />
      {(visibleNetworkPopup || isAccessiblePopup) && (
        <CustomDialog
          open={visibleNetworkPopup || isAccessiblePopup}
          title={visibleNetworkPopup ? "Visibility" : "Accessibility help?"}
          subTitle={
            visibleNetworkPopup
              ? "Hide your visibility from other users"
              : "It is our mission to be inclusive and offer equal opportunities to all. We offer a customized version of the platform for those with accessibility needs."
          }
          handleClose={handlePopup}
          className="signup-accessibility-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="got-it-button">
              <ButtonCustom onClick={handlePopup}>Got It!</ButtonCustom>
            </div>
          </div>
        </CustomDialog>
      )}
      <div className={cx("o-final-container__header")}>
        <h3>You’re all set!</h3>
        <FinalIcon />
      </div>
      <CoverHeader>
        <div className={cx("o-final-container__switch")}>
          <div className={cx("o-final-container__switch__inside")}>
            <div className={cx("o-final-container__switch__inside__toggle")}>
              <CustomSwitch
                label="Profile visibility to network"
                handleChange={() => setIsVisibleNetwork(!visibleNetwork)}
                checked={visibleNetwork}
              />
            </div>
            <p className={cx("o-final-container__switch__inside__label")}>
              Profile visibility to network
            </p>
            <div className={cx("o-final-container__switch__inside__info")}>
              <InformationIcon onClick={() => setIsVisibleNetworkPopup(true)} />
            </div>
          </div>
          <div className="mt-10 mb-10" />
          <div className={cx("o-final-container__switch__inside")}>
            <div className={cx("o-final-container__switch__inside__toggle")}>
              <CustomSwitch
                label="Enable accessibility mode"
                handleChange={handleChangeAccessiblity}
                checked={isAccessible}
              />
            </div>
            <p className={cx("o-final-container__switch__inside__label")}>
              Enable accessibility mode
            </p>
            <div
              className={cx("o-final-container__switch__inside__info", "mt-20")}
            >
              <InformationIcon onClick={() => setIsAccessiblePopup(true)} />
            </div>
          </div>
          <div className="mt-10 mb-10" />
          <div
            className={cx("o-final-container__switch__preview")}
            onClick={() => setShow(true)}
          >
            <div className={cx("o-final-container__switch__preview__icon")}>
              <ViewIcon />
            </div>
            <span className={cx("o-final-container__switch__preview__label")}>
              Show what my profile looks like to my network
            </span>
          </div>
        </div>
      </CoverHeader>
    </div>
  );
};

import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

/** -----------------------------NETWORK DASHBOARD PAGE APIS------------------------------------- */
/** Network Dashboard Page APIS */
export const getRecommendedNetworksAPI = ({
  country = "",
  school = "",
  account_type = "",
  degree = "",
  major = "",
  graduation_year = "",
  interest = "",
} = {}) => {
  return axiosInstance
    .get(
      `network_modules/recommended_network_v1?country=${country}&department_ids[]=${school}&account_type=${
        account_type === "All" ? "" : account_type
      }&degree=${degree}&major=${major}&graduation_year=${graduation_year}&interest=${interest}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getFavoriteNetworksAPI = () => {
  return axiosInstance
    .get(`network_modules/favourites_list`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const favorite_Unfavorite_NetworksAPI = (userId) => {
  return axiosInstance
    .post(
      `network_modules/add_or_remove_favourites`,
      { favourite_user_id: userId },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getUserDetailsAPI = (userID) => {
  return axiosInstance
    .get(`users/${userID}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getRecommendedTopicsAPI = () => {
  return axiosInstance.get(`topics`, headers_with_token()).then((res) => {
    return res.data;
  });
};

export const followUnfollowTopicAPI = (topicID) => {
  return axiosInstance
    .post(
      `topics/follow_unfollow_topic`,
      { topic_id: topicID },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getTopicDetailsAPI = (topicId) => {
  return axiosInstance
    .get(`topics/${topicId}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** -----------------------------DICSOVER NETWORK PAGE APIS------------------------------------- */

/** Discover Network Page APIS */
export const getNetworkDiscoverFiltersAPI = () => {
  return axiosInstance
    .get(`network_modules/discover_search_list`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getDiscoverNetWorkPeopleAPI = ({
  country = "",
  school = "",
  account_type = "",
  degree = "",
  major = "",
  graduation_year = "",
  interest = "",
}) => {
  return axiosInstance
    .get(
      `network_modules/discover_network?country=${country}&department_ids[]=${school}&account_type=${
        account_type === "All" ? "" : account_type
      }&degree=${degree}&major=${major}&graduation_year=${graduation_year}&interest=${interest}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

/** -----------------------------NETWORK MESSAGE PAGE APIS------------------------------------- */

/** Get Messages left side section */
export const getChatUsersAPI = (searchObj) => {
  return (
    axiosInstance
      // .get(`network_modules/messages`, headers_with_token())
      .get(
        `network_modules/messages/?user_search=${
          searchObj ? searchObj.text : ""
        }`,
        headers_with_token()
      )
      .then((res) => {
        return res.data;
      })
  );
};

/**Used in New chat or New Group sections */
export const getSuggestedChatUsersAPI = (obj) => {
  return axiosInstance
    .get(
      `network_modules/users_list?user_name=${obj.user_name}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

/** Get chat between users or groups */
export const getChatMessagesAPI = (conversation_id) => {
  return axiosInstance
    .get(
      `network_modules/show_messages?conversation_id=${conversation_id}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

/** mute / Un-mute chat */
export const muteUnmuteChatAPI = (conversation_id) => {
  return axiosInstance
    .get(
      `network_modules/mute_chat?conversation_id=${conversation_id}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};
/** Delete chat between user or group */
export const deleteChatAPI = (conversation_id) => {
  return axiosInstance
    .get(
      `network_modules/leave_or_delete_chat?conversation_id=${conversation_id}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

/** Report chat*/
export const reportChatAPI = (reportData) => {
  return axiosInstance
    .post(`network_modules/report_chat`, reportData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

// Create new group
export const createNewGroupAPI = (payload) => {
  return axiosInstance
    .post(
      `network_modules/create_or_update_group`,
      payload,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

/** Send Message in Personal Or Group Chat */
export const sendMessageAPI = (payload) => {
  return axiosInstance
    .post(`network_modules/send_message`, payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

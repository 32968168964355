import React from "react";
import { Router } from "@reach/router";
import TermsAndConditionsDB from "./TermsAndConditionsDB/TermsAndConditionsDB";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Container from "../../components/Container/Container";

const TermsAndConditions = () => {
  return (
    <MainLayout title="Terms and Conditions" withSubHeader={false}>
      <Container>
        <Router>
          <TermsAndConditionsDB path="/" />
        </Router>
      </Container>
    </MainLayout>
  );
};

export default TermsAndConditions;

import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import _debounce from "lodash/debounce";
import { ShimmerThumbnail } from "react-shimmer-effects";

import ServiceMiddle from "../../../components/Learn/Service/ServiceMiddle/ServiceMiddle";
import SavedServices from "../../../components/Learn/Comman/SavedServices/SavedServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  getCategoriesAPI,
  getStudentServicesAPI,
  subscribeCategoryAlertAPI,
  getSavedServicesAPI,
  getCategoryByServiceId,
} from "../../../services/learnServices";
import { toastify } from "../../../helper/helper";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { createModuleStyleExtractor } from "../../../utils/css";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import styles from "./StudentServices.module.scss";
import { Mixpanel } from "../../../mixpanel";
import { trackClickForAnalyticsAPI } from "../../../services/commonServices";
import usePersistState from "../../../state/usePersistState";

import savePinIcon from "../../../assets/svg/save-pin.svg";
import arrowRight from "../../../assets/svg/arrow-right.svg";
import searchBtnRound from "../../../assets/svg/search-btn-round.svg";
import backArrowV2 from "../../../assets/svg/back-arrow-v2.svg";
import IllustrationNoUser from "../../../assets/svg/Illustration-no-user.svg";
const cx = createModuleStyleExtractor(styles);

const StudentServices = () => {
  const params = useQueryParams();
  const { users } = usePersistState();

  const [tab, setTab] = useState("listing"); // Possible Options 'listing' and 'saved'
  const [searchText, setSearchText] = useState("");
  const [categories, setCategories] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [savedServiceList, setSavedServiceList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showPaginationLoader, setShowPaginationLoader] = useState(false);
  const [viewed, setViewed] = useState([]);

  const [callingGetCategoriesAPI, refreshGetCategoriesAPI] =
    usePromise(getCategoriesAPI);
  const [callingSubscribeCategoryAlertAPI] = usePromise(
    subscribeCategoryAlertAPI
  );
  const [callingGetSavedServicesAPI, refreshGetSavedServicesAPI] =
    usePromise(getSavedServicesAPI);
  const [, refreshTrackClickForAnalyticsAPI] = usePromise(
    trackClickForAnalyticsAPI
  );

  useEffect(() => {
    refreshGetCategoriesAPI();
    refreshGetSavedServicesAPI();
  }, []);

  const refreshGetStudentServicesAPI = async (id, page = 1, options = {}) => {
    const response = await getStudentServicesAPI(id, page, 10, options);
    if (response.data) {
      setServiceList([
        ...(page > 1 ? serviceList : []),
        ...response.data.deals,
      ]);
      setTotalPages(response.data.total_pages);
    }
    setShowLoader(false);
    setShowPaginationLoader(false);
  };

  useEffect(() => {
    if (
      callingGetCategoriesAPI.hasFetched() &&
      callingGetCategoriesAPI.hasErrors() === false &&
      callingGetCategoriesAPI.data() &&
      callingGetCategoriesAPI.data().data
    ) {
      const categories = callingGetCategoriesAPI.data().data.categories;
      if (categories && categories.length > 0) {
        setCategories(categories);
        const notificationId = params.get("notificationId");
        if (notificationId) {
          return;
        }
        getServiceData(categories[0]);
      } else {
        getServiceData([]);
      }
    }
  }, [callingGetCategoriesAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetSavedServicesAPI.hasFetched() &&
      callingGetSavedServicesAPI.hasErrors() === false
    ) {
      setSavedServiceList(
        callingGetSavedServicesAPI.data().data.favorite_deals
      );
    }
  }, [callingGetSavedServicesAPI.isFetching()]);

  useEffect(() => {
    if (
      callingSubscribeCategoryAlertAPI.hasFetched() &&
      callingSubscribeCategoryAlertAPI.hasErrors() === false
    ) {
      if (
        callingSubscribeCategoryAlertAPI.data() &&
        callingSubscribeCategoryAlertAPI.data().message
      )
        toastify("success", callingSubscribeCategoryAlertAPI.data().message);
      setShowLoader(true);
      refreshGetStudentServicesAPI(selectedCategory.id);
    }
  }, [callingSubscribeCategoryAlertAPI.isFetching()]);

  const getServiceData = (data) => {
    if (data.length == 0) {
      setSelectedCategory([]);
      setCategories([]);
      setServiceList([]);
    } else {
      setSelectedCategory(data);
      setShowLoader(true);
      const doNormalOperation = () => refreshGetStudentServicesAPI(data.id, 1);
      if (params.has("notificationId")) {
        const notificationId = params.get("notificationId");
        //if it is not already viewed
        if (!viewed.includes(notificationId)) {
          refreshGetStudentServicesAPI(data.id, 1, {
            service_id: notificationId,
          });
          setViewed([...viewed, notificationId]);
        } else {
          //if viewed then do normal operation
          doNormalOperation();
        }
      } else {
        doNormalOperation();
      }

      setCurrentPage(1);
    }
  };

  const getPaginationData = () => {
    setShowPaginationLoader(true);
    refreshGetStudentServicesAPI(selectedCategory.id, currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  const getSearchServicesData = _debounce(() => {
    refreshGetCategoriesAPI(searchText);
  }, 500);

  useEffect(() => {
    if (params.has("notificationId")) {
      const notificationId = params.get("notificationId");
      if (!notificationId) return;
      if (categories.length === 0) return;
      if (viewed.includes(notificationId)) return;
      getCategoryByServiceId(notificationId).then((item) => {
        if (item && item.data) {
          const categoryId = item.data.id; //get orginal id
          const indexOfCategory = categories.findIndex(
            (item) => item.id === parseInt(categoryId)
          ); // get the index of category from array
          indexOfCategory !== -1 && getServiceData(categories[indexOfCategory]);
        }
      });
    }
  }, [params, categories]);

  const handleClearSearch = () => {
    setSearchText("");
    refreshGetCategoriesAPI("");
  };

  return (
    <div className={cx("student-services")}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          {tab === "saved" && (
            <div className={cx("student-services__listing-services")}>
              <a
                href
                className={cx("student-services__listing-services__button")}
                onClick={(e) => {
                  e.preventDefault();
                  setTab("listing");
                }}
              >
                <img src={backArrowV2} alt="back-arrow" />
                <span>Back to Student Services</span>
              </a>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <div className={cx("student-services__title")}>
            <h2>
              {tab === "listing"
                ? "Explore our Student Services"
                : "Your Saved Services"}
            </h2>
          </div>
        </Grid>
        {tab === "listing" && (
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className={cx("student-services__saved-services")}>
              <div
                className={cx("student-services__saved-services__button")}
                onClick={() => setTab("saved")}
              >
                <img src={savePinIcon} alt="save-pin" />
                <span>
                  Your saved services{" "}
                  {savedServiceList.length > 0
                    ? "(" + savedServiceList.length + ")"
                    : ""}
                </span>
                <img src={arrowRight} alt="arrow-right" />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {tab === "listing" && (
        <>
          <div className={cx("student-services__title-search-spacer")} />
          <Grid container spacing={2}>
            <Grid item xs={0} sm={3} md={3} lg={3} xl={3} />
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className={cx("student-services__search")}>
                <TextboxCustom
                  value={searchText}
                  name="search"
                  placeholder="Search keyword"
                  lableaignment="left"
                  onChange={(e) => setSearchText(e.target.value.trim())}
                  onEnterKey={() => getSearchServicesData()}
                />
                <a
                  href
                  onClick={(e) => {
                    e.preventDefault();
                    getSearchServicesData();
                  }}
                  className={cx("student-services__search__button")}
                >
                  <img src={searchBtnRound} alt="search-btn" />
                </a>
                {searchText.length > 0 && (
                  <a
                    href
                    onClick={(e) => {
                      e.preventDefault();
                      handleClearSearch();
                    }}
                    className={cx("student-services__search__clear")}
                  >
                    <span>Clear all</span>
                  </a>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} />
          </Grid>
        </>
      )}
      <div className={cx("student-services__search-deals-spacer")} />
      {tab === "listing" ? (
        <Grid container spacing={4}>
          {callingGetCategoriesAPI.isFetching() ? (
            <>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <div className={cx("student-services__categories--loader")}>
                  {[...Array(8)].map((_, index) => (
                    <ShimmerThumbnail height={50} key={index} />
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                <div className={cx("student-services__listing--loader")}>
                  {[...Array(9)].map((_, index) => (
                    <ShimmerThumbnail width={267} height={345} key={index} />
                  ))}
                </div>
              </Grid>
            </>
          ) : categories.length > 0 ? (
            <>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <div className={cx("student-services__categories--wrapper")}>
                  <div className={cx("student-services__categories")}>
                    {categories.map((data, index) => (
                      <a
                        href
                        className={cx([
                          "student-services__categories__item",
                          data.id === selectedCategory.id
                            ? "student-services__categories__item__active"
                            : "",
                        ])}
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          getServiceData(data);

                          refreshTrackClickForAnalyticsAPI(
                            "deal_category",
                            selectedCategory.id,
                            users.school_id
                          );

                          Mixpanel.track("Student Services Category clicked", {
                            id: selectedCategory.id,
                          });
                        }}
                      >
                        <div
                          className={cx(
                            "student-services__categories__item__image-wrapper"
                          )}
                        >
                          <img src={data.logo_url} alt="logo" />
                        </div>
                        <span>{data.name}</span>
                      </a>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                <ServiceMiddle
                  serviceList={serviceList}
                  refreshSavedServices={() => {
                    refreshGetSavedServicesAPI();
                  }}
                  savedServiceList={savedServiceList}
                  showLoader={
                    showLoader || callingGetCategoriesAPI.isFetching()
                  }
                  currentPage={currentPage}
                  totalPages={totalPages}
                  fetchMoreData={getPaginationData}
                  showPaginationLoader={showPaginationLoader}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={cx("student-services__no-data-placeholder")}>
                <img src={IllustrationNoUser} alt="no-services" />
                <h2>Looks like there are no services with this search.</h2>
                <h3>Try expanding your search to discover other services!</h3>
              </div>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SavedServices
              savedServiceList={savedServiceList}
              refreshSavedServices={() => {
                refreshGetSavedServicesAPI();
              }}
              showLoader={callingGetSavedServicesAPI.isFetching()}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default StudentServices;

import React from "react";
import { navigate } from "@reach/router";

import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import profileArrowIcon from "../../../../assets/svg/f-arrow-profile-arrow-icon.svg";
import networkWithSomeone from "../../../../assets/png/f-network-with-someone-step2.png";
import { routes } from "../../../../routes";


const NetworkWithSomeone = () => {
  return (
    <>
      <div className="status-wrapper">
        <div className="status-title text-primary">How to do it</div>
        <div className="status-sub-title">
          Go to the Network portal, click Discover, and then choose someone to
          connect with by clicking on the Message button.
        </div>
        <div className="status-box d-flex justify-content-center align-items-center nt-someone pos-relative">
          <img
            src={profileArrowIcon}
            className="network-arrow"
            alt="Down Arrow"
          />
          <img
            className="steps-img step2"
            src={networkWithSomeone}
            alt="Network with someone"
          />
        </div>
        <div className="mandetory-note">
          This will take you to the Messaging portal, where you can also create
          group chats with other members.
        </div>
        <ButtonCustom
          onClick={() => navigate(routes.NETWORK.DISCOVER)}
          width={250}
          height={60}
          style={{ marginTop: "20px" }}
        >
          Start Networking
        </ButtonCustom>
      </div>
    </>
  );
};

export default NetworkWithSomeone;

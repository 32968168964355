import React, { useState, memo, useEffect } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Badge } from "../Badge/Badge";
import styles from "./Accordian.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { PRIMARY_COLOR } from "../../../styles/variables";

const cx = createModuleStyleExtractor(styles);

const Accordian = ({
  label,
  children,
  completed = false,
  state,
  handleOnChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(state || false);

  const handleChange = () => {
    let section;
    if (label === "Education") section = 1;
    else if (label === "Career") section = 2;
    else if (label === "Experience") section = 3;
    else section = 0;
    setIsExpanded(!isExpanded);
    handleOnChange(section);
  };

  useEffect(() => {
    setIsExpanded(state);
  }, [state]);
  return (
    <ExpansionPanel
      expanded={isExpanded}
      onChange={handleChange}
      id={`profile-${label}`}
      className={"profile-accordian-mui"}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon style={{ color: PRIMARY_COLOR }} />}
        aria-controls={`${label}-summary`}
        aria-label={`${label} ${completed ? "Completed" : "Incomplete"}`}
        classes={{
          focused: "focused-class",
        }}
      >
        <div className={cx("accordian-title-wrapper")}>
          <div className={cx("accordian-title-wrapper__inside")}>
            <h2 className="title-primary">{label}</h2>
            <Badge complete={completed} />
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="profile-accordian-mui__details">
        <div
          className="profile-accordian-mui__details-child"
          style={{ padding: "0 20px 0 20px" }}
        >
          {children}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
export default memo(Accordian);

import React, { useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./MyProfile.module.scss";
import CustomSwitch from "../../CustomSwitch/CustomSwitch";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import { useAppState } from "../../../context";
import CustomDialog from "../../CustomDialog/CustomDialog";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { useUpdateProfile } from "../../../hooks/Profile/useUpdateProfile";
import {
  ACCESSIBILITY_SECONDARY_COLOR,
  SECONDARY_COLOR,
} from "../../../styles/variables";
import ProfilePopup from "../ProfilePopup/ProfilePopup";

import InformationIcon from "../../../assets/svg/InformationIcon";
import ViewIcon from "../../../assets/svg/ViewIcon";
import { isUndefined } from "lodash";

const cx = createModuleStyleExtractor(styles);

export const ProfileCollapseFooter = ({ tab = "student" }) => {
  const { handleAccessibilitySave, handleVisibilitySave } = useUpdateProfile();
  const { profile, updateProfileState } = useAppState("profile");
  const { accessibility_help, is_network_popup_shown } = profile || {};

  const [showProfile, setShowProfile] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [visibleNetwork, setIsVisibleNetwork] = useState(
    is_network_popup_shown
  );
  const [isAccessible, setIsAccessible] = useState(accessibility_help);
  const [visibleNetworkElement, setVisibleNetworkElement] = useState(null);

  const handleChangeAccessiblity = () => {
    setIsAccessible(!isAccessible);
    localStorage.setItem("accessibilityHelp", !accessibility_help);
    updateProfileState({ accessibility_help: !accessibility_help });
    handleAccessibilitySave(!accessibility_help);
    setShowConfirmationPopup(false);
  };
  const handleChangeVisibility = () => {
    setIsVisibleNetwork(!visibleNetwork);
    updateProfileState({ is_network_popup_shown: !is_network_popup_shown });
    handleVisibilitySave(!is_network_popup_shown, !visibleNetwork);
  };

  const handleCloseAccessibilityPopup = (e) => {
    if (!isUndefined(e)) {
      e.preventDefault();
    }
    setShowConfirmationPopup(null);
  };

  const handleClose = () => {
    setVisibleNetworkElement(null);
  };

  return (
    <div className={cx("o-final-container")}>
      <ProfilePreview
        defaultState={showProfile}
        handleClose={() => setShowProfile(false)}
        tab={tab}
      />
      {showConfirmationPopup && (
        <CustomDialog
          id="accessibility-popup"
          open={showConfirmationPopup}
          title={
            isAccessible
              ? "Do you want to disable accessibility mode?"
              : "Do you want to enable accessibility mode?"
          }
          subTitle="At Interstride, it’s our mission to be inclusive and offer equal opportunities to all. We offer a customized version of the platform for those with accessibility needs."
          handleClose={(e) => handleCloseAccessibilityPopup(e)}
          className="signup-accessibility-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="btn">
              <ButtonCustom
                width="238"
                height={50}
                onClick={() => handleChangeAccessiblity()}
              >
                Yes
              </ButtonCustom>
            </div>
            <a
              href="/#"
              className="text-grey cursor-pointer link-focus"
              style={{ marginTop: "30px" }}
              onClick={(e) => handleCloseAccessibilityPopup(e)}
              aria-label="No thanks"
            >
              No thanks
            </a>
          </div>
        </CustomDialog>
      )}
      <div className={cx("o-final-container__footer")}>
        <div className={cx("o-final-container__footer__left")}>
          {tab === "student" && (
            <div className={cx("o-final-container__footer__left__visibility")}>
              <span
                className={cx(
                  "o-final-container__footer__left__visibility__label"
                )}
              >
                Profile visible to network
              </span>
              <CustomSwitch
                label="Profile visibility to network"
                handleChange={handleChangeVisibility}
                checked={visibleNetwork}
                id="profile-visibility"
              />
              <a
                href="/#"
                className={cx(
                  "o-final-container__footer__left__visibility__info"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleNetworkElement(e.currentTarget);
                }}
              >
                <InformationIcon
                  color={
                    accessibility_help
                      ? ACCESSIBILITY_SECONDARY_COLOR
                      : SECONDARY_COLOR
                  }
                  titleText={"Hide your visibility from other users"}
                />
              </a>
            </div>
          )}

          <div className="ambassador-profile-tutorial-two">
            <a
              href="#"
              className={cx("o-final-container__footer__left__preview")}
              onClick={(e) => {
                e.preventDefault();
                setShowProfile(!showProfile);
              }}
            >
              <span
                className={cx(
                  "o-final-container__footer__left__preview__label"
                )}
              >
                Preview profile
                <ViewIcon />
              </span>
            </a>
          </div>
        </div>
        <div className={cx("o-final-container__footer__right")}>
          {tab === "student" && (
            <div
              className={cx("o-final-container__footer__right__accessibility")}
            >
              <div
                className={cx([
                  "o-final-container__footer__right__accessibility__label",
                  isAccessible
                    ? "o-final-container__footer__right__accessibility__label--active"
                    : "",
                ])}
              >
                <span>Accessibility mode</span>
              </div>
              <CustomSwitch
                label="Enable accessibility mode"
                handleChange={() => {
                  setShowConfirmationPopup(true);
                }}
                isPopupOpen={showConfirmationPopup}
                setShowConfirmationPopup={setShowConfirmationPopup}
                checked={isAccessible}
                hasModel={true}
                id="profile-accessibility"
              />
            </div>
          )}
        </div>
        <ProfilePopup
          anchorEl={visibleNetworkElement}
          onClose={handleClose}
          open={Boolean(visibleNetworkElement)}
          text="Hide your visibility from other users"
        />
      </div>
      <div className={cx("o-final-container__foot-note")}>
        <span>All mandatory fields are marked with an asterisk (*).</span>
      </div>
    </div>
  );
};

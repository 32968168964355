import React, { Fragment, useState } from "react";

import CustomProgressBar from "../../components/CustomProgressBar/CustomProgressBar";
import { Career } from "../../components/ProfileModule/Career/Career";
import { ProfileEducation } from "../../components/ProfileModule/Educations/StepEducation/Education";
import { Experience } from "../../components/ProfileModule/Experience/Experience";
import { FooterActions } from "../../components/ProfileModule/FooterActions/FooterActions";
import { PersonalForm } from "../../components/ProfileModule/Personal/PersonalForm/PersonalForm";
import { ProfileFinish } from "../../components/ProfileModule/YouAllSet/YouAllSet";
import { useAppState } from "../../context";
import { useUpdateProfile } from "../../hooks/Profile/useUpdateProfile";
import { useUpdateUpdateIndividualProfile } from "../../hooks/Profile/useUpdateUpdateIndividualProfile";
import { ACCESSIBILITY_SECONDARY_COLOR } from "../../styles/variables";

import { isStaffAccountType } from "../../utils/common";
const Stepform = () => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { profile, personalForm, setProfileInformation, setTouchedSections } =
    useAppState("profile");
  const { handleSave, loading: profileUpdating } = useUpdateProfile();
  const { current_step, isProfileCompleted } = profile || {};
  const {
    updatePersonal,
    updateEducation,
    updateExperience,
    updateCareer,
    loading,
  } = useUpdateUpdateIndividualProfile();

  const { account_type, user_type } = personalForm || {};
  const [activeProfileStep, setActiveProfileStep] = useState(
    isStaffAccountType(account_type, user_type, true) && current_step !== 0
      ? 4
      : current_step
  );

  const onNext = async () => {
    if (activeProfileStep === 4 && !isProfileCompleted) {
      handleSave();
      return;
    }
    //If filled personal
    if (activeProfileStep === 0) {
      setTouchedSections({personal: true})
      const valid = await updatePersonal();
      if (!valid) return;
      if (isStaffAccountType(account_type, user_type, true)) {
        setActiveProfileStep(4);
        return;
      }
    }
    if (activeProfileStep === 1) {
      setTouchedSections({education: true})
      const valid = await updateEducation();
      if (!valid) return;
    }

    if (activeProfileStep === 2) {
      const valid = await updateCareer();
      if (!valid) return;
    }

    if (activeProfileStep === 3) {
      const valid = await updateExperience();
      if (!valid) return;
    }
    if (activeProfileStep < 4) setActiveProfileStep(activeProfileStep + 1);

    if (activeProfileStep < 4) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const onPrev = () => {
    if (
      isStaffAccountType(account_type, user_type, true) &&
      activeProfileStep === 4
    ) {
      setActiveProfileStep(0);
      return;
    }
    if (activeProfileStep > 0) setActiveProfileStep(activeProfileStep - 1);
    setProfileInformation("current_step", activeProfileStep - 1);
  };
  return (
    <Fragment>
      <h1 className="f-main-title">My Profile</h1>

      <div className="profile-step-form-progressbar">
        {
          isStaffAccountType(account_type, user_type, false) ? (
            <CustomProgressBar
              stepLabels={  [
                "Personal",
                "Education",
                "Career",
                "Experience",
                "You're all set!",
              ]}
              width={"100%"}
              height={26}
              percent={(activeProfileStep + 1) * (100 / 5)}
              totalStep={5}
              currentStep={activeProfileStep + 1}
              isDisplayLabel={true}
              filledBackground={
                accessibilityHelp == "true"
                  ? ACCESSIBILITY_SECONDARY_COLOR
                  : "var(--primary-main-color)"
              }
            />
          ) : (
            <CustomProgressBar
              stepLabels={  [
                "Personal",
                "You're all set!",
              ]}
              customClass="staffProgressBar"
              percent={(activeProfileStep + 1) * (100 / 2)}
              totalStep={2}
              currentStep={activeProfileStep + 1}
              isDisplayLabel={true}
              filledBackground={
                accessibilityHelp == "true"
                  ? ACCESSIBILITY_SECONDARY_COLOR
                  : "var(--primary-main-color)"
              }
            />
          )
        }
      </div>
      {activeProfileStep === 0 && <PersonalForm />}
      {isStaffAccountType(account_type, user_type, false) && (
        <>
          {activeProfileStep === 1 && <ProfileEducation />}
          {activeProfileStep === 2 && <Career />}
          {activeProfileStep === 3 && <Experience />}
        </>
      )}

      {activeProfileStep === 4 && <ProfileFinish />}

      <FooterActions
        prev={activeProfileStep > 0}
        next={true}
        last={activeProfileStep === 4}
        onNext={onNext}
        onPrev={onPrev}
        loading={loading || profileUpdating}
      />
    </Fragment>
  );
};
export default Stepform;

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";

import ChecklistStyles from "./ChecklistStyles";
import ArrowIcon from "../../../assets/svg/down-arrow.svg";
import Image from "../../../assets/svg/empty_checklist.svg";
import plusIcon from "../../../assets/svg/f-plus.svg";
import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import { getChecklistCategories } from "../../../services/ChecklistServices";

import ChecklistLeftSidebar from "../../../components/Tools/Checklist/ChecklistLeftSidebar/ChecklistLeftSidebar";
import ChecklistRightSidebar from "../../../components/Tools/Checklist/ChecklistRightSidebar/ChecklistRightSidebar";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";

const Checklist = () => {
  const {
    setAddNewChecklist,
    setChecklistCategories,
    checklist,
    checkListCategories,
    selectedCategoriesIndex,
  } = useAppState("tools");

  const [openFilter, toggleFilter] = useState(false);

  const [callgetChecklistCategories, refreshgetChecklistCategories] =
    usePromise(getChecklistCategories);

  useEffect(() => {
    getChecklistCategoriesAPI();
  }, []);

  useEffect(() => {
    if (selectedCategoriesIndex !== null) toggleFilter(false);
  }, [selectedCategoriesIndex]);

  useEffect(() => {
    if (
      callgetChecklistCategories.hasFetched() &&
      callgetChecklistCategories.hasErrors() === false &&
      callgetChecklistCategories.data() &&
      callgetChecklistCategories.data().data
    ) {
      setChecklistCategories(callgetChecklistCategories.data().data);
    }
  }, [callgetChecklistCategories.isFetching()]);

  const getChecklistCategoriesAPI = () => {
    refreshgetChecklistCategories();
  };

  if (callgetChecklistCategories.isFetching()) {
    return <JobsListingEffect count={10} />;
  }
  return (
    <ChecklistStyles>
      <div className="tools-checklist">
        <h2 className="f-main-title mb-15">Checklist</h2>
        <Grid container spacing={2}>
          {isEmpty(checkListCategories) && isEmpty(checklist) ? (
            <Grid item xs={12}>
              <EmptyListWithImage
                Image={Image}
                title={"Looks like there are no tasks in this checklist"}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={3}>
                <div
                  className={`category-list collapsible-view ${
                    openFilter ? "open" : ""
                  }`}
                >
                  <h2 className="title-primary mb-15">
                    Checklists categories
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleFilter(!openFilter);
                      }}
                      className="collapse-icon arrow"
                    >
                      <img src={ArrowIcon} alt="Arrow Icon" />
                    </a>
                  </h2>

                  <ChecklistLeftSidebar />
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className="categories-header-with-button mb-15">
                  <h2 className="title-primary">
                    {checkListCategories[selectedCategoriesIndex].category_name}
                  </h2>
                  <ButtonCustom
                    className="shadow-btn"
                    onClick={() => setAddNewChecklist()}
                  >
                    Add a new task
                    <img
                      src={plusIcon}
                      className="plus-icon"
                      alt="add a new task"
                    />
                  </ButtonCustom>
                </div>
                <ChecklistRightSidebar />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </ChecklistStyles>
  );
};

export default Checklist;

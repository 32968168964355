import { useReducer } from "react";
import moment from "moment";
import { map, get, find, findIndex, filter, isNull } from "lodash";

import { createtTime } from "../helper/helper";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  tab: "ongoing",
  webinars: {
    past_webinars: [],
    upcoming_webinars: [],
    presenters: [],
    calendarEvents: [],
  },
  myWebinars: [],
  myCalendarEvents: [],
  webinarDetails: null,
  webId: null,
  showWebinarRightSide: false,
  resourceCategories: [],
  resourceName: "",
  resourceSubscribeStatus: false,
  selectedResourceCategoryIndex: null,
  saveResourceList: [],
  resourceList: [],
  resourceLoader: false,
  filterOptions: {
    video_category_id: null,
    search: "",
    sort_by: "name",
  },
};

const useLearn = (appState) => {
  const [state, setState] = useReducer(reducer, initialArgs);

  /** ------------------------- Webinar Section Start ------------------------------ */

  const setWebinars = (webinars) => {
    const calendarData = [];
    let startDateTime, endDateTime, startTime, endTime;

    map(
      webinars.past_webinars
        .map((rec) => ({ ...rec, type: "past" }))
        .concat(
          webinars.upcoming_webinars.map((rec) => ({
            ...rec,
            type: "upcoming",
          }))
        ),
      (data, i) => {
        // map(webinars.past_webinars, (data, i) => {
        startTime = moment.utc(data.webinar_time).format("hh:mm a");
        endTime = createtTime(
          data.webinar_time,
          data && data.duration && !isNull(data.duration)
            ? data.duration.split(" ")[0]
            : 0,
          "minutes"
        );

        startDateTime = data.webinar_date + " " + startTime;
        endDateTime = data.webinar_date + " " + endTime;
        calendarData.push({
          id: data.id,
          title: data.name,
          address: data.location,
          start:
            startDateTime && moment(startDateTime, "YYYY-MM-DD HH:mm").toDate(),
          // new Date(moment(data.webinar_date).format("YYYY-MM-DD")),
          end: endDateTime && moment(endDateTime, "YYYY-MM-DD HH:mm").toDate(),
          // new Date(moment(data.webinar_date).format("YYYY-MM-DD")),
          type: data.type,
          webinar_time: data.webinar_time,
          rss_feeds: data.rss_feeds ? true : false,
          rss_feed_id:
            data.rss_feed_id && data.rss_feed_id != null
              ? data.rss_feed_id
              : null,
        });
      }
    );
    setState({
      webinars: {
        past_webinars: webinars.past_webinars,
        upcoming_webinars: webinars.upcoming_webinars,
        presenters: prepairPreEnters(webinars.presenters),
        // presenters: optionsGenerator(webinars.presenters),
        calendarEvents: calendarData,
      },
    });
  };

  const setMyWebinars = (data) => {
    //console.log("data", data)
    const calendarData = [];
    let startDateTime, endDateTime, startTime, endTime;
    data.my_webinars_list.map((webinar, i) => {
      startTime = moment.utc(webinar.webinar_time).format("hh:mm a");
      endTime = createtTime(
        webinar.webinar_time,
        webinar && webinar.duration && !isNull(webinar.duration)
          ? webinar.duration.split(" ")[0]
          : 0,
        "minutes"
      );

      startDateTime = webinar.webinar_date + " " + startTime;
      endDateTime = webinar.webinar_date + " " + endTime;
      calendarData.push({
        id: webinar.id,
        title: webinar.name,
        address: webinar.location,
        start:
          startDateTime && moment(startDateTime, "YYYY-MM-DD HH:mm").toDate(),
        end: endDateTime && moment(endDateTime, "YYYY-MM-DD HH:mm").toDate(),
        type: "upcoming",
        webinar_time: webinar.webinar_time,
        rss_feeds: webinar.rss_feeds ? true : false,
        rss_feed_id:
          webinar.rss_feed_id && webinar.rss_feed_id != null
            ? webinar.rss_feed_id
            : null,
      });
    });

    setState({
      myWebinars: get(data, "my_webinars_list", []),
      myCalendarEvents: calendarData,
    });
  };

  const setWebinarDetails = (data, showWebinar = false) => {
    setState({
      showWebinarRightSide: showWebinar,
      webId: data.webinar ? data.webinar.id : null,
      webinarDetails: get(data, "webinar", null),
    });
  };

  /** ------------------------- Webinar Section END ------------------------------ */

  /** -----------------------------Resouces Section START-------------------------- */

  const setResourceCategories = (obj) => {
    setState({ resourceCategories: obj.video_categories });
  };

  const setSelectedResourceCategoryIndex = (index) => {
    setState({ selectedResourceCategoryIndex: index });
  };

  const setSavedResources = (data) => {
    // console.log("saved_resources_list =>", data);
    setState({
      saveResourceList: data.saved_resources_list,
    });
  };

  const setResourcelist = (data, id) => {
    const obj = find(state.resourceCategories, (o) => {
      return o.id === id;
    });

    setState({
      resourceList: get(data, "resources", []),
      selectedResourceCategoryIndex: id,
      resourceName: get(obj, "category_name", ""),
      resourceSubscribeStatus: get(data, "susbscribe_status", false),
    });
  };

  const setSaveUnSaveResource = (resourceId, data, from) => {
    if (from === "middle") {
      let resourceList = state.resourceList;
      const index = findIndex(resourceList, { id: resourceId });
      const obj = find(state.resourceList, (o) => {
        return o.id === resourceId;
      });

      if (data === "") {
        resourceList[index].is_favourite = false;
        setState({
          resourceList: resourceList,
          saveResourceList: filter(state.saveResourceList, (o) => {
            return o.id !== resourceId;
          }),
        });
      } else {
        resourceList[index].is_favourite = true;
        setState({
          resourceList: resourceList,
          saveResourceList: [obj, ...state.saveResourceList],
        });
      }
    } else {
      let resourceList = state.resourceList;
      if (resourceList.length) {
        const index = findIndex(resourceList, { id: resourceId });
        if (data === "") {
          resourceList[index].is_favourite = false;
          setState({
            resourceList: resourceList,
            saveResourceList: filter(state.saveResourceList, (o) => {
              return o.id !== resourceId;
            }),
          });
        }
      }
    }
  };

  const setSearchAndSortFilter = (obj) => {
    setState({
      filterOptions: {
        ...state.filterOptions,
        ...obj,
      },
    });
  };

  const setLoader = (status) => {
    setState({
      resourceLoader: status,
    });
  };
  /** --------------------------Resouces Section END-------------------------- */
  const switchTab = (tab) => {
    setState({
      tab,
    });
  };
  return {
    setWebinars,
    setMyWebinars,
    setWebinarDetails,
    setResourceCategories,
    setSelectedResourceCategoryIndex,
    setSavedResources,
    setResourcelist,
    setSaveUnSaveResource,
    setSearchAndSortFilter,
    setLoader,
    switchTab,
    ...state,
  };
};

const prepairPreEnters = (presenters = []) => {
  const newPresenters = presenters.map((item) => {
    return { value: item, label: item };
  });
  return newPresenters;
};

export default useLearn;

import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_DARK_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
.header-wrapper{
  border-bottom: 2px solid #ebf1f7;
  background: rgb(250, 251, 253);
}
  .header {
    display: flex;
    background: #fafbfd;
    padding: 5px 15px 5px;
    align-items: center;
    min-height: 57px;
    max-width: 1300px;
    margin: 0 auto;
    @media(max-width:1366px){
      max-width: 1030px;
    }
    .skip-content-link {
      position: absolute;
      z-index: 1;
      padding: 0;
      text-decoration: none;
      border-radius: 100px;
      left: 400px;
      font-family: "TTCommons-Medium";
      display: block;
      font-size: 0px;
      color: ${PRIMARY_COLOR};
      text-transform: capitalize;
      top: 22px;
      opacity: 1;
      font-weight: 500;
      &:focus {
        font-size: 16px;
      }
    }

    .logos {
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        display: block;
        position: relative;
        padding: 4px 4px;
        display: flex;
        border-radius: 100px;
        img {
          max-width: 180px;
          width: 170px;
          max-height: 36px;
        }
      }
      .university-logo {
        padding: 0;
        margin-left: 10px;
        border-left: 2px solid var(--light-gray-color);
        padding-left: 16px;
        min-height: 38px;
        display: flex;
        align-items: center;
        img {
          display: block;
          max-width: 180px;
          white-space: nowrap;
          max-height: 36px;
        }
      }
    }

    .navbar {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      ul.menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        @media(max-width:1366px){
          margin-left: 60px;
        }
        li {
          padding: 0px 0 0px;
          + li {
            margin-left: 60px;
            @media(max-width:1366px){
              margin-left: 40px;
            }
          }
          a {
            font-family: "TTCommons-Regular";
            display: block;
            font-size: 18px;
            color: ${PRIMARY_DARK_COLOR};
            text-decoration: none;
            position: relative;
            line-height: 18px;
            font-weight: 400;
            padding-top: 2px;
            &:after {
              content: "";
              position: absolute;
              width: 100%;
              background: ${PRIMARY_DARK_COLOR};
              left: 0;
              right: 0;
              box-sizing: border-box;
              height: 1px;
              bottom: 0px;
              display: none;
            }

            &.active,&:hover {
              color: ${PRIMARY_DARK_COLOR};
              &:after {
                display: block;
              }
            }
            &:focus {
              color: #000c3d !important;
              &:after {
                display: block;
                background: #000c3d !important;
              }
            }
          }
        }
      }

      ul.header-rightsidebar {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          a {
            text-decoration: none;
            > div.initial-avatar {
              font-size: 26px;
              padding-top: 6px;
            }
          }
          svg {
            font-size: 24px;
            fill: #fff;
            margin-top: 2px;
          }
          &.nprofile-icon{
            .profile-redirect-modal{
              .MuiAvatar-colorDefault{
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                line-height: 43px;
                @media(max-width:767px){
                  line-height: 30px;
                  font-size: 18px;
                }
                svg{
                  margin-top: 4px;
                  font-size: 24px;
                  @media(max-width:767px){
                    margin-top: 3px;
                  }
                }
              }
            }
          }
        }
        &.wcag-rightsidebar {
          li {
            border-bottom: 2px solid transparent;
            .MuiAvatar-root {
              width: 42px;
              height: 42px;
              border: 0 !important ;
              margin: 0 0 0px 0;
            }
            &.active {
              .MuiAvatar-root {
                background: var(--primary-main-color) !important;
                &.default-img {
                  background: var(--light-gray-color) !important;
                }
              }
            }
            a {
              height: 42px;
              width: 42px;
              &:focus {
                .MuiAvatar-root {
                  box-shadow: 0 0 0 3px #000c3d;
                }
              }
            }
            .notifi-boxs {
              svg {
                font-size: 32px;
              }
            }
          }
        }
        .MuiAvatar-root {
          height: 40px;
          width: 40px;
          border: 2px solid var(--gray-outline-color);
          background-color: var(--gray-outline-color);
          border-radius: 100px;
          overflow: hidden;
          transition:all .3s;
          &.active,&:hover {
            border: 2px solid var(--primary-main-color);
            background: var(--primary-main-color);
            svg{
              fill:#fff;
            }
          }
          &.default-img {
            background: #d1dde5;
          }
        }
        li {
          margin: 0 0 0 8px;
          position: relative;
          .MuiAvatar-colorDefault {
            &.noti-active {
              background-color: var(--primary-main-color) !important;
              border-color: var(--primary-main-color) !important;
              + .MuiBadge-badge {
                display: none;
              }
            }
          }
          .notifi-boxs {
            svg {
              font-size: 26px;
              margin-top: 0;
            }

            .MuiBadge-badge {
              min-width: 17px !important;
              width: 17px;
              height: 17px;
              background-color: var(--secondary-color) !important;
              color: #ffffff;
              font-family: "TTCommons-Medium";
              font-size: 12px;
              padding: 2px 0 0 0;
              top: -3px;
              right: -1px;
              transform: none;
            }
          }
          .profile-redirect-modal {
            ul.submenu {
              list-style: none;
              box-shadow: var(--card-box-shadow);
              border-radius: 10px 1px 10px 10px;
              padding: 0 20px
              margin: 8px 0 0 0;
              width: 265px;
              position: absolute;
              z-index: 99;
              top: 100%;
              right: 0;
              background-color: #ffffff;
              li {
                margin: 0px;
                font-family: "TTCommons-Medium";
                font-size: 18px;
                font-weight: 500;
                line-height: 18px;
                color: var(--primary-dark-color);
                text-align: center;
                padding: 22px 0 18px;

                a {
                  color: var(--primary-dark-color);
                  border-bottom:2px solid transparent;
                  text-decoration:none;
                  &:focus,&:hover{
                    border-bottom-color:var(--primary-dark-color);
                  }
                }

                + li {
                  border-top: 2px solid #EAEEF3;
                }
              }
            }
          }
        }
      }
    }
  }
`;

import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .interstride-student-main-content-wrapper {
    margin-top: 15px;
  }
  .discover-network-main-container {
    margin-top: 20px !important;
  }
  .layout-header-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
    position: relative;
    .card-wrapper {
      position: absolute;
      left: 35px;

      .card-content {
        align-items: center;
        display: flex;
        font-size: 12px;
        padding: 14px 20px 12px;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        color: var(--gray-text-color);
        font-family: "TTCommons-Regular";
        li {
          list-style: none;
          &:not(:last-child):after {
            content: " >> ";
            margin: 0 10px;
          }
          &:last-child {
            color: var(--secondary-color);
            border-bottom: 1px solid var(--secondary-color);
          }
        }
      }
    }
    .relative-div {
      width: 100%;
      .breadcrumb-div {
        left: 0;
      }
    }
  }
  .page-title {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .main-layout-card {
    .MuiCardHeader-root {
      padding: 25px 15px;
      position: relative;

      .MuiCardHeader-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f1f4f7;
        color: #0030b5;
        margin-right: 13px;
      }
      .MuiCardHeader-title {
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        color: ${PRIMARY_COLOR};
      }
      .MuiCardHeader-subheader {
        font-size: 16px;
        line-height: 19px;
        color: var(--gray-text-color);
        margin-top: 7px;
      }
      .MuiCardHeader-action {
        margin-top: 0;
        margin-right: 0;
        position: absolute;
        right: 25px;
        top: 27px;
      }
    }
  }
  /*
  @media(min-width: 1280px){
    .container {
        max-width: 1400px;
    }
  }
  */
`;

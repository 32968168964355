import React from "react";
import { navigate } from "@reach/router";

import profileArrowIcon from "../../../../assets/svg/f-arrow-profile-down-arrow-icon.svg";
import registerWebinar from "../../../../assets/png/f-register-for-webinar-step4.png";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { routes } from "../../../../routes";


const RegisterWebinar = ({
  isRegisterButton = true,
  title = "How to do it",
  subTitle = `Go to the Learn portal and click on Webinars to see all upcoming and
past webinars. Click Register to get access.`,
}) => {
  return (
    <>
      <div className="status-wrapper">
        <div className="status-title text-primary">{title}</div>
        <div className="status-sub-title">{subTitle}</div>
        <div className="status-box d-flex justify-content-center align-items-center boost-webinar pos-relative">
          <img src={profileArrowIcon} className="arrow-icon" alt="Down Arrow" />
          <img
            src={registerWebinar}
            className="steps-img step4"
            alt="Register Webinar"
          />
        </div>
        {isRegisterButton && (
          <ButtonCustom
            onClick={() => navigate(routes.LEARN.WEBINARS)}
            width={250}
            height={60}
            style={{ marginTop: "30px" }}
          >
            Get Started
          </ButtonCustom>
        )}
      </div>
    </>
  );
};

export default RegisterWebinar;

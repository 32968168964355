import React, { useEffect } from "react";
import { Router, navigate } from "@reach/router";

import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import { routes } from "../../routes";
import Discover from "./Discover/Discover";
import Messenger from "./Messenger/Messenger";
import { useInitChat } from "../../hooks/useInitChat";
import { useChatsList } from "../../hooks/useChatsList";
import { useFetchContacts } from "../../hooks/useFetchContacts";
import { useAppState } from "../../context";
import AllTopics from "../Topics/AllTopics/AllTopics";
import usePersistState from "../../state/usePersistState";

import MyTopicsIcon from "../../assets/svg/myTopicsIcon.svg";
import DiscoverIcon from "../../assets/FocusIcons/MenuIcons/Network/DiscoverNew.svg";
import MyTopicsFocusIcon from "../../assets/FocusIcons/MenuIcons/Network/MyTopics.svg";
import SavedNetwork from "./SavedNetwork/SavedNetwork";
import NewMessage from "./NewMessage/NewMessage";
import NewGroup from "./NewGroup/NewGroup";
import NewTopic from "./NewTopic/NewTopic";
import ParticipantsListV2 from "./ParticipantsListV2/ParticipantsListV2";

const Network = ({ location, permissions = {} }) => {
  const { topics_notifcations_count } = usePersistState();
  const { discoverNetworkFilter, setTriggerReloadUnreadCountUpdate } =
    useAppState("chat");
  const { handleCommunitySubHeaderClick } = useAppState("topic");
  useFetchContacts(discoverNetworkFilter);
  useInitChat();
  useChatsList();

  useEffect(() => {
    if (
      ((location.pathname.includes(routes.NETWORK.MESSAGES) ||
        location.pathname.includes(routes.NETWORK.MESSENGEN) ||
        location.pathname.includes(routes.NETWORK.DISCOVER)) &&
        !permissions["chat"]) ||
      (location.pathname.includes(routes.NETWORK.TOPICS) &&
        !permissions["topics"])
    )
      navigate(routes.DASHBOARD);
  }, [permissions]);

  const tabs = [
    ...(permissions["topics"]
      ? [
          {
            title: "Community",
            toolTip: "Join cohorts within the broader Interstride community.",
            avatar: MyTopicsIcon,
            focusAvatar: MyTopicsFocusIcon,
            navigateURL: routes.NETWORK.TOPICS,
            navigateSideEffect: () => {
              handleCommunitySubHeaderClick();
            },
            isActive: location.pathname === routes.NETWORK.TOPICS,
            hasNotificationBadge: true,
            count: topics_notifcations_count,
          },
        ]
      : []),
    ...(permissions["chat"]
      ? [
          {
            title: "Discover Network",
            toolTip:
              "Find peers and alumni from your school who share similar background and interest.",
            avatar: DiscoverIcon,
            focusAvatar: DiscoverIcon,
            navigateURL: routes.NETWORK.DISCOVER,
            isActive: location.pathname === routes.NETWORK.DISCOVER,
          },
        ]
      : []),
  ];

  useEffect(() => {
    setTriggerReloadUnreadCountUpdate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      title="Network Dashboard"
      withSubHeader={
        window.location.pathname.includes("messenger") ? false : true
      }
      tabs={tabs}
      location={location}
      viewOnlyNavbar
      containerClassName={
        location.pathname === "/network/discover-topics"
          ? "discover-network-main-container"
          : ""
      }
    >
      <Router>
        {permissions["chat"] && (
          <>
            <Discover path="/discover" />
            <SavedNetwork path="/discover/saved-network" />
            <Messenger path="/messenger" />
            <NewMessage path="/messenger/new-message" />
            <NewGroup path="/messenger/new-group" />
          </>
        )}
        {permissions["topics"] && (
          <>
            <AllTopics path="/topics" />
            <NewTopic path="/topics/new-topic" />
            <ParticipantsListV2 path="/topics/participants-list" />
          </>
        )}
      </Router>
    </MainLayout>
  );
};

export default Network;

import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .MuiPaper-root {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    padding: 22px 20px 18px 20px;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    + .MuiPaper-root {
      margin-top: 15px;
    }
    .MuiButtonBase-root {
      padding: 0;
      min-height: auto;
      .MuiAccordionSummary-content {
        margin: 0 !important;
        p {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 19px;
        }
      }
      .MuiAccordionSummary-expandIcon {
        margin-right: 6px;
        svg.MuiSvgIcon-root {
          fill: var(--primary-dark-color);
        }
      }
    }
    &:before {
      display: none;
    }
    .MuiAccordionDetails-root {
      padding: 0;
      margin: 8px 0 0 0;
      flex-wrap: wrap;

      .career-body {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        font-family: "TTCommons-Regular";
        color: var(--primary-dark-color);

        p {
          margin: 0;
        }
        ul {
          margin: 8px 0 0 0;
          padding: 0 0 0 15px;
          li {
            + li {
              margin: 4px 0 0 0;
            }
          }
        }

        a {
          color: var(--secondary-color) !important;
          border-bottom: 1px solid var(--secondary-color);
          text-decoration: none;
          &:focus {
            color: var(--primary-dark-color) !important;
            border-color: var(--primary-dark-color) !important;
          }
        }
      }
    }
  }
`;

import React, { memo, useReducer, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { MoreHoriz, Close } from "@material-ui/icons";
import { ClickAwayListener } from "@material-ui/core";

import { routes } from "../../../../routes";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./ChatActionsGroup.module.scss";
import MenuDialog from "../../../Topics/TopicRight/MenuDialog";
import { defaultState, reducer } from ".";
import { useAppState } from "../../../../context";
import {
  deleteChat,
  muteOrUnmuteChat,
  reportChat,
} from "../../../../services/chat.service";
import { toastify, trapFocus } from "../../../../helper/helper";
import usePersistState from "../../../../state/usePersistState";

const cx = createModuleStyleExtractor(styles);

const ChatActionsGroup = () => {
  const {
    isGroup,
    isFreshChat,
    isGroupAdmin,
    setGroupTags,
    setReloadChats,
    toggleMuteStatus,
    activeConversation,
    deleteChatFromState,
    fetchGroupParticipants,
    removeUserFromFreshChat,
    getChatPreviewByConversationId,
    setCurrentGroupModalId,
  } = useAppState("chat");
  const { users } = usePersistState();
  const { id: userId } = users || {};
  const preview = getChatPreviewByConversationId();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [anchorEl, setAnchorEl] = useState(null);
  const { mute_chat } = preview || {};
  const isCurrentFreshChat = isFreshChat(activeConversation);
  const actions = [
    ...(isGroup() && isGroupAdmin(activeConversation, userId)
      ? [
          {
            title: "Add Someone New",
            action: "group.add.member",
          },
        ]
      : []),
    {
      title: "Leave Chat",
      action: "chat.leave",
    },
    ...(!isCurrentFreshChat
      ? [
          {
            title: !mute_chat ? "Mute Chat" : "Unmute Chat",
            action: "chat.mute",
          },
        ]
      : []),
    {
      title: "Report Chat",
      action: "chat.report",
    },
  ];

  const closeMenuModel = () => {
    dispatch({ type: "action.reset" });
    dispatch({ type: "action.dropdown", value: false });
  };

  const onOptionSelect = (id) => {
    /**
     * If it is fresh chat but not yet created
     */
    if (isFreshChat(activeConversation)) {
      if (id !== "chat.leave") {
        toastify("error", `${"You can't perform this action before chat"}`);
        return;
      }
    }
    switch (id) {
      case "group.add.member": {
        const participants = fetchGroupParticipants(activeConversation);
        const users = participants
          .filter((user) => user.userid !== userId)
          .map((item) => {
            return { id: `${item.userid}`, text: `${item.user_name}` };
          });
        setGroupTags(users);
        closeMenuModel();
        setCurrentGroupModalId(activeConversation);
        navigate(routes.NETWORK.NEW_GROUP);
        break;
      }
      case "chat.leave":
        dispatch({
          type: "chat.action",
          id,
          modal: {
            isOpen: true,
            title: "Are you sure you want to delete this chat?",
            btnText: "Delete Chat",
            bottomText: "Cancel",
            showTextBox: false,
            modelId: id,
          },
        });

        //leave chat
        break;
      case "chat.mute":
        dispatch({
          type: "chat.action",
          id,
          modal: {
            isOpen: true,
            title: `Are you sure you want to ${
              mute_chat ? "unmute" : "mute"
            } this chat?`,
            subTitle: "You will need to be invited to join the chat again.",
            btnText: `${mute_chat ? "Unmute Chat" : "Mute Chat"}`,
            bottomText: "Cancel",
            showTextBox: false,
            modelId: id,
          },
        });
        //mute chat
        return;
      case "chat.report":
        dispatch({
          type: "chat.action",
          id,
          modal: {
            isOpen: true,
            title: "What would you like to report?",
            btnText: "Share Report",
            bottomText: "No thanks",
            showTextBox: true,
            modelId: id,
          },
        });
        return;
      default:
        dispatch({
          type: "chat.action",
          modal: {
            modelId: id,
            isOpen: true,
            title: "Thanks for sharing your feedback!",
            subTitle:
              "We will review your report and take the necessary action.",
            btnText: "Got It",
            bottomText: "",
            showTextBox: false,
          },
        });
    }
  };

  /**
   * Handler called on submit
   * @param {*} actionId
   */
  const callAPI = async (actionId) => {
    switch (actionId) {
      case "chat.leave": {
        if (isFreshChat(activeConversation)) {
          removeUserFromFreshChat(activeConversation);
          deleteChatFromState();
          setReloadChats(true);
          return;
        }
        const response = await deleteChat(activeConversation);
        const { success } = response;
        if (success) {
          deleteChatFromState();
          setReloadChats(true);
        }
        closeMenuModel();
        break;
      }
      case "chat.mute": {
        const meta = await muteOrUnmuteChat(activeConversation);
        if (meta.success) {
          toggleMuteStatus(activeConversation);
        }
        closeMenuModel();
        break;
      }
      case "chat.report": {
        const _response = await reportChat({
          report_text: state.reportText,
          conversation_id: activeConversation,
        });
        if (_response) {
          closeMenuModel();
          onOptionSelect(-1);
        }
        break;
      }
      default:
        closeMenuModel();
        break;
    }
  };
  useEffect(() => {
    if (anchorEl) {
      let element = document.getElementById("focus-submenu1");
      trapFocus(element);
    }
  }, [anchorEl]);
  return (
    <ClickAwayListener onClickAway={() => closeMenuModel()}>
      <div className="action-menu">
        <MenuDialog
          isOpen={state.modal.isOpen}
          data={state.modal}
          handleClose={closeMenuModel}
          callAPI={callAPI}
          onTextChange={(value) =>
            dispatch({ type: "chat.report.text", value })
          }
          reportText={state.reportText}
        />
        <div id="focus-submenu1">
          <div className={cx("group-profile__dropdown-button")}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                !state.dropdown
                  ? dispatch({ type: "action.dropdown", value: true })
                  : dispatch({ type: "action.dropdown", value: false });
                setAnchorEl(anchorEl ? null : e.currentTarget);
              }}
            >
              {!state.dropdown ? <MoreHoriz /> : <Close />}
            </a>
          </div>
          {state.dropdown && (
            <div className={cx("group-profile__dropdown")}>
              {actions.map((option, index) => (
                <React.Fragment key={index}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onOptionSelect(option.action);
                    }}
                  >
                    <span>{option.title}</span>
                  </a>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default memo(ChatActionsGroup);

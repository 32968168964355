import React from "react";

import CustomDialog from "../../CustomDialog/CustomDialog";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import TextboxCustom from "../../Textbox/TextboxCustom";

const MenuDialog = ({
  handleClose,
  callAPI,
  data,
  loader = false,
  onTextChange = () => {},
  reportText = "",
}) => {
  const { isOpen, modelId, title, subTitle, btnText, bottomText, showTextBox } =
    data;
  return (
    <>
      <CustomDialog
        open={isOpen}
        title={title}
        subTitle={subTitle && subTitle}
        handleClose={handleClose}
        className={`topic-menu-dialog ${data.className}`}
      >
        <div className="menu-dialog-content">
          <div className="content-box">
            {showTextBox && (
              <TextboxCustom
                className="report-text"
                isMultiline={true}
                label="Your report*"
                placeholder="Please share any complaints or inappropriate behavior."
                onChange={(e) => onTextChange(e.target.value)}
              />
            )}
          </div>
          <div className="btn">
            {showTextBox ? (
              <ButtonCustom
                isSubmitting={loader}
                isDisabled={loader || reportText?.trim()?.length === 0}
                onClick={() => callAPI(modelId)}
                width={250}
              >
                {btnText}
              </ButtonCustom>
            ) : (
              <ButtonCustom
                isSubmitting={loader}
                isDisabled={loader}
                onClick={() => callAPI(modelId)}
                width={250}
              >
                {btnText}
              </ButtonCustom>
            )}
          </div>
          <div className="text-center">
            <a
              href="/#"
              className="ngray-link"
              onClick={(e) => {
                e.preventDefault();
                handleClose(e);
              }}
              aria-label={bottomText}
            >
              {bottomText && bottomText}
            </a>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default MenuDialog;

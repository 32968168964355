import React from "react";
import CustomUploadStyles from "./CustomUploadStyles";
import { useDropzone } from "react-dropzone";
import { Avatar } from "@material-ui/core";
import { NoUserImage } from "../../helper/helper";
import uploadIcon from "../../assets/svg/uploadIcon.svg";
import ButtonCustom from "../ButtonCustom/ButtonCustom";

const CustomUpload = ({
  handleUploadFile = (e) => {},
  removeUploadFile = (e) => {},
  icon,
  remove = false,
  text,
  width = "115",
  vallidFiles,
  showPreview = false,
  revertIcon = false,
  preview = null,
  removeText,
  focusIcon,
  name = "File upload",
  isSsoModal = false
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    noDrag: true,
    onDrop: handleUploadFile,
  });

  // useEffect(() => {
  //   handleUploadFile(acceptedFiles);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [acceptedFiles]);

  const id = Math.random();

  return (
    <CustomUploadStyles>
      <div
        className="dropzone-custom"
        {...getRootProps({
          className: "dropzone cursor-pointer",
        })}
        onClick={open}
      >
        <label htmlFor={name} className="hide-element">
          {name}
        </label>
        <input {...getInputProps()} accept={vallidFiles} id={name} />
        {showPreview && (
          <div className="custom-avtar d-flex">
            <Avatar
              src={preview !== "" ? preview : NoUserImage}
              alt="preview"
            />
          </div>
        )}
      </div>
      {
        isSsoModal ? <>
          <div className="remove-icon">
            <div
              onClick={open}
              className="removeicon-center d-flex align-items-center justify-content-center"
            >
              <a
                href={`/#`}
                onClick={(e) => {
                  e.preventDefault();
                }}
                aria-label={text ? text : "attachment"}
                className="btn-upload cursor-pointer img-link link-focus"
                onFocus={() => {
                  if (icon && focusIcon) {
                    let img = document.getElementById(`upload-icon-${id}`);
                    if (img) img.src = focusIcon;
                  }
                }}
                onBlur={() => {
                  if (icon && focusIcon) {
                    let img = document.getElementById(`upload-icon-${id}`);
                    if (img) img.src = icon;
                  }
                }}
              >
                {/* {icon && (
                  <img
                    id={`upload-icon-${id}`}
                    src={icon}
                    alt={text ? text : "attachment"}
                    width="15px"
                  />
                )} */}
                <span>{text}</span>
              </a>
            </div>
            {remove && (
              <a
                href={`/${removeText}`}
                onClick={(e) => {
                  e.preventDefault();
                  removeUploadFile(e);
                }}
                aria-label={removeText}
                className="removeicon-center btn-upload cursor-pointer link-focus"
              >
                {removeText}
              </a>
            )}
          </div>
        </> : <>
          <div className="remove-icon">
            <div
              onClick={open}
              className="removeicon-center d-flex align-items-center justify-content-center"
            >
              {/* <a
                href={`/#`}
                onClick={(e) => {
                  e.preventDefault();
                }}
                aria-label={text ? text : "attachment"}
                className="btn-upload cursor-pointer img-link link-focus"
                onFocus={() => {
                  if (icon && focusIcon) {
                    let img = document.getElementById(`upload-icon-${id}`);
                    if (img) img.src = focusIcon;
                  }
                }}
                onBlur={() => {
                  if (icon && focusIcon) {
                    let img = document.getElementById(`upload-icon-${id}`);
                    if (img) img.src = icon;
                  }
                }}
              >
                {icon && (
                  <img
                    id={`upload-icon-${id}`}
                    src={icon}
                    alt={text ? text : "attachment"}
                    width="15px"
                  />
                )}
                <span>{text}</span>
              </a> */}

              <ButtonCustom
                width={width}
                style={{
                  opacity: 1,
                  backgroundColor: "#0030B5",
                  height: 40,
                  paddingInline: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: 14,
                      height: 13,
                      marginRight: 6,
                      marginBottom: 3,
                      filter: "brightness(0) invert(1)",
                    }}
                    src={uploadIcon}
                    alt="Linked In"
                  />
                  <p style={{ textTransform: "initial" }} className="">
                    {text}
                  </p>
                </div>
              </ButtonCustom>
            </div>
            {remove && (
              <a
                href={`/${removeText}`}
                onClick={(e) => {
                  e.preventDefault();
                  removeUploadFile(e);
                }}
                aria-label={removeText}
                className="removeicon-center btn-upload cursor-pointer link-focus"
              >
                {removeText}
              </a>
            )}
          </div>
        </>
      }
    </CustomUploadStyles>
  );
};

export default CustomUpload;

import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "@reach/router";
import { filter, isNull, isEmpty } from "lodash";
import Scrollbars from "react-custom-scrollbars";

import { BACKEND_FLAG_URL } from "../../../constant/mockdata";
import usePersistState from "../../../state/usePersistState";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import EmptyMiddleBox from "../../../components/EmptyModdleBox/EmptyMiddleBox";
import GoogleMapCustom from "../../../components/GoogleMapCustom/GoogleMapCustom";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import Overview from "../../../assets/svg/icon-overview.svg";
import Travel from "../../../assets/svg/icon-travel.svg";
import Tax from "../../../assets/svg/icon-tax.svg";
import Housing from "../../../assets/svg/icon-housing.svg";
import Insurance from "../../../assets/svg/icon-insurance.svg";
import Bank from "../../../assets/svg/icon-bank.svg";
import Scholarships from "../../../assets/svg/icon-scholarships.svg";
import Jobs from "../../../assets/svg/icon-jobs.svg";
import Health from "../../../assets/svg/icon-health.svg";
import CIA from "../../../assets/svg/icon-cia.svg";
import Numbeo from "../../../assets/svg/icon-numbeo.svg";
import CDC from "../../../assets/svg/icon-cdc.svg";
import Lonely from "../../../assets/svg/icon-lonely.svg";
import Advisory from "../../../assets/svg/icon-travelAdvisory.svg";
import Hofstede from "../../../assets/svg/icon-Hofstede.svg";
import Immigration from "../../../assets/svg/Icon-immigration-link.svg";
import CareerGuidance from "../../../assets/svg/Icon-careerguidance-link.svg";
import JobsLink from "../../../assets/svg/Icon-job-link.svg";
import Networking from "../../../assets/svg/Icon-networking-link.svg";

const CountryView = ({
  selectedCountry = {},
  countryDetails = {},
  onBack = () => {},
  handleCityClick = () => {},
}) => {
  const { permissions = {} } = usePersistState();

  const [filterResult, setFilterResult] = useState([]);

  const selectedCountryName = !isNull(selectedCountry)
    ? selectedCountry.name
    : "";

  const selectedCountryCode = !isNull(selectedCountry)
    ? selectedCountry.code
    : "";

  useEffect(() => {
    setFilterResult([...countryDetails.cities]);
  }, [countryDetails]);

  const renderLink = (name, link, desc, icon) => (
    <>
      {isNull(link) || isEmpty(link) || link === "NA" ? (
        <></>
      ) : (
        <a
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          className="cursor-pointer"
        >
          <div className="wraper">
            <div>
              <div className="icon">
                <img src={icon} alt={name} />
              </div>
              <h3>{name}</h3>
              <p>{desc}</p>
            </div>
          </div>
        </a>
      )}
    </>
  );

  const renderMainLink = (name, link, icon, defaultIcon = false) => (
    <>
      {isNull(link) || isEmpty(link) || link === "NA" ? (
        <></>
      ) : (
        <a
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          className="cursor-pointer"
          tabIndex={0}
        >
          <div className="wrapper">
            <div className="icon">
              <img
                src={icon}
                {...(defaultIcon ? { height: 50, width: 50 } : {})}
                alt="Icon"
              />
            </div>
            <div>
              <h3>{name}</h3>
            </div>
          </div>
        </a>
      )}
    </>
  );

  const showCities = countryDetails.cities.length > 0;

  const country_guide =
    !isNull(countryDetails.country_guide) &&
    !isEmpty(countryDetails.country_guide)
      ? countryDetails.country_guide
      : {};

  const showOverview =
    isNull(countryDetails.overview) ||
    isEmpty(countryDetails.overview) ||
    countryDetails.overview === "NA" ||
    countryDetails.overview === "No description found"
      ? false
      : true;

  return (
    <div
      className={`country-view-wrapper ${showCities ? "" : "hide-city"} ${
        showOverview ? "" : "hide-overview"
      }`}
    >
      <div className="country-heading">
        <div className="serach-country">
          {showCities ? (
            <div className="form-field-group">
              <TextboxCustom
                name="search"
                placeholder={"Search city"}
                lableaignment="left"
                onChange={(e) => {
                  let data = filter(countryDetails.cities, function (o) {
                    return o.city_name.match(
                      new RegExp("^" + e.target.value, "i")
                    );
                  });
                  setFilterResult(data);
                }}
              />
            </div>
          ) : (
            <a
              href="/#"
              className="pages-back-btn"
              onClick={(e) => {
                e.preventDefault();
                onBack();
              }}
            >
              <img src={BackArrow} width="21" alt="Back arrow" />
              Back to Countries
            </a>
          )}
          <h2 className="title-primary">
            {selectedCountryName}
            <div className="flag-icon">
              <img
                src={`${BACKEND_FLAG_URL}${
                  selectedCountry ? selectedCountry.code : ""
                }.svg`}
                alt="Flag"
              />
            </div>
          </h2>
          {permissions["chat"] && countryDetails.is_network_users && (
            <>
              <div className="networking-link">
                <Link
                  to={`/network/discover?country=${selectedCountryCode}`}
                  className="nlink-secondary"
                >
                  Connect with your network from {selectedCountryName}
                </Link>
                <svg
                  width="9px"
                  height="14px"
                  viewBox="0 0 9 14"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Final-Design"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Country-Insights/Country"
                      transform="translate(-1393.000000, -158.000000)"
                      stroke="#0030B5"
                      strokeWidth="2"
                    >
                      <g
                        id="Connect"
                        transform="translate(1116.000000, 154.000000)"
                      >
                        <polyline
                          id="Path-3"
                          transform="translate(281.000000, 11.000000) rotate(-90.000000) translate(-281.000000, -11.000000) "
                          points="275.5 8 281 14 286.5 8"
                        ></polyline>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="selected-contry-content">
        <Grid container spacing={3}>
          {showCities && (
            <Grid item md={2}>
              <div className="cities_wrapper">
                <a
                  href="/#"
                  className="pages-back-btn mb-15"
                  onClick={(e) => {
                    e.preventDefault();
                    onBack();
                  }}
                  tabIndex={0}
                >
                  <img src={BackArrow} width="21" alt="Back arrow" />
                  Back to Countries
                </a>
                <h3 className="title-primary mb-15">
                  Cities in {selectedCountryName}
                </h3>
                <ul>
                  {filterResult.length > 0 ? (
                    <>
                      {filterResult.map((city) => (
                        <li key={city.city_name}>
                          <a
                            href="/#"
                            aria-label={city.city_name}
                            onClick={(e) => handleCityClick(e, city)}
                          >
                            {city.city_name}
                          </a>
                        </li>
                      ))}
                    </>
                  ) : (
                    <EmptyMiddleBox title="There aren’t any cities with this name. Please try again." />
                  )}
                </ul>
              </div>
            </Grid>
          )}

          <Grid item md={showCities ? 10 : 12}>
            <Grid container spacing={3}>
              {showOverview && (
                <Grid item md={6}>
                  <div
                    className={`overview-wrapper ${showCities ? "" : "mt-0"}`}
                  >
                    <h2 className="title-primary mb-15">Overview</h2>
                    <div className="Scrollbars-wrapper">
                      <Scrollbars
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={"calc(100% - 48px)"}
                      >
                        <div
                          className={`${
                            showCities
                              ? "overview-content"
                              : "city-hide-overview"
                          } overview-spacing`}
                        >
                          <div
                            className="country-start-paragraph-margin"
                            dangerouslySetInnerHTML={{
                              __html: countryDetails.overview,
                            }}
                          ></div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item md={showOverview ? 6 : 12}>
                <div className={`location-wrapper ${showCities ? "" : "mt-0"}`}>
                  <h2 className="title-primary mb-15">Location on Map</h2>

                  <div className="map-wrapper">
                    <GoogleMapCustom
                      address={selectedCountryName}
                      label={selectedCountryName}
                      zoom={4}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="country-advisory-section">
              <h2 className="title-primary mt-0 mb-15">Useful links</h2>
              <ul>
                {renderMainLink("CIA Factbook", country_guide.cia_link, CIA)}

                {renderMainLink("Cost of Living", country_guide.numbeo, Numbeo)}

                {renderMainLink("Health Agency", country_guide.cdc_gov, CDC)}

                {renderMainLink("Travel", country_guide.lonely_planet, Lonely)}

                {renderMainLink(
                  "Travel Advisory",
                  country_guide.us_travel_advisory,
                  Advisory
                )}

                {renderMainLink(
                  "Culture",
                  country_guide.hofstede_insights,
                  Hofstede
                )}

                {renderMainLink(
                  "Jobs",
                  country_guide.job_site_link,
                  JobsLink,
                  true
                )}

                {renderMainLink(
                  "Immigration",
                  country_guide.immegration_website,
                  Immigration,
                  true
                )}

                {renderMainLink(
                  "Career Guidance",
                  country_guide.career_guidance,
                  CareerGuidance, // Icon needs to be updated here
                  true
                )}

                {renderMainLink(
                  "Networking",
                  country_guide.networking_link,
                  Networking, // Icon needs to be updated here
                  true
                )}
              </ul>
            </div>
            <div className="country-services-wrapper">
              {(country_guide.moving_link ||
                country_guide.relocating_link ||
                country_guide.visas_work_permit_link ||
                country_guide.housing_link ||
                country_guide.health_care_link ||
                country_guide.banks_taxes_link ||
                country_guide.education_link ||
                country_guide.living_link) && (
                <>
                  <h2 className="title-primary mt-0 mb-15">Guides</h2>
                  <ul>
                    {renderLink(
                      "Overview",
                      country_guide.moving_link,
                      `A comprehensive guide on relocating to ${selectedCountryName}`,
                      Overview
                    )}

                    {renderLink(
                      "Relocating",
                      country_guide.relocating_link,
                      `All you need to know about relocating your household goods and pets`,
                      Travel
                    )}

                    {renderLink(
                      "Visas & Work Permits",
                      country_guide.visas_work_permit_link,
                      `The guide to visa types and work permit requirements`,
                      Tax
                    )}

                    {renderLink(
                      "Housing",
                      country_guide.housing_link,
                      `Everything you need to know about finding a new home`,
                      Housing
                    )}

                    {renderLink(
                      "Healthcare",
                      country_guide.health_care_link,
                      `Health insurance and the healthcare system in ${selectedCountryName} explained`,
                      Insurance
                    )}

                    {renderLink(
                      "Banks & Taxes",
                      country_guide.banks_taxes_link,
                      `A comprehensive guide about opening a bank account and managing your taxes`,
                      Bank
                    )}

                    {renderLink(
                      "Education",
                      country_guide.education_link,
                      `A comprehensive guide about the education system and international schools`,
                      Scholarships
                    )}

                    {renderLink(
                      "Working",
                      country_guide.working_link,
                      `Your guide on jobs and finding work in ${selectedCountryName}`,
                      Jobs
                    )}

                    {renderLink(
                      "Living",
                      country_guide.living_link,
                      ` What you should know about living costs and more in ${selectedCountryName}`,
                      Health
                    )}
                  </ul>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CountryView;

import React, { useEffect, useLayoutEffect } from "react";

import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import { useEssentials } from "../../hooks/Profile/useEssentials";
import { useFetchProfile } from "../../hooks/Profile/useFetchProfile";
import { CollapseForm } from "./CollapseForm";
import Stepform from "./Stepform";
import { Loading } from "./Loading";
import GetStarted from "../../components/ProfileModule/GetStarted/GetStarted";
import { useAppState } from "../../context";
import { applyAccessibilityHelp, addAccessibilityClass } from "../../helper/helper";
import ProfileHeaderV2 from "../../components/ProfileModule/ProfileHeaderV2/ProfileHeaderV2";

export default function ProfileModule(props) {
  const { location } = props || {};
  const { getStarted } = useAppState("profile");

  // Custom Hooks call
  const state = useFetchProfile();
  const { profile, loading } = state || {};
  const { isProfileCompleted, accessibility_help } = profile || {};
  useEssentials();

  useEffect(() => {
    applyAccessibilityHelp(accessibility_help ? "true" : "false");
  }, [accessibility_help]);

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__withHeadingLayout-profile";
    addAccessibilityClass()
  }, []);

  return (
    <MainLayout
      title=""
      withSubHeader={false}
      tabs={[]}
      location={location}
      viewOnlyNavbar
      setShowSignOutPopup={true}
      containerClassName="profile-module__wrapper ac-profile-wrap"
    >
      <div className="profile-module-main-container">
        {loading || !profile ? (
          <>
            <ProfileHeaderV2 />
            <Loading />
          </>
        ) : isProfileCompleted ? (
          <>
            <ProfileHeaderV2 />
            <CollapseForm />
          </>
        ) : getStarted ? (
          <>
            <ProfileHeaderV2 />
            <GetStarted />
          </>
        ) : (
          <Stepform />
        )}
      </div>
    </MainLayout>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, Button, Avatar } from "@material-ui/core";
import { navigate, useParams } from "@reach/router";
import moment from "moment";
import { isUndefined, includes } from "lodash";

import BookAppointmentSearchStyles from "./BookAppointmentWithAdminStyles";
import LocationIcon from "../../../../assets/svg/f-location-pin.svg";
import BackArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";
import { useAppState } from "../../../../context";
import usePersistState from "../../../../state/usePersistState";
import usePromise from "../../../../hooks/usePromise/usePromise";
import {
  toastify,
  NoUserImage,
  transformIntoLabels,
  getUserFromList,
} from "../../../../helper/helper";

import SingleSelectCard from "../../../SingleSelectCard/SingleSelectCard";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import ViewProfileCard from "../ViewProfileCard/ViewProfileCard";
import AppointmentCalendar from "../AppointmentCalendar/AppointmentCalendar";
import TimezoneUpdateDialog from "../TimezoneUpdateDialog";

import {
  createAppointment,
  getAppointmentById,
  updateAppointment,
} from "../../../../services/appointmentServices";
import {
  getUsersAvailibility,
  getUsersByMeetingPurposeId,
  getUserTimeslots,
  getMeetingPurposes,
} from "../../../../services/meetingPurposeServices";
import { useDocumentTitle } from "../../../../hooks/useDocumentTitle";
import { cacheTimezone } from "../../../../utils/common";
import Loader from "../../../Loader/Loader";

const dateIncludes = (array, date, key = "available_date") => {
  let response = array.filter((i) => {
    return i[key] === date;
  });
  if (response.length === 0) {
    return false;
  }
  return true;
};

const BookAppointmentWithAdmin = () => {
  const params = useParams();
  const url = new URL(window.location.href);
  let bookingId = url.searchParams.get("bookingid");
  const {
    users = {},
    appointmentType,
    meetingPurposes,
    setAppointmentType,
    setMeetingPurposes,
  } = usePersistState();
  const { id: userId } = users || {};
  const { updateCalendarAppointment } = useAppState("tools");

  const [appointment, setAppointment] = useState(appointmentType);
  const [selectedProfile, setSelectedProfile] = useState(parseInt(params.id));
  const [list, setList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHourOption, setSelectedHourOption] = useState(0);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availibility, setAvailibility] = useState([]);
  const [timeslots, setTimeslots] = useState(null);
  const [selectedMonths, setSelectedMonths] = useState({});
  const [scheduledAppointment, setScheduledAppointment] = useState(null);
  const [timezoneId, setTimezoneId] = useState(null);
  const [timezones, setTimezones] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDateClassAdded, setIsDateClassAdded] = useState(false);
  const [completeAppointmentModal, setCompleteAppointmentModal] =
    useState(false);
  const [confirmCompleteAppointmentModal, setConfirmCompleteAppointmentModal] =
    useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState({
    value: users.time_zone,
    id: users.timezone_id,
  });
  const [initialMonths, setInitialMonths] = useState(null);

  const [, refreshCreateAppointment] = usePromise(createAppointment);
  const [, refreshUpdateAppointment] = usePromise(updateAppointment);
  const [callGetUsersByMeetingPurposeId, refreshGetUsersByMeetingPurposeId] =
    usePromise(getUsersByMeetingPurposeId);
  const [callGetUsersAvailibility, refreshGetUsersAvailibility] =
    usePromise(getUsersAvailibility);
  const [callGetUserTimeslots, refreshGetUserTimeslots] =
    usePromise(getUserTimeslots);
  const [, refreshGetAppointmentById] = usePromise(getAppointmentById);
  const [callGetMeetingPurposes, refreshGetMeetingPurposes] =
    usePromise(getMeetingPurposes);
  const [, setDocumentTitle] = useDocumentTitle("Book Appointment");

  useEffect(() => {
    setDocumentTitle("Book Appointment");
    if (!timezones?.length) {
      setTimezones(
        JSON.parse(sessionStorage.getItem("get_rails_timezone"))?.timezones
      );
    } else {
      cacheTimezone();
    }
  }, []);

  let selectedProfileData = {};
  if (list) {
    selectedProfileData =
      selectedProfile !== null
        ? getUserFromList(list, parseInt(params.id))
        : {};
  }

  useEffect(() => {
    if (
      callGetMeetingPurposes.hasFetched() &&
      !callGetMeetingPurposes.hasErrors()
    ) {
      let appointmentSettings =
        callGetMeetingPurposes.data().data.appointment_settings;
      let data = [];
      appointmentSettings.map((i) => {
        if (!includes(data, i.meeting_purpose_id)) {
          data.push(i.meeting_purpose_id);
        }
      });
      let meetingPurposes = transformIntoLabels(
        callGetMeetingPurposes.data().data.meeting_purposes,
        data
      );
      if (meetingPurposes.length === 1) {
        setAppointmentType(meetingPurposes[0].value);
      }
      setMeetingPurposes(meetingPurposes);
    }
  }, [callGetMeetingPurposes.isFetching()]);

  useEffect(() => {
    if (meetingPurposes.length === 0 || !appointmentType) {
      refreshGetMeetingPurposes();
    }
  }, []);

  useEffect(() => {
    if (users) {
      setSelectedTimezone({
        value: users.time_zone,
        id: users.timezone_id,
      });
    }
  }, [users]);

  useEffect(() => {
    let selectedElements = document.getElementsByClassName(
      "fc-day-span-appointment"
    );
    if (selectedElements.length > 0) {
      for (let i = 0; i < selectedElements.length; i++) {
        selectedElements[i].parentElement.setAttribute(
          "href",
          "javascript:void(0)"
        );
      }
    }
  }, [isDateClassAdded]);

  useEffect(() => {
    async function callMeetingPurpose() {
      if (meetingPurposes && meetingPurposes.length > 0) {
        setAppointment(meetingPurposes[0].value);
        let getList = await refreshGetUsersByMeetingPurposeId(
          appointmentType ?? meetingPurposes[0]?.value
        );
        if (typeof getList !== "undefined") {
          setList(getList.appointment_settings);
        }
      }
    }
    callMeetingPurpose();
  }, [meetingPurposes]);

  useEffect(() => {
    async function getAppointment() {
      let appointmentById = await refreshGetAppointmentById(
        parseInt(bookingId),
        selectedTimezone.value,
        selectedTimezone.id
      );
      setScheduledAppointment(appointmentById?.appointment);
      setAppointment(appointmentById?.appointment?.meeting_purpose.id);
      if (appointmentById?.appointment?.date) {
        const formattedAppointmentDate = new Date(
          appointmentById?.appointment?.date
        );
        const initMonthsData = {
          month: parseInt(moment(formattedAppointmentDate).format("MM")),
          year: formattedAppointmentDate.getFullYear(),
        };
        setSelectedDate(formattedAppointmentDate);
        setSelectedMonths(initMonthsData);
        setInitialMonths(initMonthsData);
      }
      const createdById = appointmentById?.appointment?.admin_user?.id;
      if (list.length > 0) {
        list.filter((i, k) => {
          if (i?.user?.id === createdById) {
            setSelectedProfile(k);
          }
          return i?.user?.id === createdById;
        });
      }
    }
    if (bookingId) {
      getAppointment();
    } else {
      setScheduledAppointment(null);
    }
  }, [bookingId, list]);

  useEffect(() => {
    const createdById = scheduledAppointment?.admin_user?.id;
    if (list.length > 0) {
      list.filter((i, k) => {
        if (i?.user?.id === createdById) {
          setSelectedProfile(k);
        }
        return i?.user?.id === createdById;
      });
    }
  }, [list, scheduledAppointment]);

  useEffect(() => {
    let data = [];
    if (
      typeof callGetUsersAvailibility.data()?.available_dates !== "undefined"
    ) {
      data = callGetUsersAvailibility.data()?.available_dates;
    }
    setAvailibility(data);
    if (bookingId && selectedDate === null && data && !isUndefined(data[0])) {
      setSelectedDate(new Date(data[0].available_date));
    }
  }, [callGetUsersAvailibility.isFetching()]);

  useEffect(() => {
    setTimeslots(callGetUserTimeslots.data());
  }, [callGetUserTimeslots.isFetching()]);

  useEffect(() => {
    if (timezones?.length > 0) {
      if (typeof selectedProfileData?.user?.id !== "undefined") {
        refreshGetUsersAvailibility(
          selectedMonths.month,
          selectedMonths.year,
          selectedProfileData?.user?.id,
          selectedTimezone.value,
          selectedTimezone.id
        );
      }
    }
  }, [selectedTimezone?.id, timezones]);

  useEffect(() => {
    if (
      selectedDate &&
      timezones?.length > 0 &&
      typeof availibility !== "undefined" &&
      availibility.length > 0 &&
      selectedProfileData?.user?.id
    ) {
      getTimeslots(moment(selectedDate).format("YYYY-MM-DD"));
    }
  }, [
    selectedDate,
    timezones,
    availibility,
    selectedProfileData?.user?.id,
    selectedTimezone?.id,
  ]);

  useEffect(() => {
    getTimeslots(moment(selectedDate).format("YYYY-MM-DD"));
  }, [selectedTimezone?.id]);

  useEffect(() => {
    if (
      callGetUsersByMeetingPurposeId.hasFetched() &&
      !callGetUsersByMeetingPurposeId.hasErrors() &&
      callGetUsersByMeetingPurposeId.data()
    ) {
      setList(callGetUsersByMeetingPurposeId.data().appointment_settings);
    }
  }, [callGetUsersByMeetingPurposeId.isFetching()]);

  useEffect(() => {
    if (typeof selectedProfileData?.user?.id !== "undefined") {
      return checkAvailability();
    }
  }, [selectedProfileData?.user?.id, selectedMonths?.month]);

  const checkAvailability = () => {
    let id = selectedProfileData?.user?.id;
    if (
      typeof id !== "undefined" &&
      typeof selectedMonths.month !== "undefined"
    ) {
      let selectedtz = (timezones ?? [])?.find(
        (i) => selectedTimezone?.id === i?.id
      );
      refreshGetUsersAvailibility(
        selectedMonths.month,
        selectedMonths.year,
        id,
        selectedtz?.timezone_name_value
          ? selectedtz?.timezone_name_value
          : selectedTimezone.value,
        selectedtz?.id ? selectedtz?.id : selectedTimezone.id
      );
      setTimezoneId(selectedtz?.id ? selectedtz?.id : selectedTimezone.id);
      if (!isUndefined(selectedtz)) {
        setSelectedTimezone({
          value: selectedtz?.timezone_name_value,
          id: selectedtz?.id,
        });
      }
    }
  };

  const getTimeslots = (date, selectedType = selectedHourOption) => {
    let adminId = selectedProfileData?.user?.id;
    let selectedtz = (
      timezones && Array.isArray(timezones) ? timezones : []
    ).find((i) => selectedTimezone.id === i.id);
    let formattedDate = moment(date).format("YYYY-MM-DD");
    let start_time = (availibility || []).find((i) => {
      return i.available_date === formattedDate;
    });
    try {
      if (
        typeof start_time !== "undefined" &&
        (typeof selectedtz?.id !== "undefined" ||
          typeof selectedTimezone.id !== "undefined")
      ) {
        let response = refreshGetUserTimeslots(
          formattedDate,
          adminId,
          selectedtz?.timezone_name_value
            ? selectedtz?.timezone_name_value
            : selectedTimezone.value,
          selectedType,
          selectedtz?.id ? selectedtz?.id : selectedTimezone.id,
          start_time?.start_time
        );
        setTimezoneId(selectedtz?.id ? selectedtz?.id : selectedTimezone.id);
        if (typeof response !== "undefined") {
          response
            .then((res) => {
              if (typeof res.data !== "undefined" && !res.data.success) {
                toastify("error", res.data.message);
                setTimeslots([]);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const endTime =
    selectedHourOption === 1
      ? moment(selectedTime, "HH:mm")
          .add(60, "minutes")
          .format("hh:mma")
          .replace(/^0+/, "")
      : moment(selectedTime, "HH:mm")
          .add(30, "minutes")
          .format("hh:mma")
          .replace(/^0+/, "");

  const endTimeValue =
    selectedHourOption === 1
      ? moment(selectedTime, "HH:mm").add(60, "minutes").format("HH:mm")
      : moment(selectedTime, "HH:mm").add(30, "minutes").format("HH:mm");

  const completeBooking = () => {
    setIsSubmitting(true);

    if (bookingId) {
      refreshUpdateAppointment(
        userId,
        selectedProfileData?.user?.id,
        selectedTime,
        endTimeValue,
        moment(selectedDate).format("YYYY-MM-DD"),
        selectedTimezone.value,
        bookingId,
        timezoneId
      )
        .then((response) => {
          setConfirmCompleteAppointmentModal(true);
          setIsSubmitting(false);
          setCompleteAppointmentModal(false);
          updateCalendarAppointment(bookingId, response.appointment);
        })
        .catch((err) => {
          setIsSubmitting(false);
          setCompleteAppointmentModal(false);
          let errorMessage = err?.data?.message;
          toastify(
            "error",
            errorMessage
              ? errorMessage
              : "Something went wrong. Please try again."
          );
        });
    } else {
      refreshCreateAppointment(
        userId,
        selectedProfileData?.user?.id,
        selectedProfileData?.appointment_setting?.title,
        selectedProfileData?.appointment_setting?.description,
        selectedProfileData?.appointment_setting?.meeting_location,
        selectedProfileData?.appointment_setting?.meeting_link,
        selectedTime,
        endTimeValue,
        moment(selectedDate).format("YYYY-MM-DD"),
        selectedTimezone.value,
        timezoneId
      )
        .then(() => {
          setIsSubmitting(false);
          setCompleteAppointmentModal(false);
          setConfirmCompleteAppointmentModal(true);
        })
        .catch((err) => {
          setIsSubmitting(false);
          setCompleteAppointmentModal(false);
          let errorMessage = err?.data?.message;
          toastify(
            "error",
            errorMessage
              ? errorMessage
              : "Something went wrong. Please try again."
          );
        });
    }
  };
  let displayName = "";
  if (selectedProfileData?.user) {
    displayName =
      selectedHourOption === 1
        ? "One hour meeting with "
        : "Half an hour meeting with ";
    displayName +=
      selectedProfileData?.user?.first_name +
      " " +
      selectedProfileData?.user?.last_name;
  }

  return (
    <BookAppointmentSearchStyles>
      <div className="book-appointment-search-wrapper">
        <div className="f-main-title-with-button mb-30">
          <div className="back-btn-wrapper">
            <a
              className="shadow-btn"
              onClick={(e) => {
                let back_url = "";
                if (bookingId) {
                  back_url = "/tools/meet";
                } else {
                  back_url = "/tools/book-meeting";
                }
                e.preventDefault();
                navigate(back_url);
              }}
              href="#"
              aria-label="Back"
            >
              <img
                src={BackArrow}
                id="BackArrow"
                width="18px"
                alt="Back arrow"
              />
              <span>Back</span>
            </a>
          </div>
          <h2 className="f-main-title mb-0 w-100">Book meeting</h2>
        </div>
        <div className="f-search-wrapper">
          <div className="filter-item">
            <label>Filter</label>
            <div className="filter-item-content">
              <Button
                name="halfHour"
                className={selectedHourOption === 0 ? "active item " : "item"}
                onClick={() => {
                  setSelectedHourOption(0);
                  if (selectedDate) {
                    getTimeslots(selectedDate, 0);
                  }
                }}
                aria-label={"Half hour"}
                disableRipple={true}
              >
                Half hour
              </Button>
              <Button
                name="hour"
                className={selectedHourOption === 1 ? "active item " : "item"}
                onClick={() => {
                  setSelectedHourOption(1);
                  if (selectedDate) {
                    getTimeslots(selectedDate, 1);
                  }
                }}
                aria-label={"Hour"}
                disableRipple={true}
              >
                Hour
              </Button>
            </div>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <ViewProfileCard
              data={selectedProfileData}
              scheduledAppointment={scheduledAppointment}
              appointmentType={appointment}
            />
          </Grid>

          <Grid item xs={9}>
            <h2 className="title-primary">Select a date & time</h2>
            <Grid container spacing={3}>
              <Grid item xs={selectedDate && selectedDate !== null ? 8 : 12}>
                {bookingId && !initialMonths ? (
                  <Loader />
                ) : (
                  <AppointmentCalendar
                    selectedDate={selectedDate !== null && selectedDate}
                    setSelectedDate={setSelectedDate}
                    initialMonths={initialMonths}
                    dayCellContent={(info, create) => {
                      setIsDateClassAdded(false);
                      const formatDate = moment(info.date).format("YYYY-MM-DD");
                      if (
                        typeof availibility !== "undefined" &&
                        availibility.length !== 0
                      ) {
                        if (dateIncludes(availibility, formatDate)) {
                          let addClass = "fc-day-span-appointment";
                          if (
                            scheduledAppointment &&
                            scheduledAppointment.date === formatDate
                          ) {
                            addClass += " selectedDate selectedAppointment";
                          }
                          if (
                            moment(selectedDate).format("YYYY-MM-DD") ===
                            formatDate
                          ) {
                            addClass += " selectedDate";
                          }
                          const element = create(
                            "span",
                            { class: addClass },
                            info.dayNumberText
                          );
                          setIsDateClassAdded(true);
                          return element;
                        } else {
                          let addClass = "fc-day-span-non-appointment";
                          const element = create(
                            "span",
                            { class: addClass },
                            info.dayNumberText
                          );
                          return element;
                        }
                      }
                    }}
                    dateClick={(info) => {
                      if (
                        typeof availibility !== "undefined" &&
                        dateIncludes(availibility, info.dateStr)
                      ) {
                        getTimeslots(info.dateStr);
                        setSelectedDate(info.date);
                      } else {
                        setSelectedDate(null);
                      }
                    }}
                    showNonCurrentDates={false}
                    setSelectedMonths={setSelectedMonths}
                  />
                )}
              </Grid>

              {selectedDate && selectedDate !== null && (
                <Grid item xs={4}>
                  <CardCustom className="meet-time-wrapper">
                    <div className="title-primary mb-25 text-center pt-30">
                      {moment(selectedDate).format("MMM DD, YYYY")}
                    </div>
                    <TimezoneUpdateDialog
                      setSelectedTimezone={setSelectedTimezone}
                      hideButton={true}
                      updateTimezone={false}
                    />

                    <SingleSelectCard
                      value={selectedTime}
                      onClick={(value) => {
                        setSelectedTime(value);
                        setCompleteAppointmentModal(true);
                      }}
                      options={timeslots}
                      changeOptionsFormat={true}
                      showEmptyPopup={true}
                      timeslotsLoading={callGetUserTimeslots.isFetching()}
                    />
                  </CardCustom>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {completeAppointmentModal && (
          <CustomDialog
            open={completeAppointmentModal}
            title={bookingId ? "Reschedule meeting" : "Book meeting"}
            handleClose={() => {
              setCompleteAppointmentModal(false);
            }}
            className="delete-saved-search-popup"
            dialogTitleClass="modal-title"
            dialogSubTitleClass="text-para"
            isDisplayCloseButton={true}
          >
            <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
              <div className="profile-img mt-15">
                <Avatar
                  src={
                    selectedProfileData?.user?.user_profile_url || NoUserImage
                  }
                  alt="No user placeholder"
                />
              </div>
              <div className="modal-field meeeting-date">
                {moment(selectedDate).format("dddd MMMM Do, YYYY")}
              </div>
              <div className="modal-field display-name">{displayName}</div>
              <div className="modal-field meeeting-time">
                {moment(selectedTime, "HH:mm")
                  .format("hh:mma")
                  .replace(/^0+/, "")}{" "}
                - {endTime}
              </div>
              {selectedProfileData?.location &&
              selectedProfileData?.meeting_type === "in_person" ? (
                <div className="modal-field meeting-location">
                  <img src={LocationIcon} alt="Location pin" />
                  <span>{selectedProfileData?.location}</span>
                </div>
              ) : (
                <div className="modal-field meeting-location">
                  <img src={LocationIcon} alt="Location pin" />
                  {selectedProfileData?.join_url ? (
                    <a
                      href={selectedProfileData?.join_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Meeting Link"
                      className="zoom-link"
                    >
                      Meeting Link
                    </a>
                  ) : (
                    <span>Zoom link to be provided via email</span>
                  )}
                </div>
              )}
              <div className="btn">
                <ButtonCustom
                  width="238"
                  height={50}
                  onClick={() => completeBooking()}
                  isSubmitting={isSubmitting}
                >
                  Book meeting
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
        )}

        {confirmCompleteAppointmentModal && (
          <CustomDialog
            open={confirmCompleteAppointmentModal}
            title={"Booking complete"}
            handleClose={() => {
              setConfirmCompleteAppointmentModal(false);
              navigate("/tools/calendar", { replace: true });
            }}
            className="delete-saved-search-popup"
            dialogTitleClass="modal-title"
            dialogSubTitleClass="text-para"
            isDisplayCloseButton={false}
          >
            <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
              <div className="description-note">
                You will receive an email confirmation with complete details.
              </div>
              <div className="btn">
                <ButtonCustom
                  width="238"
                  height={50}
                  onClick={() => {
                    window.location.href = "/tools/meet";
                    setConfirmCompleteAppointmentModal(false);
                  }}
                >
                  Back to Calendar
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
        )}
      </div>
    </BookAppointmentSearchStyles>
  );
};

export default BookAppointmentWithAdmin;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Masonry from "react-masonry-css";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  masonryGrid: {
    display: "flex",
    marginLeft: theme.spacing(-2),
    width: "inherit",
  },
  masonryColumn: {
    paddingLeft: theme.spacing(2),
    backgroundClip: "padding-box",
  },
}));

const propTypes = {
  children: PropTypes.node,
};

const BreakpointMasonry = ({ children, grid = 2 }) => {
  const classes = useStyles();
  const theme = useTheme();

  const breakpointCols = {
    default: grid,
    [theme.breakpoints.values.xl]: grid,
    [theme.breakpoints.values.lg]: grid,
    [theme.breakpoints.values.md]: 1,
    [theme.breakpoints.values.sm]: 1,
    [theme.breakpoints.values.xs]: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointCols}
      className={classes.masonryGrid}
      columnClassName={classes.masonryColumn}
    >
      {children}
    </Masonry>
  );
};

BreakpointMasonry.propTypes = propTypes;

export default BreakpointMasonry;

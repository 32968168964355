import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Popover, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import OPTGuideStyles from "./OPTGuideStyles";
import {
  resetOPTInitialState,
  updateOPTIndex,
} from "../../../redux/reducers/optReducer";

import CalculatorIcon from "../../../assets/svg/CalculatorColored.svg";
import Notealert from "../../../assets/svg/note-alert.svg";

const OPTGuide = () => {
  const dispatch = useDispatch();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const optLocalStorageItem = JSON.parse(
    localStorage.getItem("interstride_opt")
  );

  const [showPopover, setPopover] = useState(null);
  const openPopover = Boolean(showPopover);
  const idPopover = openPopover ? "opt-subtitle-popover" : undefined;

  useEffect(() => {
    if (!optLocalStorageItem || isEmpty(optLocalStorageItem)) {
      dispatch(resetOPTInitialState());
    }
  }, []);

  const handleInfoClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };

  return (
    <OPTGuideStyles>
      <div className="opt-guide-hero-banner">
        <div className="opt-guide-hero-banner__logo-container">
          <img src={CalculatorIcon} alt="calculator" />
        </div>
        <div className="opt-guide-hero-banner__summary">
          <h2>
            Post-Completion Optional Practical Training (OPT) Planning Tool{" "}
          </h2>
          <p>Calculate your OPT eligibility and important deadlines</p>
          <ButtonCustom
            width={248}
            className="focus-white"
            onClick={() => dispatch(updateOPTIndex(0))}
          >
            Get started
          </ButtonCustom>
        </div>
      </div>
      <div className="opt-guide-description">
        <h3>
          What is Post-Completion OPT?
          <a
            href="/#"
            onClick={handleInfoClick}
            className="alert-icon information-icon"
            aria-label="Find Job Note"
          >
            <img src={Notealert} alt="Note" />
          </a>
        </h3>
        <Popover
          id={idPopover}
          open={openPopover}
          anchorEl={showPopover}
          onClose={handlePopoverClose}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Typography>
            Read more about OPT here:{" "}
            <a
              href="https://interstride.com/blog/optional-practical-training-opt-for-f-1-students/"
              target="_blank"
              rel="noopener noreferrer"
              className="popover-link"
            >
              Optional practical training (OPT) for F-1 students
            </a>
          </Typography>
          {accessibilityHelp === "true" && (
            <div className="close-button">
              <ButtonCustom onClick={handlePopoverClose}>Got It!</ButtonCustom>
            </div>
          )}
        </Popover>
        <p>
          OPT is a 12-month work authorization program that permits
          international students to gain professional experience without having
          to apply for another visa. Post-completion OPT allows you to work in
          the U.S. after graduation in a role that is directly related to your
          area of study.
        </p>
      </div>
    </OPTGuideStyles>
  );
};

export default OPTGuide;

import { CheckCircle } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
export const ProfileCheckbox = ({ handleClick, defaultState = false, text }) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const [checked, setChecked] = useState(defaultState);
  const handleOnClick = (e) => {
    e.preventDefault();
    setChecked(!checked);
    handleClick && handleClick();
  };
  return !checked ? (
    <a
      href="#"
      style={{
        display: "block",
        boxSizing: "border-box",
        height: "17px",
        width: "17px",
        borderRadius: "9px",
        cursor: "pointer",
        background: "#f1f4f7",
      }}
      onClick={handleOnClick}
      aria-label={text}
    ></a>
  ) : (
    <>
      {
        accessibilityHelp == "true" ? (
          <a href="#" onClick={handleOnClick} aria-label={text} style={{
            display: "block",
            boxSizing: "border-box",
            height: "17px",
            width: "17px",
            borderRadius: "9px",
            cursor: "pointer",
            background: "#f1f4f7",
          }}>
            <CheckCircle
              style={{
                cursor: "pointer",
                width: "17px",
                height: "17px",
              }}

              color={"primary"}
            />
          </a>
        ) : (
          <a href="#" onClick={handleOnClick} aria-label={text} style={{
            cursor: "pointer",
            background: "none",
            border: "none"
          }}>
            <CheckCircle
              style={{
                cursor: "pointer",
                width: "17px",
                height: "17px",
              }}

              color={"primary"}
            />
          </a>
        )
      }
    </>
  );
};

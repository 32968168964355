import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { isNull, toString } from "lodash";
import { Grid, Avatar, Popover, Typography, Tooltip } from "@material-ui/core";

import FeaturedJobsListStyles from "./FeaturedJobsListStyles";
import CardCustom from "../../CardCustom/CardCustom";
import { getFeaturedJobsAPI } from "../../../services/JobServices";
import { getFilteredEmployerJobsAPI } from "../../../services/EmployerJobServices";
import { allNotificationsCountAPI } from "../../../services/notificationServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  capitalizeFirstLetter,
  processJobLocationTime,
} from "../../../helper/helper";
import EmptyListWithImage from "../../EmptyListWithImage/EmptyListWithImage";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import usePersistState from "../../../state/usePersistState";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import SearchFilter from "../../../components/Jobs/SearchFilter/SearchFilter";
import { US_STATE_OPTIONS, MAX_TITLE_LENGTH } from "../../../constant/mockdata";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import { updateFavouriteJobsAPI } from "../../../services/JobServices";
import { useSavedJobs } from "../../../hooks/useSavedJobs";
import { toastify } from "../../../helper/helper";
import StarIcon from "../../StarIcon/StarIcon";
import { Mixpanel } from "../../../mixpanel";

import RSS from "../../../assets/png/handshake_rss_logo.png";
import Notealert from "../../../assets/svg/note-alert.svg";

const FeaturedJobsList = () => {
  const LONG_FEATURED_JOB_TITLE_MAX_LENGTH = MAX_TITLE_LENGTH + 50; // As we have double space here

  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const {
    users = {},
    setSelectedJob,
    jobsCountryList = [],
    setAllNotifications,
  } = usePersistState();

  const { id: userId } = users || {};

  const defaultFilters = {
    search: "",
    country: null,
    state: null,
    city: null,
    publish_date: null,
    job_type: null,
  };

  const [adminJobsList, setAdminJobsList] = useState([]);
  const [employerJobsList, setEmployerJobsList] = useState([]);
  const [showPopover, setPopover] = useState(null);
  const [currentJobsFilter, setCurrentJobsFilter] = useState({
    ...defaultFilters,
  });
  const [showLoader, setLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [callAdminJobsAPI, refreshAdminJobsAPI] =
    usePromise(getFeaturedJobsAPI);
  const [callGetFilterEmployerJobsAPI, refreshGetFilterEmployerJobsAPI] =
    usePromise(getFilteredEmployerJobsAPI);
  const [callingUpdateFavouriteJobsAPI, refreshUpdateFavouriteJobsAPI] =
    usePromise(updateFavouriteJobsAPI);

  const { savedJobs = [], refreshSavedJobs = () => {} } = useSavedJobs();

  useEffect(() => {
    applyFilters(currentJobsFilter);
  }, []);

  useEffect(() => {
    if (
      callingUpdateFavouriteJobsAPI.hasFetched() &&
      callingUpdateFavouriteJobsAPI.hasErrors() === false &&
      callingUpdateFavouriteJobsAPI.data() &&
      callingUpdateFavouriteJobsAPI.data().message
    ) {
      toastify("success", callingUpdateFavouriteJobsAPI.data().message);
      refreshSavedJobs();
    }
  }, [callingUpdateFavouriteJobsAPI.isFetching()]);

  const updateFavourites = (params) => {
    refreshUpdateFavouriteJobsAPI(params);
  };

  const favJobKeyList = savedJobs.map(({ job_id }) => job_id);

  useEffect(() => {
    if (currentJobsFilter.country && jobsCountryList.length > 0) {
      const selectedCountry = jobsCountryList.find(
        (rec) => rec.value === currentJobsFilter.country
      );
      setSelectedCountry(selectedCountry);
    } else {
      setSelectedCountry(null);
    }
  }, [currentJobsFilter.country]);

  const applyFilters = (newParams = {}) => {
    setLoader(true);

    setAdminJobsList([]);
    setEmployerJobsList([]);

    const { search, country, state, city, job_type, publish_date } = newParams;

    const params = {
      search: search !== "" ? search : null,
      country:
        !isNull(country) && selectedCountry && !isNull(selectedCountry)
          ? selectedCountry.label
          : null,
      city: city !== "" ? city : null,
      publish_date: publish_date && publish_date !== "" ? publish_date : null,
      job_type,
    };

    if (country && country === "us") {
      const selectedState =
        state !== null
          ? US_STATE_OPTIONS.find((rec) => rec.value === state)
          : null;

      if (selectedState && selectedState !== null)
        params.state = selectedState.value;
    }

    refreshGetFilterEmployerJobsAPI(users?.id, {
      ...params,
      sort: "date",
    });
    refreshAdminJobsAPI({
      ...params,
      sort: "date",
      read: "all",
    });
    refreshAllNotificationsCount();
  };

  useEffect(() => {
    if (
      callGetFilterEmployerJobsAPI.hasFetched() &&
      callGetFilterEmployerJobsAPI.hasErrors() === false
    ) {
      if (
        callGetFilterEmployerJobsAPI.data() &&
        callGetFilterEmployerJobsAPI.data().data
      ) {
        const { employer_jobs = [] } = callGetFilterEmployerJobsAPI.data().data;
        setEmployerJobsList(employer_jobs);
      } else {
        setEmployerJobsList([]);
      }
    }
  }, [callGetFilterEmployerJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callAdminJobsAPI.hasFetched() &&
      callAdminJobsAPI.hasErrors() === false
    ) {
      if (
        callAdminJobsAPI.data() &&
        callAdminJobsAPI.data().data &&
        callAdminJobsAPI.data().data.jobs
      ) {
        setAdminJobsList(callAdminJobsAPI.data().data.jobs);
        setLoader(false);
      }
    }
  }, [callAdminJobsAPI.isFetching()]);

  const clearAll = () => {
    setSelectedCountry(null);
    setCurrentJobsFilter({
      ...defaultFilters,
      city: "",
    });
    applyFilters({ ...defaultFilters });
  };

  const handleFeaturedEmployerJobClick = (data, websiteUrl, e) => {
    // No need to handle job card click on star icon click
    if (e?.target?.id?.includes("Star")) return;
    e?.preventDefault && e.preventDefault();

    if (!data.applicant_status)
      Mixpanel.track("Job apply click", {
        job_source: "ep_featured",
        job_title: data.title,
        job_employer_name: data.name,
        job_country: data.country_name,
        job_state: data.state_name,
        job_city: data.city_name,
        job_full_location: `${
          data.city_name !== null && data.city_name !== "null"
            ? `${data.city_name}${
                data.state_name !== null && data.state_name !== "null"
                  ? ","
                  : ""
              }`
            : ""
        } ${
          data.state_name !== null && data.state_name !== "null"
            ? data.state_name
            : ""
        }`,
      });

    if (data.details === "url") window.open(websiteUrl);
    else {
      setSelectedJob(data);
      const { permalink } = data;
      if (permalink) navigate(`/jobs/job-details/${permalink}?type=featured`);
      else navigate("jobs/job-details");
    }
  };

  const handleFeaturedAdminJobClick = (data, websiteUrl, e) => {
    // No need to handle job card click on star icon click
    if (e?.target?.id?.includes("Star")) return;
    e?.preventDefault && e.preventDefault();

    Mixpanel.track("Job apply click", {
      job_source: "ap_featured",
      job_title: data?.job_title,
      job_employer_name: data?.company,
      job_country: data.country,
      job_state: data?.state,
      job_city: data?.city,
      job_full_location: `${
        data?.city !== null && data?.city !== "null"
          ? `${capitalizeFirstLetter(data?.city)}${
              data?.state !== null && data?.state !== "null" ? "," : ""
            }`
          : ""
      } ${data?.state !== null && data?.state !== "null" ? data?.state : ""}`,
    });

    if (websiteUrl) window.open(websiteUrl);
  };
  const refreshAllNotificationsCount = () => {
    setTimeout(async () => {
      try {
        const response = await allNotificationsCountAPI();
        if (response?.success) {
          // Update Persist State
          setAllNotifications(response?.data);
        }
      } catch (error) {
        console.error(error);
      }
    }, 2000);
  };

  const renderEmployerJobs = (data, i) => {
    let websiteUrl = data.url;
    if (data.details === "url" && !/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }

    return (
      <Grid item xs={12} key={`employer-job-${i}`}>
        <CardCustom
          onClick={(e) => handleFeaturedEmployerJobClick(data, websiteUrl, e)}
        >
          <div className="search-item employer-job">
            <div className="avtar">
              {data.logo.url && data.logo.url !== null ? (
                <Avatar variant="rounded" alt="" src={data.logo.url} />
              ) : (
                <InitialAvatar
                  variant="rounded"
                  title={data.name}
                  index={i}
                  initialCount={1}
                />
              )}
            </div>
            <div className="content">
              <div className="header-primary">
                {data.title &&
                data.title.length > LONG_FEATURED_JOB_TITLE_MAX_LENGTH ? (
                  <Tooltip title={capitalizeFirstLetter(data.title)}>
                    <span>
                      {capitalizeFirstLetter(
                        data.title.substring(
                          0,
                          LONG_FEATURED_JOB_TITLE_MAX_LENGTH
                        )
                      )}
                      ...
                    </span>
                  </Tooltip>
                ) : (
                  <span>{capitalizeFirstLetter(data.title)}</span>
                )}
              </div>
              <div className="sub-header-primary">{data.name}</div>
              <div className="text-grey">
                {processJobLocationTime({
                  city: data?.city_name,
                  state: data?.state_code,
                  country: !["United States", "USA", "US"].includes(
                    data?.country_name
                  )
                    ? data?.country_name
                    : null,
                  time: data?.postedon,
                })}
              </div>
            </div>
            {data.applicant_status && data.applicant_status !== null ? (
              <>
                <div
                  className={`card-actions-wrapper ${
                    accessibilityHelp == "true" ? "show-actions" : ""
                  }`}
                >
                  <div className="cursor-pointer">
                    <a
                      href={`jobs/job-details/${data?.permalink ?? ""}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nlink-btn bg-gray"
                      aria-label="Already Applied"
                      onClick={(e) => e.preventDefault()}
                    >
                      Applied
                    </a>
                  </div>
                </div>
                {favJobKeyList.includes(toString(data?.jobid)) ? (
                  <StarIcon
                    aria-label={`Remove ${data.title} from saved jobs`}
                    variant="filled"
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "unlike",
                        job_title: data.title,
                      });
                    }}
                    className="ml-8"
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${data.title} to saved jobs`}
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "like",
                        job_title: data.title,
                        city: data.city_name,
                        state: data.state_code,
                        country: !["United States", "USA", "US"].includes(
                          data?.country_name
                        )
                          ? data?.country_name
                          : null,
                        company: data.employer_name,
                        url: websiteUrl,
                      });
                    }}
                    className={`card-actions-wrapper ml-8 ${
                      accessibilityHelp == "true" ? "show-actions" : ""
                    }`}
                  />
                )}
              </>
            ) : (
              <>
                <div
                  className={`card-actions-wrapper ${
                    accessibilityHelp == "true" ? "show-actions" : ""
                  }`}
                >
                  <div>
                    <a
                      href={`jobs/job-details/${data?.permalink ?? ""}`}
                      className="nlink-btn nbg-secondary"
                      aria-label={`Apply for ${data.title}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.preventDefault()}
                    >
                      Apply
                    </a>
                  </div>
                </div>
                {favJobKeyList.includes(toString(data?.jobid)) ? (
                  <StarIcon
                    aria-label={`Remove ${data.title} from saved jobs`}
                    variant="filled"
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "unlike",
                        job_title: data.title,
                      });
                    }}
                    className="ml-8"
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${data.title} to saved jobs`}
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "like",
                        job_title: data.title,
                        city: data.city_name,
                        state: data.state_code,
                        country: !["United States", "USA", "US"].includes(
                          data?.country_name
                        )
                          ? data?.country_name
                          : null,
                        company: data.employer_name,
                        url: websiteUrl,
                      });
                    }}
                    className={`card-actions-wrapper ml-8 ${
                      accessibilityHelp == "true" ? "show-actions" : ""
                    }`}
                  />
                )}
              </>
            )}
          </div>
        </CardCustom>
      </Grid>
    );
  };

  const renderAdminJobs = (data, i) => {
    let websiteUrl = data.link;
    if (!/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }
    return (
      <Grid item xs={12} key={`featured-job-${i}`}>
        {data.rss_feed_id && data.rss_feed_id != null ? (
          <img src={RSS} className="custom-rss-icon" alt="Webinar-Rss-Image" />
        ) : (
          ""
        )}
        <CardCustom
          onClick={(e) => handleFeaturedAdminJobClick(data, websiteUrl, e)}
        >
          <div className="search-item featured-job">
            <div className="avtar">
              {data.logo_url && data.logo_url !== null ? (
                <img
                  alt="Avatar"
                  src={data.logo_url}
                  style={{
                    "border-radius": "4px",
                    height: "50px",
                    "max-width": "50px",
                  }}
                />
              ) : (
                <InitialAvatar
                  variant="rounded"
                  title={data.company}
                  index={i}
                  initialCount={1}
                />
              )}
            </div>
            <div className="content">
              <div className="header-primary">
                {data.job_title &&
                data.job_title.length > LONG_FEATURED_JOB_TITLE_MAX_LENGTH ? (
                  <Tooltip title={capitalizeFirstLetter(data.job_title)}>
                    <span>
                      {capitalizeFirstLetter(
                        data.job_title.substring(
                          0,
                          LONG_FEATURED_JOB_TITLE_MAX_LENGTH
                        )
                      )}
                      ...
                    </span>
                  </Tooltip>
                ) : (
                  <span>{capitalizeFirstLetter(data.job_title)}</span>
                )}
              </div>
              <div className="sub-header-primary">{data.company}</div>
              <div className="text-grey">
                {processJobLocationTime({
                  city: data.city,
                  state: data.state,
                  country:
                    data.country !== "United States" &&
                    data.country !== "USA" &&
                    data.country !== "US" &&
                    data.country,
                  time: data.created_at,
                })}
              </div>
            </div>
            <div
              className={`card-actions-wrapper ${
                accessibilityHelp == "true" ? "show-actions" : ""
              }`}
            >
              <div>
                <a
                  href={websiteUrl ?? "/#"}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="nlink-btn nbg-secondary"
                  aria-label={`Apply for ${data.job_title}`}
                  onClick={(e) => e.preventDefault()}
                >
                  Apply
                </a>
              </div>
            </div>
            {favJobKeyList.includes(toString(data.id)) ? (
              <StarIcon
                aria-label={`Remove ${data.job_title} from saved jobs`}
                variant="filled"
                onClick={() => {
                  updateFavourites({
                    userId,
                    featured_job_id: data.id,
                    like: "unlike",
                    job_title: data.job_title,
                  });
                }}
                className="ml-8"
              />
            ) : (
              <StarIcon
                aria-label={`Add ${data.job_title} to saved jobs`}
                onClick={() => {
                  updateFavourites({
                    userId,
                    featured_job_id: data.id,
                    like: "like",
                    job_title: data.job_title,
                    city: data.city,
                    state: data.state,
                    company: data.company,
                    country:
                      data.country !== "United States" &&
                      data.country !== "USA" &&
                      data.country !== "US"
                        ? data.country
                        : null,
                    url: websiteUrl,
                    publish_date: data.created_at,
                    logo_url: data.logo_url,
                  });
                }}
                className={`card-actions-wrapper ml-8 ${
                  accessibilityHelp == "true" ? "show-actions" : ""
                }`}
              />
            )}
          </div>
        </CardCustom>
      </Grid>
    );
  };

  const totalJobs =
    (employerJobsList ? employerJobsList.length : 0) +
    (adminJobsList ? adminJobsList.length : 0);

  const handleClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handleClose = () => {
    setPopover(null);
  };

  const open = Boolean(showPopover);
  const id = open ? "simple-popover" : undefined;

  return (
    <FeaturedJobsListStyles>
      <div className="search-mainpage featured-jobs-list">
        <div className="job-section">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SearchFilter
                filter={currentJobsFilter}
                visaFilter={false}
                searchType={
                  currentJobsFilter.country &&
                  currentJobsFilter.country === "us"
                    ? "us"
                    : "international"
                }
                onClearAllClick={clearAll}
                setFilter={(changedFilters) => {
                  setCurrentJobsFilter({
                    ...currentJobsFilter,
                    ...changedFilters,
                  });
                }}
                applyFilters={() => {
                  applyFilters(currentJobsFilter);
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <div>
                <div className="job-title mb-15 search-top">
                  {totalJobs > 0 && (
                    <h2 className="title-primary mb-0">
                      Featured Jobs
                      <a
                        href="/#"
                        onClick={handleClick}
                        className="alert-icon information-icon"
                        aria-label="Featured Job Note"
                      >
                        <img src={Notealert} alt="Note" />
                      </a>
                    </h2>
                  )}
                </div>

                {callGetFilterEmployerJobsAPI.isFetching() ||
                callAdminJobsAPI.isFetching() ||
                showLoader ? (
                  <JobsListingEffect count={5} />
                ) : (
                  <>
                    {totalJobs && totalJobs > 0 ? (
                      <>
                        <Grid
                          container
                          spacing={2}
                          className={
                            (employerJobsList && employerJobsList.length) > 0 ||
                            (adminJobsList && adminJobsList.length) > 0
                              ? "featured-jobs-list"
                              : ""
                          }
                        >
                          {/* Group employer and admin jobs and sort with latest jobs coming first in listing */}
                          {[...employerJobsList, ...adminJobsList]
                            ?.sort(
                              (a, b) =>
                                new Date(b?.postedon || b?.created_at) -
                                new Date(a?.postedon || a?.created_at)
                            )
                            ?.map((data, i) =>
                              data?.role === "employer" || data?.role === "hr"
                                ? renderEmployerJobs(data, i)
                                : renderAdminJobs(data, i)
                            )}
                        </Grid>
                      </>
                    ) : (
                      <EmptyListWithImage
                        title={`Looks like there are no featured jobs with this search.`}
                        subTitle={`Try removing some filters to expand your search and get more results.`}
                      />
                    )}
                  </>
                )}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={showPopover}
                  onClose={handleClose}
                  className="popover-wrapper"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Typography>
                    <p>
                      Featured opportunities are sourced directly from employers
                      or Interstride partners.
                    </p>
                    <p>
                      By clicking on the employment opportunity links [or job
                      postings] below, you are agreeing to share information,
                      including personal information with third party employers
                      and/or other recruiting platforms.
                    </p>
                  </Typography>
                  {accessibilityHelp == "true" && (
                    <div className="close-button">
                      <ButtonCustom onClick={handleClose}>Got It!</ButtonCustom>
                    </div>
                  )}
                </Popover>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </FeaturedJobsListStyles>
  );
};
export default FeaturedJobsList;

import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .company {
    .top-section {
      .company-title {
        .title-primary {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: flex;
          .star {
            margin: -3px 0px 0px 8px;
          }
        }
        .ViewDetails {
          display: none;
        }
      }
      .custom-tab {
        display: flex;
        width: calc(100% - 345px);
        @media (max-width: 1366px) {
          width: 100%;
        }
        @media (max-width: 600px) {
          flex-wrap: wrap;
          justify-content: center;
        }
        button {
          border-radius: 40px;
          background-color: #ffffff;
          box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
          width: 148px;
          height: 50px;
          padding: 18px 10px 15px 10px;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 19px;
          color: var(--primary-dark-color);
          text-transform: inherit;
          + button {
            margin-left: 10px;
          }
          &.active {
            background-color: ${PRIMARY_COLOR};
            color: #fff;
            border-color: ${PRIMARY_COLOR};
          }
          &.disabled {
            color: var(--light-gray-color);
            pointer-evets: none;
          }
          &:focus {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
          }
        }
        .sort-by {
          @media (max-width: 600px) {
            margin-top: 12px;
          }
        }
        .react-custom-dropdown {
          min-width: 160px;
        }
      }
    }
    .body-section {
      .card-wrapper {
        .MuiCard-root {
          background: transparent;
          padding: 0;
          border-radius: initial;
          box-shadow: none;
          overflow: visible;
          .MuiCardContent-root {
            padding: 0;
          }
        }
      }
      .company-card {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .card-left {
          width: calc(100% - 345px);
          box-sizing: border-box;
          position: relative;
          .search-top {
            position: absolute;
            top: 0;
            right: 30px;
          }
          .card-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 34px 0;
            .card-sort {
              select {
                border-radius: 10px;
                background-color: #ffffff;
                box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
                padding: 10px 20px 9px;
                height: auto;
                min-height: auto;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                color: ${PRIMARY_COLOR};
                font-family: "TTCommons-Regular";
              }
            }
          }
          .visa-info {
            .petition-wrapper {
              .MuiPaper-root {
                border-radius: 10px;
                background-color: #ffffff;
                box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
                transition: all 0.3s;
                &:hover {
                  box-shadow: 0 1px 14px 3px var(--gray-outline-color);
                }
                .MuiButtonBase-root {
                  padding: 0;
                  .MuiExpansionPanelSummary-content {
                    margin: 0;
                  }
                  .MuiIconButton-edgeEnd {
                    margin-right: 20px;
                    color: ${PRIMARY_COLOR};
                  }
                }
              }
              .petition-info {
                display: flex;
                width: 100%;
                align-items: center;
                padding: 20px 20px 18px 25px;
                .PrimaryDiv {
                  .sub-header-primary {
                    margin: 3px 0 0;
                  }
                }
                .header-primary {
                  margin: 0 0 0 auto;
                }
              }
              .petition-details {
                padding: 20px 20px 20px;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                color: var(--field-label-color);
                font-family: "TTCommons-Regular";
                position: relative;
                .MuiExpansionPanelDetails-root {
                  padding: 0 0 15px;
                }
                .popup-link {
                  color: var(--primary-dark-color);
                  font-size: 15px;
                  line-height: 17px;
                  position: absolute;
                  top: 0;
                  right: 20px;
                  text-decoration: none;
                  border-bottom: 2px solid var(--primary-dark-color);
                }
              }
              + div {
                margin-top: 15px;
              }
            }
          }
          .open-position-content {
            .card-wrapper {
              .MuiCard-root {
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: var(--card-box-shadow);
                overflow: visible;
                &:hover {
                  box-shadow: 0 1px 14px 3px var(--gray-outline-color);
                }
                .MuiCardContent-root {
                  padding: 0;
                }
              }
            }
          }
        }
        .card-right {
          border-radius: 10px;
          width: 100%;
          max-width: 325px;
          padding-left: 0;
          box-sizing: border-box;
          text-align: center;
          box-shadow: var(--card-box-shadow);
          margin: 0 0 0 auto;
          background: #fff;
          .h-information {
            padding: 20px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
`;

import React from "react";
import { useLocation } from "@reach/router";
/**
 * Extract Query params
 * @returns
 */
export const useQueryParams = () => {
  const { search } = useLocation();
  //use Memo to avoid retriving params with same values
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

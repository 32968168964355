import React from "react";
import { useDropzone } from "react-dropzone";
import { Avatar } from "@material-ui/core";

import CustomDropzoneStyles from "./CustomDropzoneStyles";
import { NoUserImage } from "../../helper/helper";
import deleteIcon from "../../assets/svg/f-delete-icon.svg";

const CustomDropzone = ({
  handleUploadFile = (e) => {},
  removeUploadFile = (e) => {},
  icon,
  remove = false,
  text,
  vallidFiles,
  showPreview = false,
  preview = null,
  removeText,
  showDeleteIcon = false,
  fileName = "",
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    noDrag: true,
    onDrop: handleUploadFile,
  });

  const id = Math.random();

  return (
    <CustomDropzoneStyles>
      <a
        className="dropzone-custom"
        {...getRootProps({
          className: "dropzone cursor-pointer",
        })}
        onClick={open}
      >
        <input aria-labelledby="dropzone-label" type="image" {...getInputProps()} accept={vallidFiles} />
        {showPreview && (
          <div className="custom-avtar d-flex">
            <Avatar
              src={preview !== "" ? preview : NoUserImage}
              alt="preview"
            />
          </div>
        )}
      </a>
      <div className="remove-icon">
        {showDeleteIcon ? (
          <div className="selectedfile-wrapper">
            {fileName}
            <a
              href="/delete"
              className="delete-btn"
              onClick={(e) => {
                e.preventDefault();
                removeUploadFile(e);
              }}
            >
              <img src={deleteIcon} alt="Delete" />
            </a>
          </div>
        ) : (
          <div
            onClick={open}
            className="removeicon-center d-flex align-items-center justify-content-center"
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
              }}
              aria-label={text ? text : "attachment"}
              className={text ? "nlink-secondary" : ""}
              onFocus={() => {
                if (icon) {
                  const img = document.getElementById(`upload-icon-${id}`);
                  if (img) img.src = icon;
                }
              }}
              onBlur={() => {
                if (icon) {
                  const img = document.getElementById(`upload-icon-${id}`);
                  if (img) img.src = icon;
                }
              }}
            >
            {icon && (
              <img
                id={`upload-icon-${id}`}
                src={icon}
                alt={text ? text : "attachment"}
                width="35"
                height="35"
                className="mr-6"
              />
            )}
            {
              text && (
                <span>{text}</span>
              )
            }
            </a>
          </div>
        )}
        {remove && (
          <a
            href={`/${removeText}`}
            onClick={(e) => {
              e.preventDefault();
              removeUploadFile(e);
            }}
            aria-label={removeText}
            className="removeicon-center btn-upload cursor-pointer link-focus"
          >
            {removeText}
          </a>
        )}
      </div>
    </CustomDropzoneStyles>
  );
};

export default CustomDropzone;

import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { createHistory } from "@reach/router";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { updateFavouriteServiceAPI } from "../../../../services/learnServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import Loader from "../../../../components/Loader/Loader";
import { toastify } from "../../../../helper/helper";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { createModuleStyleExtractor } from "../../../../utils/css";
import ServiceCard from "../ServiceCard/ServiceCard";
import IllustrationNoUser from "../../../../assets/svg/Illustration-no-user.svg";
import styles from "./ServiceMiddle.module.scss";

const cx = createModuleStyleExtractor(styles);

const ServiceMiddle = (props) => {
  const {
    serviceList = [],
    savedServiceList = [],
    showLoader = false,
    showPaginationLoader = false,
    widget = false,
    onActionClick = () => {},
  } = props;
  const [currentNotificationId, setCurrentNotificationId] = useState(-1);
  const [favServiceIds, setFavSerciveIds] = useState([]);
  const query = useQueryParams();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const history = createHistory(window);

  const [callingUpdateFavouriteServiceAPI, refreshUpdateFavouriteServiceAPI] =
    usePromise(updateFavouriteServiceAPI);

  useEffect(() => {
    if (
      callingUpdateFavouriteServiceAPI.hasFetched() &&
      callingUpdateFavouriteServiceAPI.hasErrors() === false
    ) {
      if (
        callingUpdateFavouriteServiceAPI.data() &&
        callingUpdateFavouriteServiceAPI.data().message
      )
        toastify("success", callingUpdateFavouriteServiceAPI.data().message);
      props.refreshSavedServices();
    }
  }, [callingUpdateFavouriteServiceAPI.isFetching()]);

  // Tracks saved services list and extracts ids
  useEffect(() => {
    if (savedServiceList.length === 0) {
      setFavSerciveIds([]);
      return;
    } else {
      let _favServiceIds = [];
      savedServiceList.forEach((item) => {
        if (item?.deal?.id) _favServiceIds.push(item.deal.id);
      });
      setFavSerciveIds(_favServiceIds);
    }
  }, [savedServiceList]);

  useEffect(() => {
    if (query) {
      if (query.has("notificationId")) {
        const notificationId = query.get("notificationId");
        if (currentNotificationId == notificationId) return;
        setCurrentNotificationId(notificationId);
        setTimeout(() => {
          history.navigate(history.location.pathname);
        }, 3000);
      }
    }
  }, [query]);

  // Handler for favorite and un favorite service
  const handleFavoriteClick = (payload) => {
    refreshUpdateFavouriteServiceAPI(payload);
  };

  return (
    <div className={cx("service-middle")}>
      {showLoader ? (
        <div className={cx("service-middle__loader-main")}>
          {[...Array(9)].map((_, index) => (
            <ShimmerThumbnail width={267} height={345} key={index} />
          ))}
        </div>
      ) : (
        <div className={cx("service-middle__list")}>
          {serviceList && serviceList.length > 0 ? (
            <>
              {accessibilityHelp == "true" ? (
                <>
                  <div className={cx("service-middle__list__item")}>
                    {serviceList.map((data, index) => (
                      <ServiceCard
                        key={index}
                        data={data}
                        isFavourite={favServiceIds.includes(data.id)}
                        onClickStar={handleFavoriteClick}
                      />
                    ))}
                  </div>
                  <div className={cx("service-middle__list__accessible")}>
                    {props.currentPage !== props.totalPages && (
                      <center>
                        {showPaginationLoader ? (
                          <Loader />
                        ) : (
                          <ButtonCustom onClick={props.fetchMoreData}>
                            Load More
                          </ButtonCustom>
                        )}
                      </center>
                    )}
                  </div>
                </>
              ) : (
                <InfiniteScroll
                  dataLength={serviceList.length}
                  next={props.fetchMoreData}
                  hasMore={props.currentPage !== props.totalPages}
                  loader={
                    <div
                      className={cx("service-middle__list__loader-secondary")}
                    >
                      {[...Array(3)].map((_, index) => (
                        <ShimmerThumbnail
                          width={267}
                          height={345}
                          key={index}
                        />
                      ))}
                    </div>
                  }
                  style={{ overflow: "none" }}
                >
                  <div className={cx("service-middle__list__item")}>
                    {serviceList.map((data, index) => (
                      <ServiceCard
                        key={index}
                        data={data}
                        isFavourite={favServiceIds.includes(data.id)}
                        onClickStar={handleFavoriteClick}
                        widget={widget}
                        onActionClick={onActionClick}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </>
          ) : (
            <div className={cx("service-middle__list__placeholder")}>
              <img src={IllustrationNoUser} alt="no-services" />
              <h2>Looks like there are no services with this search.</h2>
              <h3>Try expanding your search to discover other services!</h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceMiddle;

import React from "react";
import { Router } from "@reach/router";

import CareerGameplanDashboard from "./CareerGameplanDashboard/CareerGameplanDashboard";

const CareerGameplan = () => {
  return (
    <>
      <Router>
        <CareerGameplanDashboard path="/" />
      </Router>
    </>
  );
};

export default CareerGameplan;

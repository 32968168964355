import React, { useState, useRef, useEffect } from "react";
import onEnter from "react-onenterkeydown";
import { Link } from "@reach/router";
import cls from "classnames";
import { Radio, Tooltip } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { isUndefined } from "lodash";
import isNull from "underscore/modules/isNull";

import deleteIcon from "../../../../assets/svg/f-delete-icon.svg";
import editIcon from "../../../../assets/svg/f-edit-icon.svg";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";

const EnhancedInput = onEnter("input");

const ChecklistSubTaskList = ({
  task,
  createSubTask,
  subCategoryId,
  mainIndex,
  subIndex,
  subTaskMark,
  removeSubTaskStaticFn,
  removeSubTask,
  editSubtask,
  editTask,
  showAddEditDeleteButton = true,
  activeEditTask,
  activeEditSubTask,
  setActiveEditSubTask,
  setHideSubtaskButton,

}) => {
  const [taskName, setTaskName] = useState("");
  const [taskId, setTaskId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showTooltip, setShowTooltip] = useState(null);
  const searchInput = useRef(null);
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  useEffect(() => {
    task.title && setTaskName(task.title);
    task.is_mark == "true" && setIsEdit(false);
  }, [task]);

  useEffect(() => {
    if (task.title !== taskName) {
      setActiveEditSubTask({
        id: task.id,
        title: taskName,
        task_id: task.task_id,
      });
    } else {
      setActiveEditSubTask(null);
    }
  }, [taskName]);

  useEffect(() => {
    if (isEdit) {
      searchInput.current.focus();
    }
  }, [isEdit]);

  return (
    <div className="checklist-task">
      <div className="checklist-control">
        <div className="ncheckbox-wrapper">
          <Radio
            disableRipple={true}
            type="checkbox"
            id={`subtask-${task.id}`}
            checkedIcon={<CheckCircle color={"primary"} fontSize="small" />}
            inputProps={{ "aria-label": taskName ? taskName : "task" }}
            color="primary"
            size="small"
            value={task.is_mark}
            checked={task.is_mark === "true"}
            onChange={(e) => {
              if (!isUndefined(task.id)) {
                setIsEdit(false);
                subTaskMark(task.id, e.target.checked);
              }
            }}
          />
        </div>

        <div
          className={cls("subtitle", {
            completed: task.is_mark === "true",
          })}
        >
          {isEdit ? (
            <EnhancedInput
              ref={searchInput}
              type="text"
              placeholder="Add to subtask"
              value={taskName}
              onChange={(e) => {
                setTaskName(e.target.value)
                setHideSubtaskButton(false)
              }}
            />
          ) : task.title === undefined ? (
            <EnhancedInput
              type="text"
              placeholder="Add to subtask"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
          ) : (
            taskName
          )}
        </div>

        {isEdit && activeEditSubTask?.id === taskId ? (
          <ButtonCustom
            width="94"
            height="40"
            color="primary"
            className="btn-save"
            onClick={() => {
              setIsEdit(false);
              setHideSubtaskButton(false);
              taskName &&
                taskName.trim().length > 0 &&
                editSubtask(task.id, taskName, task.task_id);
            }}
          >
            Save task
          </ButtonCustom>
        ) : task.title === undefined ? (
          <ButtonCustom
            width="94"
            height="40"
            color="primary"
            className="btn-save"
            onClick={() => {
              setHideSubtaskButton(false)
              taskName &&
                taskName.trim().length > 0 &&
                createSubTask(subCategoryId, taskName);
            }}
          >
            Save task
          </ButtonCustom>
        ) : null}

        {task &&
          !!task.title &&
          task.user_id == localStorage.getItem("userId") &&
          showAddEditDeleteButton && (
            <>
              <Tooltip
                title="Edit subtask"
                placement="bottom"
                open={!isNull(showTooltip) && showTooltip === `edit-${task.id}`}
                disableHoverListener
                onMouseEnter={() => setShowTooltip(`edit-${task.id}`)}
                onMouseLeave={() => setShowTooltip(null)}
              >
                <Link
                  to="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (activeEditSubTask && taskId !== activeEditSubTask?.id) {
                      editSubtask(
                        activeEditSubTask.id,
                        activeEditSubTask.title,
                        activeEditSubTask.task_id,
                        false
                      );
                    }
                    if (activeEditTask) {
                      editTask(activeEditTask.id, activeEditTask.taskName);
                    }
                    setTaskId(task.id);
                    setActiveEditSubTask({
                      id: task.id,
                      title: taskName,
                      task_id: task.task_id,
                    });
                    setIsEdit(true);
                  }}
                  className={`btn-edit link-focus ml-auto ${accessibilityHelp == "true" ? "show-actions" : ""}`}
                  aria-label={`Edit Subtask ${taskName}`}
                  aria-describedby={`subtask-${task.id}`}
                >
                  <img src={editIcon} alt="Edit task icon" />
                </Link>
              </Tooltip>
            </>
          )}
        {showAddEditDeleteButton && (
          <Tooltip
            title="Delete subtask"
            placement="bottom"
            open={!isNull(showTooltip) && showTooltip === task.id}
            disableHoverListener
            onMouseEnter={() => setShowTooltip(task.id)}
            onMouseLeave={() => setShowTooltip(null)}
          >
            <button
              className={`btn-close ${accessibilityHelp == "true" ? "show-actions" : ""}`}
              onClick={() => {
                setHideSubtaskButton(false)
                task.id
                  ? removeSubTask(task.id)
                  : removeSubTaskStaticFn(mainIndex, subIndex)
              }}
              aria-label={`Close Subtask ${taskName}`}
              aria-describedby={`subtask-${task.id}`}
            >
              <img src={deleteIcon} alt="Delete task icon" />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ChecklistSubTaskList;

import React, { memo } from "react";
import { Tooltip } from "@material-ui/core";
import Styles from "./BadgeStyles";
const Badge = ({ tooltipText, title, className = "" }) => {
  return (
    <Styles>
      <Tooltip title={tooltipText}>
        <span className={`badge--default ${className}`}>{title}</span>
      </Tooltip>
    </Styles>
  );
};
export default memo(Badge);

// import firebase from '@firebase/app'
// import "@firebase/messaging"

var firebase = require("@firebase/app").default;

// var firebase = null;
var messaging = null;

var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
  );

window.location.protocol == "https:" &&
  !isSafari &&
  require("@firebase/messaging");

// if (!isSafari) {
//   import("firebase/messaging").then((someth) => {});
//   import("firebase").then((something) => {
//     firebase = something.default;
//     const config = {
//       apiKey: "AIzaSyC0o_wbEaN6a5iPfncmUrbSBgCkMpooD0g",
//       authDomain: "interstride-1606300174546.firebaseapp.com",
//       databaseURL:
//         "https://interstride-1606300174546-default-rtdb.firebaseio.com",
//       projectId: "interstride-1606300174546",
//       storageBucket: "interstride-1606300174546.appspot.com",
//       messagingSenderId: "676649229721",
//       appId: "1:676649229721:web:0a7acb3336ce5eadfa8708",
//       measurementId: "G-ZCYBPHFE0J",
//     };

//     firebase.initializeApp(config);
//     messaging = firebase.messaging();
//   });
// }

if (!isSafari) {
  const config = {
    apiKey: "AIzaSyCal6I_mnG7mg9yWA4gWRwYogj-OEbWoXs",
    authDomain: "interstride-177910.firebaseapp.com",
    databaseURL: "https://interstride-177910.firebaseio.com",
    projectId: "interstride-177910",
    storageBucket: "interstride-177910.appspot.com",
    messagingSenderId: "662715351295",
    appId: "1:662715351295:web:90fd5b6066501f20550cca",
    measurementId: "G-B31Q32742C",
  };

  firebase.initializeApp(config);
  if (
    window.location.protocol == "https:" &&
    firebase.messaging.isSupported()
  ) {
    messaging = firebase.messaging();
  }
}

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (!messaging) reject("Messaging is not defined.");
    // console.log("messaging", messaging.requestPermission());
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      // console.log("payload", payload);
      resolve(payload);
    });
  });

export const onBackgroundMessageListener = () =>
  new Promise((resolve) => {
    messaging.setBackgroundMessageHandler((payload) => {
      // console.log("setBackgroundMessageHandler", payload);
      resolve(payload);
    });
  });

export { messaging };

import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .search-filter {
    display: block;
    .title-primary {
      .ngray-link {
        margin-left: 12px;
      }
    }
    .MuiCardContent-root {
      padding: 20px 20px;
    }
    .filter-note {
      position: absolute;
      left: 42px;
      top: 10px;
      text-align: center;
      font-size: 15px;
    }
  }
    }
    .card-content {
      .item {
        position: relative;
        .popup-link {
          color: var(--secondary-color);
          font-size: 15px;
          line-height: 15px;
          position: absolute;
          top: 2px;
          right: 0;
          text-decoration: none;
          border-bottom: 1px solid var(--secondary-color);
        }
      }
    }
    .textbox-label {
      margin: 0 0 6px 10px !important;
      color: ${PRIMARY_COLOR};
      font-size: 15px;
      letter-spacing: 0;
      line-height: 13px;
      display: block;
      padding-top: 4px;
      font-family: "TTCommons-Regular";
      padding: 4px 0 0 0;
    }
    .custom-textbox {
      input {
        padding: 0 22.27px;
      }
    }
    .searchBtn {
      display: block;
      margin-top: 20px;
      button {
        width:100%;
        background-color: var(--link-color) !important;
      }
    }
  }

  .more-filters {
    cursor: pointer;
    margin-bottom: 25px;
    text-decoration: none;
    text-align: center;
    display: block;
    span {
      text-align: center;
      border-bottom: 1px solid var(--secondary-color);
    }
    &:focus {
      span {
        color: ${PRIMARY_COLOR};
        border-bottom: 1px solid ${PRIMARY_COLOR};
      }
    }
  }

  .searchBtn {
    display: block;
    margin-top: 40px;
    margin-bottom: 10px;
    button {
      padding: 19.85px 20px 15.85px;
      height: auto;
      border-radius: 45px;
      font-size: 22px;
      font-weight: 500;
      -webkit-letter-spacing: 0;
      -moz-letter-spacing: 0;
      -ms-letter-spacing: 0;
      letter-spacing: 0;
      line-height: 24.3px;
      text-align: center;
      margin: 0px 0 0 0;
      width: 100%;
    }
  }

  .custom-tab {
    button {
      border: 2px solid var(--light-gray-color);
      padding: 10px 31px 5px;
      box-shadow: none;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      text-align: center;
      color: ${PRIMARY_COLOR};
      font-family: "TTCommons-Medium";
      text-transform: capitalize;
      border-radius: 32.5px 0 0 32.5px;
      background: #f9fbfd;
      + button {
        border-radius: 0 32.5px 32.5px 0;
      }
      &.active {
        background-color: ${PRIMARY_COLOR};
        color: #fff;
        border-color: ${PRIMARY_COLOR};
      }
      &.disabled {
        color: var(--light-gray-color);
      }
      &:focus {
        box-shadow: 0 0 8px 1px #000c3d !important;
      }
      span {
        line-height: normal;
      }
    }
  }

  .preset-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    &__item {
      height: 34px;
      border-radius: 40px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: 15px;
      padding: 5px 15px 0px 15px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.684);
      }
      span {
        height: 18px;
        color: #000c3d;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        display: flex;
        align-items: center;
      }
      &--active {
        background-color: #0030b5;
        span {
          color: white;
        }
      }
    }
  }

  .preset-filter--big-screen {
    margin-bottom: 26px;
    @media (max-width: 991px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;

import axios from "axios";
import { navigate } from "@reach/router";
import Bugsnag from "@bugsnag/js";

import { routes } from "../routes";
import { config } from "../constant/config";
import { applyAccessibilityHelp, clearLocalStorage } from "../helper/helper";

let axiosEmpInterceptors = axios.create({
  baseURL: config().EMPAPI_URL, // API URL
  // timeout: 8000,
});

axiosEmpInterceptors.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
axiosEmpInterceptors.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axiosEmpInterceptors.interceptors.response.use(
  function (response) {
    // console.log("response", response);
    return response;
  },
  function (error) {
    //console.log("--------------------------", error);
    const responseData = error.response
      ? error.response.data
      : {
          code: 0,
          message: error.message,
        };
    // console.log("error", error.response);

    const statusData = error.response ? error.response.status : 500;
    if (statusData === 401) {
      const isLogin = sessionStorage.getItem("isLogin");
      if (isLogin === "1") {
        // Leave breadcrumb
        Bugsnag.leaveBreadcrumb(
          "Session storage clear",
          {
            instance: "API failure 401 code",
          },
          "state"
        );
      }

      // console.log("clear localstorage2");
      clearLocalStorage();
      applyAccessibilityHelp(false);

      navigate(routes.LOGIN);
      //redirectTo(routes.LOGIN);
    }

    return Promise.reject({
      status: statusData || 500,
      data: responseData,
    });
  }
);

export default axiosEmpInterceptors;
